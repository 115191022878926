import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import * as Actions from "module/amc/store/actions/Worker.Actions";

import Button from "components/CustomButtons/Button.jsx";
import { Add } from "@material-ui/icons";
import { Checkbox, CircularProgress, FormControl, FormControlLabel, MenuItem, Select, Typography } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import WorkerSearchCriteria from "module/amc/components/workers/WorkerSearchCriteria";
import WorkerTable from "module/amc/components/workers/WorkerTable";
import WorkerPopup from "./WorkerPopup";

//lingui
import { Trans } from "@lingui/macro";
import { isNull, isArrayNullOrEmpty, isNullOrEmpty } from "../../tools";

import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";
import { HasRight } from "services/user/UserHelper";

function WorkerList({ amc, addItem, classes }) {
  const dispatch = useDispatch();

  var searchResult = amc.results["WORKER"];
  var isSearching = amc.searching["WORKER"];

  var resultComponent = "";
  if (isSearching)
    resultComponent = (
      <center>
        <CircularProgress />
      </center>
    );
  else if (!isNullOrEmpty(searchResult)) resultComponent = <WorkerTable />;

  var buttonCreation = "";
  if (HasRight("amc.admin")) {
    buttonCreation = (
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <Button variant="contained" onClick={addItem} size="sm" color="info">
            <Add />
            <Trans>Add Worker</Trans>
          </Button>
        </GridItem>
      </GridContainer>
    );
  }

  return (
    <div>
      {buttonCreation}
      <WorkerSearchCriteria showSearchCriteriaOnSideBar={false} />
      {resultComponent}
      <WorkerPopup />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addItem: () => {
      dispatch(Actions.AddItem());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(WorkerList));
