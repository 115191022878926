import React from "react";
import { useDispatch } from "react-redux";
import FormLabel from "@material-ui/core/FormLabel";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";
import { IconButton } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import * as ThirdPartyActions from "module/tpCopernic/store/actions";
import { HasOneOfRights } from "services/user/UserHelper";

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

function InfolegaleMerge({ workflow, classes }) {
  var dispatch = useDispatch();
  var exitingThirdParty = {};
  var newThirdParty = {};

  if (!!workflow.linkedObject && !!workflow.linkedObject.oldThirdParty) exitingThirdParty = workflow.linkedObject.oldThirdParty;

  if (!!workflow.linkedObject && !!workflow.linkedObject.newThirdParty) newThirdParty = workflow.linkedObject.newThirdParty;

  var thirdPartyButton = <></>;
  if (
    exitingThirdParty.identifier &&
    HasOneOfRights(["thirdparty_customer.view", "thirdparty_workflow.edit", "thirdparty_supplier.view", "thirdparty_workflow.view"])
  ) {
    thirdPartyButton = (
      <center>
        <IconButton color="info" onClick={() => dispatch(ThirdPartyActions.OpenThirdParty(exitingThirdParty.identifier))}>
          <Visibility />
        </IconButton>
      </center>
    );
  }

  var newThirdPartyButton = <></>;
  if (
    newThirdParty.identifier &&
    HasOneOfRights(["thirdparty_customer.view", "thirdparty_workflow.edit", "thirdparty_supplier.view", "thirdparty_workflow.view"])
  ) {
    newThirdPartyButton = (
      <center>
        <IconButton color="info" onClick={() => dispatch(ThirdPartyActions.OpenThirdParty(newThirdParty.identifier))}>
          <Visibility />
        </IconButton>
      </center>
    );
  }

  return (
    <div style={{ width: "98%" }}>
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <Card
            style={{
              ...CardInlineStyle.card
            }}
          >
            <CardHeader
              style={{
                ...CardInlineStyle.cardHeader
              }}
              icon
            >
              <h4>
                <Trans>Old Third Party</Trans>
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Id MDM</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <TextField margin="dense" id="idmdmOld" type="text" fullWidth value={exitingThirdParty.identifier} />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  {thirdPartyButton}
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Long name</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <TextField margin="dense" id="longNameOld" type="text" fullWidth value={exitingThirdParty.longName} />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Legal Identifier</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <TextField margin="dense" id="legalIdentifierOld" type="text" fullWidth value={exitingThirdParty.legalIdentifier} />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
          <Card
            style={{
              ...CardInlineStyle.card
            }}
          >
            <CardHeader
              style={{
                ...CardInlineStyle.cardHeader
              }}
              icon
            >
              <h4>
                <Trans>New Third Party</Trans>
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Id MDM</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <TextField margin="dense" id="idmdmNew" type="text" fullWidth value={newThirdParty.identifier} />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  {newThirdPartyButton}
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Long name</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <TextField margin="dense" id="longNameNew" type="text" fullWidth value={newThirdParty.longName} />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Legal Identifier</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <TextField margin="dense" id="legalIdentifierNew" type="text" fullWidth value={newThirdParty.legalIdentifier} />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
export default withStyles(thirdPartySearchCriteriaStyle)(InfolegaleMerge);
