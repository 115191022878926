import React, {useState} from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import JobReportVisual from "module/report/components/amc/JobReportVisual";
import JobAppInsight from "module/report/components/amc/JobAppInsight";
import CustomerContractReportVisual from "module/report/components/amc/CustomerContractReportVisual";
import CustomerContractAppInsight from "module/report/components/amc/CustomerContractAppInsight";

function JobAndContractDashBoard() {

    const [showAppInsight, setShowAppInsight] = useState(false);
    const [appInsightParameters, setAppInsightParameters] = useState(null);
    const [typeSelected, setTypeSelected] = useState(null);

    function HandleSelecedData(type, data) {
        if (data != null) {
            setAppInsightParameters(data);
            setShowAppInsight(true);
        }
        setTypeSelected(type);
    }
    var appinsightTableContainer = <></>;
    if (showAppInsight) {
        switch (typeSelected) {
            case "CustomerContract":
                appinsightTableContainer = <CustomerContractAppInsight parameters={appInsightParameters} />;
                break;
            case "Job":
                appinsightTableContainer = <JobAppInsight parameters={appInsightParameters} />;
                break;
        }
    }

    return (
        <GridContainer>
            <GridItem xs={6}>
                <GridContainer>
                    <GridItem xs={12}>
                        <CustomerContractReportVisual handleSelecedData={(data) => HandleSelecedData("CustomerContract", data)} />
                    </GridItem>
                    <GridItem xs={12}>
                        <CustomerContractReportVisual handleSelecedData={(data) => HandleSelecedData("Job", data)} />
                    </GridItem>
                </GridContainer>
            </GridItem>
            <GridItem xs={6}>
                {appinsightTableContainer}
            </GridItem>
        </GridContainer>
    );
}

export default withStyles(dashboardStyle)(JobAndContractDashBoard);