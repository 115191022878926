import * as Actions from "./types";
import { isArrayNullOrEmpty, isNullOrEmpty } from "../../../tools";
import { ApimGet, ApimPost } from "components/Common/ApimSender";

export function CrupdateMigCode(item) {
  return dispatch => {
    dispatch({
      type: Actions.TP_COPERNIC_DUNSMONITORING_BEGIN_ACTION
    });

    if (item.migCodeLegalIdentifiers != null) {
      item.migCodeLegalIdentifiers.forEach(item => (item.identifier = 0));
    }

    ApimPost(
      "api/TpCopernic/MigCodes",
      item,
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_MIGCODE_SAVE_END,
          item: responseJson
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_MIGCODE_END_ACTION,
          error: error
        });
      }
    );
  };
}

export function SetCriterias(criterias) {
  return dispatch => {
    dispatch({
      type: Actions.TP_COPERNIC_MIGCODE_SET_CRITERIAS,
      criterias: criterias
    });
  };
}

export function GetExistingMigCode(codeMig) {
  return dispatch => {
    ApimGet(
      "api/TpCopernic/MigCodes/" + codeMig,
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_MIGCODE_GET_EXISTING,
          item: responseJson
        });
      },
      error => {
        dispatch({
          type: Actions.TP_COPERNIC_MIGCODE_END_ACTION
        });
      }
    );
  };
}

export function SearchMigCode() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.TP_COPERNIC_MIGCODE_BEGIN_ACTION
    });

    const criterias = getState().TP_MigCode.criterias;

    ApimGet(
      "api/TpCopernic/MigCodes" + ConvertCriteriaToQueryString(criterias),
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_MIGCODE_GET_ALL,
          result: responseJson
        });

        dispatch({
          type: Actions.TP_COPERNIC_MIGCODE_END_ACTION
        });
      },
      error => {
        dispatch({
          type: Actions.TP_COPERNIC_MIGCODE_END_ACTION
        });
      }
    );
  };
}

function ConvertCriteriaToQueryString(criteria) {
  var queryString = "";
  var queryParams = [];

  if (!isNullOrEmpty(criteria.migCode)) queryParams.push("MigCode=" + criteria.migCode);

  if (!isNullOrEmpty(criteria.label)) queryParams.push("label=" + criteria.label);

  if (!isNullOrEmpty(criteria.legalIdentifier)) queryParams.push("LegalIdentifier=" + criteria.legalIdentifier);

  if (!isNullOrEmpty(criteria.startPage)) {
    queryParams.push("StartPage=" + criteria.startPage);
  }
  if (!isNullOrEmpty(criteria.pageSize)) {
    queryParams.push("PageSize=" + criteria.pageSize);
  }
  if (!isNullOrEmpty(criteria.orderProperty)) {
    queryParams.push("OrderProperty=" + criteria.orderProperty);
  }
  if (!isNullOrEmpty(criteria.isDescendingOrder)) {
    queryParams.push("IsDescendingOrder=" + criteria.isDescendingOrder);
  }
  if (!isArrayNullOrEmpty(queryParams)) queryString = "?" + queryParams.join("&");

  return queryString;
}
