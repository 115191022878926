import React from "react";
import { RootStore } from "store/RootStore";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import KeysValuesDisplay from "components/Common/KeysValuesDisplay.jsx";
import Selector from "components/Common/Selector.jsx";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";

import { Trans } from "@lingui/macro";
import { TpLegalIdentifier } from "store/MasterValue/MasterValueTypes";
import { TpIndustryCode } from "store/MasterValue/MasterValueTypes";

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

class UnknownStep2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newIndustryCode: {
        industryCodeIdentifier: "",
        value: ""
      }
    };
    this.handleAddIndustryCode = this.handleAddIndustryCode.bind(this);
    this.getIndustryCodeLabel = this.getIndustryCodeLabel.bind(this);
    this.getLegalIdentifierLabel = this.getLegalIdentifierLabel.bind(this);
  }

  ///------------------------------------------------------------------
  ///handle Add Legal Identifier
  ///------------------------------------------------------------------
  handleAddIndustryCode() {
    var newIndustryCode = this.state.newIndustryCode;

    if (newIndustryCode.industryCodeIdentifier === "" || newIndustryCode.Value === "") return;

    var thirdParty = this.props.thirdParty;
    this.props.handleChange({
      ...thirdParty,
      thirdParty_ActivitySectors: [...thirdParty.thirdParty_ActivitySectors, newIndustryCode]
    });
    this.setState({
      newIndustryCode: {
        industryCodeIdentifier: "",
        value: ""
      }
    });
  }

  ///------------------------------------------------------------------
  ///handle ThirdParty change
  ///------------------------------------------------------------------
  handleChangeNewIndustryCode(event, property) {
    var newIndustryCode = this.state.newIndustryCode;
    newIndustryCode[property] = event.target.value;
    this.setState({ newIndustryCode: newIndustryCode });
  }

  getIndustryCodeLabel(items, key) {
    var item = items.find(function(element) {
      return element.code === key;
    });
    return item.label_EN;
  }

  getLegalIdentifierLabel(items, key) {
    var item = items.find(function(element) {
      return element.code === key;
    });
    return item.label_EN;
  }

  filterLegalIdentifierByCountry(country) {
    var legalIdentifiers = RootStore.getState().MasterValueReducer[TpLegalIdentifier].filter(
      legalIdentifier => legalIdentifier.countryCode === country
    );
    return { items: legalIdentifiers };
  }

  render() {
    var item = this.props.thirdParty;

    var industryCodes = RootStore.getState().MasterValueReducer[TpIndustryCode];
    var legalIdentifiers = this.filterLegalIdentifierByCountry(item.countryCode);

    return (
      <div style={{ width: "100%" }}>
        <Card
          style={{
            ...CardInlineStyle.card
          }}
        >
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <h4>
              <Trans>Legal identifiers</Trans>
            </h4>
          </CardHeader>
          <CardBody>
            <KeysValuesDisplay
              collection={item.thirdParty_LegalIdentifiers}
              displayedProperties={{
                keyProperty: "legalIdentifierCode",
                valueProperty: "value"
              }}
              handleChange={e =>
                this.props.handleChange({
                  ...item,
                  thirdParty_LegalIdentifiers: item.thirdParty_LegalIdentifiers.map(legalId => {
                    if (legalId.legalIdentifierCode === item.legalIdentifierCode) {
                      legalId.value = e.target.value;
                    }
                    return legalId;
                  })
                })
              }
              transformKey={item => {
                return this.getLegalIdentifierLabel(legalIdentifiers.items, item.legalIdentifierCode);
              }}
            ></KeysValuesDisplay>
          </CardBody>
        </Card>

        <Card>
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <h4>
              <Trans>Industry codes</Trans>
            </h4>
          </CardHeader>
          <CardBody>
            <KeysValuesDisplay
              collection={item.thirdParty_ActivitySectors}
              displayedProperties={{
                keyProperty: "industryCodeIdentifier",
                valueProperty: "Value"
              }}
              transformKey={item => {
                return this.getIndustryCodeLabel(industryCodes, item.industryCodeIdentifier);
              }}
            >
              <tr>
                <td>
                  <Selector
                    items={industryCodes}
                    displayedProperties={{
                      keyProperty: "code",
                      valueProperty: "label_EN"
                    }}
                    value={this.state.newIndustryCode.industryCodeIdentifier}
                    onChange={event => {
                      this.handleChangeNewIndustryCode(event, "industryCodeIdentifier");
                    }}
                  ></Selector>
                </td>
                <td>
                  <TextField
                    margin="dense"
                    id="newIndustryCode"
                    type="text"
                    fullWidth
                    value={this.state.newIndustryCode.Value}
                    onChange={event => {
                      this.handleChangeNewIndustryCode(event, "Value");
                    }}
                  />
                </td>
                <td>
                  <IconButton aria-label="Add" onClick={this.handleAddIndustryCode}>
                    <AddIcon />
                  </IconButton>
                </td>
              </tr>
            </KeysValuesDisplay>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default UnknownStep2;
