import React, { useEffect, useState } from 'react';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { SearchOutlined, ViewDay, ViewHeadline, ViewQuilt } from "@material-ui/icons";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import { Typography } from '@material-ui/core';
import SearchCriteriasSimple from './SearchCriteriasSimple';
import SearchCriteriasExtended from './SearchCriteriasExtended';
import SearchCriteriasComplex from './SearchCriteriasComplex';
import { Trans } from "@lingui/macro";

function SearchCriterias({ criteria, setCriteria, search, showSearchCriteriaOnSideBar, defaultLang }) {
    const [searchMode, setSearchMode] = useState('Simple');

    const blockHeaderSearchClasses = ['blockHeaderSearch'];
    if (showSearchCriteriaOnSideBar)
        blockHeaderSearchClasses.push("Reduce");

    const simpleSearchClasses = ['simple-search'];
    const extendedSearchClasses = ['extended-search'];
    const complexSearchClasses = ['complex-search'];
    var searchTitle = "";

    const cleanSearch = (e) => { e.preventDefault(); setCriteria({}); };
    const launchSearch = (e) => { e.preventDefault(); search(criteria); }

    var searchComponent = "";
    switch (searchMode) {
        case 'Simple':
            searchComponent = (
                <SearchCriteriasSimple
                    criteria={criteria} setCriteria={setCriteria}
                    cleanSearch={cleanSearch}
                    launchSearch={launchSearch}
                    showSearchCriteriaOnSideBar={showSearchCriteriaOnSideBar}
                    defaultLang={defaultLang} />
            );
            simpleSearchClasses.push('show');
            if (!showSearchCriteriaOnSideBar)
                searchTitle = <Trans> Quick Search </Trans>;
            break;
        case 'Extend':
            searchComponent = (
                <SearchCriteriasExtended 
                    showSearchCriteriaOnSideBar={showSearchCriteriaOnSideBar}
                    defaultLang={defaultLang} />
            );
            extendedSearchClasses.push('show');
            if (!showSearchCriteriaOnSideBar)
                searchTitle = <Trans> Extended Search</Trans>;
            break;
        case 'Complex':
            searchComponent = (
                <SearchCriteriasComplex
                    defaultLang={defaultLang} />
            );
            complexSearchClasses.push('show');
            if (!showSearchCriteriaOnSideBar)
                searchTitle = <Trans> Complex Search</Trans>;
            break;
        default:
            console.warn('Unknown searchMode: ' + searchMode);
            break;
    }

    return (
        <Card>
            <CardHeader color="info" icon>
                <CardIcon color="info">
                    <SearchOutlined />
                </CardIcon>
                <div className={blockHeaderSearchClasses.join(' ')} >
                    <div className="blockHeaderSearchItem">
                        <ToggleButtonGroup value={searchMode} exclusive onChange={(e, mode) => setSearchMode(mode)}>
                            <ToggleButton value="Simple">
                                <ViewDay />
                            </ToggleButton>
                            <ToggleButton value="Extend">
                                <ViewHeadline />
                            </ToggleButton>
                            <ToggleButton value="Complex">
                                <ViewQuilt />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <div className="blockHeaderSearchItem">
                        <Typography ariant="display1" >
                            {searchTitle}
                        </Typography>
                    </div>
                </div>
            </CardHeader>
            <CardBody>
                {searchComponent}
            </CardBody>
        </Card>
    );
}

export default withStyles(thirdPartySearchCriteriaStyle)(SearchCriterias);