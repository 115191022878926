import React from "react";
import { isNull } from "tools";

import ThirdPartyCreation from "../commonThirdPartyWorkflow/ThirdPartyCreation";
import RecapSupplierCreation from "./RecapSupplierCreation";

import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";

function CreationSupplier({ workflow, setWorkflow, defaultLang }) {
  var componentToDisplay = "";
  if (!isNull(workflow.workflowStepCode) && workflow.workflowStepCode === "SupplierSummary") componentToDisplay = <RecapSupplierCreation />;
  else
    componentToDisplay = (
      <ThirdPartyCreation
        thirdParty={workflow.linkedObject?.supplier?.thirdParty}
        setThirdParty={tp =>
          setWorkflow({
            ...workflow,
            linkedObject: {
              ...workflow.linkedObject,
              supplier: {
                ...workflow.linkedObject.supplier,
                thirdParty: tp
              }
            }
          })
        }
        defaultLang={defaultLang}
      />
    );

  return <div className="divContainer">{componentToDisplay}</div>;
}

export default withStyles(thirdPartySearchCriteriaStyle)(CreationSupplier);
