import React from "react";
import uuidv4 from 'uuid/v4';   // Generate GUID

// @material-ui/core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ContactCard from '../ContactCard';

import Button from "components/CustomButtons/Button";
import { Typography } from '@material-ui/core';
import { PersonAddOutlined } from "@material-ui/icons";

//lingui
import { Trans } from "@lingui/macro";
import { isArrayNullOrEmpty, isNull } from "module/tools";

const CardInlineStyle = {
    card: {
        marginTop: "10px",
    },
    cardHeader: {
        backgroundColor: "rgba(0, 172, 193, 0.6)",
        margin: "0",
        paddingLeft: "10px",
    }
};

class UnknownStep3 extends React.Component {
    constructor(props) {
        super(props);
        this.addContact = this.addContact.bind(this);
        this.updateContact = this.updateContact.bind(this);
        this.deleteContact = this.deleteContact.bind(this);
    }

    handleContactChange() {
        if (!isNull(this.props.handleChange)) {
            var thirdParty = this.props.thirdParty;
            thirdParty = {
                ...thirdParty,
                thirdPartyContacts: thirdParty.thirdPartyContacts
            }
            this.props.handleChange(thirdParty);

        }
    }


    addContact() {
        var thirdParty = this.props.thirdParty;
        if (isNull(thirdParty.thirdPartyContacts))
            thirdParty.thirdPartyContacts = [];
        thirdParty.thirdPartyContacts.push(
            {
                localId: uuidv4(),
                contactTypeCode: 'GENERAL',
                action: 'INSERT',
                details: [{}]
            });
        this.props.handleChange({ ...thirdParty });
    }

    updateContact(c) {
        var thirdParty = this.props.thirdParty;
        if (isNull(thirdParty.thirdPartyContacts))
            thirdParty.thirdPartyContacts = [];
        var index = thirdParty.thirdPartyContacts.findIndex((element) => element.LocalId === c.LocalId);
        if (index < 0)
            thirdParty.thirdPartyContacts.push(c);
        else
            thirdParty.thirdPartyContacts[index] = c;
        this.props.handleChange({ ...thirdParty });
    }

    deleteContact(c, isCreation) {
        var thirdParty = this.props.thirdParty;
        if (isNull(thirdParty.thirdPartyContacts))
            thirdParty.thirdPartyContacts = [];
        var index = thirdParty.thirdPartyContacts.findIndex((element) => element.LocalId === c.LocalId);
        if (index >= 0)
            thirdParty.thirdPartyContacts.splice(index, 1);
        this.props.handleChange({ ...thirdParty });
    }

    render() {
        var item = this.props.thirdParty;
        var contacts = item.thirdPartyContacts;

        var cards;
        if (!!contacts){
            cards =
                (
                    <GridContainer>
                        {contacts.map((ctx, idx) => {
                            if (ctx.action === 'DELETE') {
                                return "";
                            }
                            return (
                                <GridItem xs={12} sm={12} md={12}>
                                    <ContactCard
                                        key={idx}
                                        contact={ctx}
                                        //contactTypes={contactTypes}
                                        isCreation={ctx.action === 'INSERT'}
                                        isEditable={true}
                                        isDeletable={true}
                                        onContactSave={c => this.updateContact(c)}
                                        onContactDelete={(c, isCreation) => this.deleteContact(c, isCreation)}
                                        defaultLang={this.props.defaultLang}
                                    />
                                </GridItem>
                            );
                        })}
                    </GridContainer>
                );
        }

        var btnAddContact = "";
        if (!contacts || contacts.length == 0) {
            btnAddContact = (
                <Button onClick={this.addContact}>
                    <PersonAddOutlined />
                Nouveau contact
                </Button>
            );
        }

        return (
            <div>
                {cards}
                {btnAddContact}
            </div>
        );
    };
}


export default UnknownStep3;