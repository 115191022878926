import { ApimGet, ApimPost } from "components/Common/ApimSender";
import { isArrayNullOrEmpty, isNullOrEmpty } from "tools";

export function ThirdPartyGet(thirdPartyIdentifier, callbackFn, errorFn) {
  ApimGet("api/TpCopernic/ThirdParties/" + thirdPartyIdentifier, c => callbackFn(c), e => errorFn(e));
}

export function ThirdPartySearch(criterias, callbackFn, errorFn) {
  //const queryString = criterias.searchMode === "Simple" ? ConvertCriteriaToSimpleQueryString(criterias) : ConvertCriteriaToQueryString(criterias);
  const queryString = ConvertCriteriaToQueryString(criterias);
  return ApimGet("api/TpCopernic/ThirdParties" + queryString, countries => callbackFn(countries), e => errorFn(e));
}

// Convert Criteria To Simple Query String
function ConvertCriteriaToSimpleQueryString(criteria) {
  var queryString = "";
  var queryParams = [];

  if (typeof criteria.criteriaFullText !== "undefined" && criteria.criteriaFullText !== null && criteria.criteriaFullText !== "") {
    queryParams.push("FreeSearch=" + criteria.criteriaFullText);
  }
  if (criteria.criteriaCountry !== null && criteria.criteriaCountry !== "") {
    queryParams.push("Country=" + criteria.criteriaCountry);
  }
  if (!isNullOrEmpty(criteria.startPage)) {
    queryParams.push("StartPage=" + criteria.startPage);
  }
  if (!isNullOrEmpty(criteria.pageSize)) {
    queryParams.push("PageSize=" + criteria.pageSize);
  }
  if (!isNullOrEmpty(criteria.orderProperty)) {
    queryParams.push("OrderProperty=" + criteria.orderProperty);
  }
  if (!isNullOrEmpty(criteria.isDescendingOrder)) {
    queryParams.push("IsDescendingOrder=" + criteria.isDescendingOrder);
  }

  if (!isArrayNullOrEmpty(queryParams)) queryString = "?" + queryParams.join("&");

  return queryString;
}

function ConvertCriteriaToQueryString(criteria) {
  var queryString = "";
  var queryParams = [];

  if (!isNullOrEmpty(criteria.criteriaMdmId)) {
    queryParams.push("MdmId=" + criteria.criteriaMdmId);
  }
  if (!isNullOrEmpty(criteria.criteriaMigCode)) {
    queryParams.push("MigCode=" + criteria.criteriaMigCode);
  }
  if (!isNullOrEmpty(criteria.criteriaLegalIdentifier)) {
    queryParams.push("LegalIdentifier=" + criteria.criteriaLegalIdentifier);
  }
  if (!isNullOrEmpty(criteria.criteriaAn8Iris3)) {
    queryParams.push("An8Iris3=" + criteria.criteriaAn8Iris3);
  }
  if (!isNullOrEmpty(criteria.criteriaAddress)) {
    queryParams.push("LineAddress=" + criteria.criteriaAddress);
  }
  if (!isArrayNullOrEmpty(criteria.categories)) {
    queryParams.push("Category=" + criteria.categories.join("|"));
  }
  if (!isArrayNullOrEmpty(criteria.statuses)) {
    queryParams.push("Status=" + criteria.statuses.join("|"));
  }
  if (!isArrayNullOrEmpty(criteria.principalEstablishment)) {
    queryParams.push("PrincipalEstablishment=" + criteria.principalEstablishment);
  }
  if (!isNullOrEmpty(criteria.criteriaLongName)) {
    queryParams.push("LongName=" + criteria.criteriaLongName);
  }
  if (!isNullOrEmpty(criteria.criteriaCity)) {
    queryParams.push("City=" + criteria.criteriaCity);
  }
  if (!isNullOrEmpty(criteria.criteriaCountry)) {
    queryParams.push("Country=" + criteria.criteriaCountry);
  }
  if (!isNullOrEmpty(criteria.criteriaTpCountry)) {
    queryParams.push("TpCountry=" + criteria.criteriaTpCountry);
  }
  if (!isNullOrEmpty(criteria.localSearch)) {
    queryParams.push("LocalSearch=" + criteria.localSearch);
  }
  if (!isNullOrEmpty(criteria.criteriaDuns)) {
    queryParams.push("Duns=" + criteria.criteriaDuns);
  }
  if (!isArrayNullOrEmpty(criteria.types)) {
    for (var i = 0; i < criteria.types.length; i++) {
      var type = criteria.types[i];
      switch (type) {
        case "isCustomer":
          queryParams.push("CustomerSearch=true");
          break;
        case "isSupplier":
          queryParams.push("SupplierSearch=true");
          break;
        case "isPointOfSale":
          queryParams.push("PointOfSaleSearch=true");
          break;
        case "isBeneficiary":
          queryParams.push("BeneficiarySearch=true");
          break;
        case "isAccount":
          queryParams.push("AccountSearch=true");
          break;
      }
    }
  }

  if (!isNullOrEmpty(criteria.startPage)) {
    queryParams.push("StartPage=" + criteria.startPage);
  }
  if (!isNullOrEmpty(criteria.pageSize)) {
    queryParams.push("PageSize=" + criteria.pageSize);
  }
  if (!isNullOrEmpty(criteria.orderProperty)) {
    queryParams.push("OrderProperty=" + criteria.orderProperty);
  }
  if (!isNullOrEmpty(criteria.isDescendingOrder)) {
    queryParams.push("IsDescendingOrder=" + criteria.isDescendingOrder);
  }
  if (!isNullOrEmpty(criteria.beneficiarySupplierSearch)) {
    queryParams.push("BeneficiarySupplierSearch=" + criteria.beneficiarySupplierSearch);
  }
  if (!isArrayNullOrEmpty(queryParams)) queryString = "?" + queryParams.join("&");

  return queryString;
}

export function ThirdPartyCrupdate(thirdParty, callbackFn, errorFn) {
  ApimPost("api/TpCopernic/ThirdParties", thirdParty, c => callbackFn(c), e => errorFn(e));
}

export function ThirdPartyAutocomplete(thirdParty, callbackFn, errorFn) {
  ApimPost("api/TpCopernic/ThirdPartyValidation", thirdParty, c => callbackFn(c), e => errorFn(e));
}

export function ThirdPartyValidate(thirdParty, workflowStep, callbackFn, errorFn) {
  ApimPost("api/TpCopernic/ThirdPartyValidation/" + workflowStep, thirdParty, c => callbackFn(c), e => errorFn(e));
}
