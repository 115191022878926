import React from 'react';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from '../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle';
import { isArray } from '../../tools';
import { Alert } from '@material-ui/lab';

function AlertError({ error, defaultLang }) {
    if (!defaultLang) defaultLang = 'FR';
    let message = '';
    if (error.response && error.response.data) {
        let data = error.response.data;
        if (isArray(data)) {
            if (data.length == 1)
                message = <><strong>{data[0].code}</strong> - {defaultLang === 'FR' ? data[0].descriptionFr : data[0].descriptionEn}</>;
            else
                message = <ul>{data.map(d => <li><strong>{d.code}</strong> - {defaultLang === 'FR' ? d.descriptionFr : d.descriptionEn}</li>)}</ul>;
        }
        else
            message = data;
    }
    else {
        message = error.message;
    }

    return (<Alert severity="error">{message}</Alert>);
}

export default withStyles(thirdPartySearchCriteriaStyle)(AlertError);