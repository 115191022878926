import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import throttle from 'lodash/throttle';
import diacritic from 'diacritic';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

//lingui
import { Trans } from '@lingui/macro';

import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import { highlightText } from '../../module/tools';
import { isArrayNullOrEmpty } from '../../tools';
import { Tooltip } from '@material-ui/core';

function OldStructureAutocompleteWithDefaultValue({ selectedValue, onValueChange, structures, readOnly, error, label }) {
    var [value, setValue] = React.useState(null);
    var [options] = React.useState(structures.map(s => {
        return { text: s.identifier + " - " + s.oldIdentifier + " - " + s.label, value: s.identifier, type: s.type };
    }));

    React.useEffect(() => {
        if (selectedValue) {
            setValue(options.find(c => c.value === selectedValue));
        }
    }, [selectedValue]);


    if (typeof readOnly === "undefined")
        readOnly = false;

    var customInputProps = {
        readOnly: { readOnly },
        disabled: { readOnly },
    };

    return (
        <Autocomplete
            clearOnEscape
            options={options}
            getOptionLabel={(option) => option.text}
            onChange={(event, newValue) => {
                setValue(newValue);
                if (typeof onValueChange !== "undefined")
                    onValueChange(newValue ? newValue.value : null);
            }}
            value={value}
            renderInput={(params) => {
                if (readOnly)
                    return (
                        <Tooltip title={value?.text}>
                            <TextField
                                {...params}
                                margin="normal"
                                fullWidth
                                InputProps={{
                                    readOnly: { readOnly },
                                    disabled: { readOnly },
                                }}
                                error={error}
                                label={!!label ? label : null}
                            />
                        </Tooltip>
                    );
                else
                    return (
                        <TextField
                            {...params}
                            margin="normal"
                            fullWidth
                            error={error}
                            label={!!label ? label : null}
                        />
                    );
            }}
            renderOption={(option, { inputValue }) => {
                const matches = match(option.text, inputValue);
                const parts = parse(option.text, matches);

                return (
                    <div>
                        {parts.map((part, index) => (
                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                {part.text}
                            </span>
                        ))}
                    </div>
                );
            }}
        />
    );
}

const mapStateToProps = state => {
    return {
    };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(OldStructureAutocompleteWithDefaultValue));