import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-6';
import { connect, useDispatch } from 'react-redux';
import * as Actions from 'module/amc/store/actions/Job.Actions';

// custom components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CountryAutocomplete from 'components/Autocomplete/CountryAutocomplete';
import Select, { components } from 'react-select';

// helpers
import LocalStorageHelper from "services/common/LocalStorageHelper";

// core components
import { Check, SearchOutlined, ViewDay, ViewHeadline, ViewQuilt, Add } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Checkbox, FormControlLabel, Typography, IconButton } from "@material-ui/core";

//lingui
import { Trans } from '@lingui/macro';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import JobPhaseListTab from './Parts/JobPhaseListTab';
import JobPhaseBudgetListTab from './Parts/JobPhaseBudgetListTab';

function JobPhasesBudgetTab({ amc, addPhaseBudget, isReadonly }) {
    const dispatch = useDispatch();

    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");


    var item = !!amc.currentItem['JOB'] ? amc.currentItem['JOB'] : {};
    var warnings = amc.errors['JOB'];

    return (
        <Card
            style={{
                ...CardInlineStyle.card
            }}
        >
            <CardHeader
                style={{
                    ...CardInlineStyle.cardHeader
                }}
                icon>
                <GridContainer>
                    <GridItem xs={11} sm={11} md={11}>
                        <h4><Trans>Phases Budget</Trans></h4>
                    </GridItem>
                    <GridItem xs={1} sm={1} md={1}>
                        <IconButton onClick={addPhaseBudget}>
                            <Add style={{ color: 'white' }} />
                        </IconButton>
                    </GridItem>
                </GridContainer>
            </CardHeader>
            <CardBody>
                <JobPhaseBudgetListTab isReadonly={isReadonly} job={item} phases={!!item.jobPhaseBudgets ? item.jobPhaseBudgets.sort((a, b) => {
                    let comparison = 0;
                    if (a.code > b.code) {
                        comparison = 1;
                    } else if (a.code < b.code) {
                        comparison = -1;
                    }
                    return comparison;
                }) : []} />
            </CardBody>
        </Card>
    );
}

const CardInlineStyle = {
    card: {
        marginTop: "10px",
    },
    cardHeader: {
        backgroundColor: "rgba(0, 172, 193, 0.6)",
        margin: "0",
        paddingLeft: "10px",
    }
};

const mapStateToProps = state => {
    return {
        amc: state.AMC_Reducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCriterias: criterias => {
            dispatch(Actions.SetCriterias(criterias))
        },
        search: () => {
            dispatch(Actions.Search())
        },
        addPhaseBudget: () => {
            dispatch(Actions.AddPhaseBudget())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(amcSearchStyle)(JobPhasesBudgetTab));