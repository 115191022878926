import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import 'assets/scss/components/ThirdPartyResult.css';
import 'assets/scss/components/ThirdPartySearchCriteria.css';


import { Trans } from "@lingui/macro";
import { List, ListItem, Link, Button } from '@material-ui/core';
import { Popover } from '@material-ui/core';

const CardInlineStyle = {
    card: {
        marginTop: "10px",
    },
    cardHeader: {
        backgroundColor: "rgba(0, 172, 193, 0.6)",
        margin: "0",
        paddingLeft: "10px",
    },
};

function PopoverConfirm({ anchor, confirmHandler, abortHandler, classes }) {
    const dispatch = useDispatch();

    const open = Boolean(anchor);

    return (
        <Popover
            open={open}
            anchorEl={anchor}
            onClose={abortHandler}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
        >
            <div>
                <Button onClick={confirmHandler}>Confirm</Button>
                <Button onClick={abortHandler}>Abort</Button>
            </div>
        </Popover >
    );

}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(thirdPartySearchCriteriaStyle)(PopoverConfirm));