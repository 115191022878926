import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Trans } from "@lingui/macro";
import {
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  TextField
} from "@material-ui/core";
import { SearchOutlined, ExpandMore, ChevronRight, Inbox, Drafts } from "@material-ui/icons";
import { TreeView, TreeItem } from "@material-ui/lab";
import { withStyles } from "@material-ui/styles";
import { formatISO } from "date-fns";
import * as HistoryActions from "store/History/HistoryActions";
import * as StructureActions from "./store/actions";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardError from "components/Card/CardError";
import CardMessage from "components/Card/CardMessage";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import DateSelector from "components/Common/DateSelector";
import Button from "components/CustomButtons/Button.jsx";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import { StructureGetTree, StructureNew } from "./actions/StructureActions";
import { isNullOrEmpty } from "../../tools";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

function StructureTreeIris2({ classes, defaultLang }) {
  const [state, setState] = useState({ isLoading: false, error: null, createError: null });
  const [criteria, setCriteria] = useState({ dateRef: formatISO(new Date()) });
  const [filterText, setFilterText] = useState("");
  const [context, setContext] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    launchSearch();
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => (state.structures ? treeFilter(filterText) : null), 500);
    return () => clearTimeout(timeOutId);
  }, [filterText]);

  const launchSearch = () => {
    setState({ ...state, isLoading: true });

    StructureGetTree(
      "iris2",
      criteria.dateRef,
      result => {
        treeFilter("", result);
      },
      e => setState({ ...state, isLoading: false, error: e })
    );
  };

  const treeFilter = (text, result) => {
    let expanded = [1];
    let structures = result ? result.structures : state.structures;
    let relations = result ? result.relations : state.relations;
    let filteredStructures = structures;
    let filteredRelations = relations;
    if (text.length > 2) {
      let regex = new RegExp(text, "i");
      let filtered = state.structures
        .filter(s => regex.test(s.label))
        .map(s => allParents(s.id, state.relations))
        .join(",")
        .split(",");
      expanded = filtered.flat();
      //filteredStructures = structures.filter(s => expanded.some(e => e == s.id));
      //filteredRelations = relations.filter(r => filteredStructures.some(s => s.id === r.toId));
    }

    let nodes = treeBuilder("1", filteredStructures, filteredRelations, text);
    setFilterText(text);
    setState({
      ...state,
      isLoading: false,
      structures: structures,
      relations: relations,
      treeNodes: { nodes: nodes, expanded: expanded }
    });
  };

  const treeBuilder = (structureId, structures, relations, filterText) => {
    var s = structures.find(s => s.id === structureId);
    if (!s) {
      return;
    }
    var map = relations.filter(r => r.fromId === s.id).map(r => treeBuilder(r.toId, structures, relations, filterText));
    var id = s.id;

    let chipLabel, nodeLabel;
    if (s.structureType === "UE") {
      chipLabel = s.id.indexOf("¤") > -1 ? "ACT" : "SEC";
      nodeLabel = s.label;
    } else {
      chipLabel = s.structureType;
      nodeLabel = s.label;
    }

    if (!isNullOrEmpty(filterText)) {
      // Split on highlight term and include term into parts, ignore case
      const parts = nodeLabel.split(new RegExp(`(${filterText})`, "gi"));
      nodeLabel = (
        <span>
          {parts.map((part, i) => (
            <span key={i} style={{ backgroundColor: part.toLowerCase() === filterText.toLowerCase() ? "yellow" : "white" }}>
              {part}
            </span>
          ))}
        </span>
      );
    }
    var label = (
      <>
        <Chip size="small" variant="outlined" label={chipLabel} />
        <span> {nodeLabel}</span>
      </>
    );

    return (
      <TreeItem key={id} nodeId={id} label={label} onDoubleClick={e => handleOpenStructure(e, s)} onContextMenu={e => handleRightClick(e, s)}>
        {map}
      </TreeItem>
    );
  };

  const handleRightClick = (event, structure) => {
    event.stopPropagation();
    event.preventDefault();
    setContext({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      menu: buildContextMenu(structure)
    });
  };

  const buildContextMenu = structure => {
    let possibleChildren;
    switch (structure.structureType) {
      case "SJ":
        possibleChildren = ["CDG"];
        break;
      case "CDG":
        possibleChildren = ["UE"];
        break;
      default:
        possibleChildren = [];
        break;
    }

    let listItemChildren = possibleChildren.map((c, i) => {
      let title = "";
      if (c === "DIR") {
        title = <Trans>Structure_Tree_NewDIR</Trans>;
      } else if (c === "ERT") {
        title = <Trans>Structure_Tree_NewERT</Trans>;
      } else if (c === "AREA") {
        title = <Trans>Structure_Tree_NewAREA</Trans>;
      } else if (c === "DIV") {
        title = <Trans>Structure_Tree_NewDIV</Trans>;
      } else if (c === "SJ") {
        title = <Trans>Structure_Tree_NewSJ</Trans>;
      } else if (c === "CDG") {
        title = <Trans>Structure_Tree_NewCDG</Trans>;
      } else if (c === "UE") {
        title = <Trans>Structure_Tree_NewUE</Trans>;
      } else if (c === "COMP") {
        title = <Trans>Structure_Tree_NewCOMP</Trans>;
      } else if (c === "UP") {
        title = <Trans>Structure_Tree_NewUP</Trans>;
      } else if (c === "EP") {
        title = <Trans>Structure_Tree_NewEP</Trans>;
      }
      return (
        <ListItem button onClick={e => handleCreateStructure(e, structure, c)}>
          <ListItemText primary={title} />
        </ListItem>
      );
    });

    if (listItemChildren.length > 0) {
      listItemChildren = [<Divider />, ...listItemChildren];
    }

    return (
      <>
        <ListItem button onClick={e => handleOpenStructure(e, structure)}>
          <ListItemText primary={<Trans>Structure_Tree_Open</Trans>} primaryTypographyProps={{ style: { fontWeight: "bold" } }} />
        </ListItem>
        {listItemChildren}
      </>
    );
  };

  const handleOpenStructure = (event, structure) => {
    event.stopPropagation();
    event.preventDefault();
    setContext(null);
    dispatch(StructureActions.OpenStructure(structure.structureType, structure.identifiant));
    dispatch(HistoryActions.addHistoryStructure(structure));
  };

  const handleCreateStructure = (event, parent, structureType) => {
    event.stopPropagation();
    event.preventDefault();
    setContext(null);
    setState({ ...state, isLoading: true });
    StructureNew(
      parent.structureType,
      parent.identifiant,
      structureType,
      s => {
        dispatch(StructureActions.OpenStructure(null, null, s));
        setState({ ...state, isLoading: false });
      },
      e => setState({ ...state, isLoading: false, createError: e })
    );
  };

  var treeView = "";
  if (state.isLoading) {
    treeView = <CircularProgress />;
  } else if (state.error) {
    treeView = <CardError error={state.error} />;
  } else if (state.treeNodes) {
    treeView = (
      <Card>
        <CardHeader>
          <TextField label="Filtre" defaultValue={filterText} onChange={e => setFilterText(e.target.value)} variant="outlined" />
        </CardHeader>
        <CardBody>
          <TreeView
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            expanded={state.treeNodes.expanded}
            onNodeToggle={(e, nodeIds) => setState({ ...state, treeNodes: { ...state.treeNodes, expanded: nodeIds } })}
          >
            {state.treeNodes.nodes}
          </TreeView>
        </CardBody>
      </Card>
    );
  } else {
    treeView = <CardMessage message={<Trans>NoResult</Trans>} />;
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={9} sm={9} md={9}>
          <Paper>{treeView}</Paper>
        </GridItem>
        <GridItem xs={3} sm={3} md={3}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <SearchOutlined />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  launchSearch();
                }}
                autoComplete="false"
              >
                <div>
                  <DateSelector
                    value={criteria.dateRef}
                    label="DateRef"
                    onChange={d => setCriteria({ ...criteria, dateRef: d })}
                    isEditable
                    defaultLang={defaultLang}
                  />
                </div>
                <div className={classes.flexRight}>
                  <Button type="submit" color="info">
                    <Trans> Search </Trans>
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Popover
        open={Boolean(context)}
        anchorReference="anchorPosition"
        anchorPosition={context ? { top: context.mouseY, left: context.mouseX } : undefined}
        onClose={() => setContext(null)}
      >
        <List component="nav">{context ? context.menu : ""}</List>
      </Popover>
      <Dialog open={Boolean(state.createError)} onClose={() => setState({ ...state, createError: null })}>
        <DialogContent>
          <CardError error={state.createError} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setState({ ...state, createError: null })}>
            <Trans>Close</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function allParents(structureId, relations) {
  var directParents = relations.filter(s => s.toId === structureId).map(r => r.fromId);
  if (directParents.length > 0) {
    return directParents.map(p => allParents(p, relations) + "," + structureId).join(",");
  }
  return structureId;
}

const mapStateToProps = state => {
  return {
    defaultLang: state.AuthenticationReducer.user.language
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(StructureTreeIris2));
