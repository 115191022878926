import { Trans } from "@lingui/macro";
import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Add, Assignment, Edit } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import BaseSelect from "components/Common/BaseSelector";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import ReactTable from "react-table-6";
import { StructureSj } from "store/MasterValue/MasterValueTypes";
import { isArrayNullOrEmpty, isNull } from "../../../tools";
import * as Actions from "../../store/actions";
import DialogCustomerSj from "./DialogCustomerSj";

const TabCustomerSj = function({ customerCtx, canAddSj, allSjs, availableSjs, defaultLang }) {
  var dispatch = useDispatch();
  var [isFirstTime, setIsFirstTime] = useState(true);
  var [customerSjIndex, setCustomerSjIndex] = useState(null);
  var [openDialog, setOpenDialog] = useState(false);
  var [newSjIdentifier, setNewSjIdentifier] = useState(null);
  const customer = customerCtx.customer;
  var sjs = customerCtx.sjs;

  useEffect(() => {
    if (isFirstTime) {
      dispatch(Actions.CustomerSjGet(customer.thirdPartyId));
      setIsFirstTime(false);
    }
  });

  ///------------------------------
  /// Crée le composant graphique pour l'ajout d'un lien Customer/SJ
  ///------------------------------
  function showAddSj() {
    return (
      <div>
        <Card
          style={{
            ...CardInlineStyle.card
          }}
        >
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <h4>
              <Trans>Add link</Trans>
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={11} sm={11} md={11}>
                <BaseSelect
                  items={availableSjs}
                  displayedProperties={{
                    keyProperty: "identifier",
                    valueProperty: "label"
                  }}
                  transformValue={item => {
                    var jadeidentifierPart = "";
                    if (!!item.newIdentifier) jadeidentifierPart = " - " + item.newIdentifier;
                    return item.identifier + jadeidentifierPart + " - " + item.oldIdentifier + " - " + item.label;
                  }}
                  value={newSjIdentifier}
                  onChange={event => {
                    setNewSjIdentifier(event.value);
                  }}
                  isSearchable={true}
                />
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <IconButton aria-label="Add" onClick={() => addSj()}>
                  <Add />
                </IconButton>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }

  function addSj() {
    if (isNull(newSjIdentifier)) return;

    dispatch(
      Actions.CustomerSjAdd({
        identifier: 0,
        customerIdentifier: customer.thirdPartyIdentifier,
        sjCode: newSjIdentifier
      })
    );
  }

  function showDetail(sjIndex) {
    setOpenDialog(true);
    setCustomerSjIndex(sjIndex);
  }

  function convertDataToReactTable() {
    if (isNull(sjs)) return [];

    return sjs.map(prop => {
      var sj = prop.sj;

      var icon = "";
      if (prop.action !== "NONE") {
        var iconStatus = "";
        var iconTitle = "";
        if (prop.action === "INSERT") {
          iconTitle = <Trans> InsertSj </Trans>;
          iconStatus = <Add />;
        } else if (prop.action === "UPDATE") {
          iconTitle = <Trans> UpdateSj </Trans>;
          iconStatus = <Edit />;
        }

        icon = <Tooltip title={iconTitle}>{iconStatus}</Tooltip>;
      }

      var knownSj = allSjs.find(s => s.identifier === sj.sjCode);
      var sjOldIdentifier, sjNewIdentifier, sjLabel;
      if (knownSj) {
        sjOldIdentifier = knownSj.oldIdentifier;
        sjNewIdentifier = knownSj.newIdentifier;
        sjLabel = knownSj.label;
      }

      return {
        sj: sj.sjCode,
        sjOldIdentifier: sjOldIdentifier,
        sjNewIdentifier: sjNewIdentifier,
        sjLabel: sjLabel,
        iris3: sj.aN8Iris3,
        iris2: sj.aN8Iris2,
        noniris: sj.nonIrisId,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a like kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                let itemIndex = sjs.findIndex(o => o.sj.sjCode !== 0 && o.sj.sjCode === sj.sjCode);
                showDetail(itemIndex);
              }}
              color="info"
              className="like"
            >
              <Assignment />
            </Button>
            {icon}
          </div>
        )
      };
    });
  }

  if (isFirstTime)
    return (
      <center>
        <CircularProgress />
      </center>
    );

  var showAddSj = canAddSj ? showAddSj() : "";
  return (
    <div>
      {showAddSj}
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <Trans>Existing links</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <ReactTable
            data={convertDataToReactTable()}
            columns={[
              {
                Header: <Trans>Actions</Trans>,
                accessor: "actions",
                sortable: false,
                filterable: false
              },
              {
                Header: <Trans>Sj</Trans>,
                accessor: "sj",
                filterable: true
              },
              {
                Header: <Trans>SjNewIdentifier</Trans>,
                accessor: "sjNewIdentifier",
                filterable: true
              },
              {
                Header: <Trans>SjOldIdentifier</Trans>,
                accessor: "sjOldIdentifier",
                filterable: true
              },
              {
                Header: <Trans>SjLabel</Trans>,
                accessor: "sjLabel",
                filterable: true,
                Cell: rowInfo => {
                  var label = rowInfo.original.sjLabel;
                  return <span title={label}>{label}</span>;
                }
              },
              {
                Header: <Trans>Iris 3</Trans>,
                accessor: "iris3",
                filterable: true
              },
              {
                Header: <Trans>Iris 2</Trans>,
                accessor: "iris2",
                filterable: true
              },
              {
                Header: <Trans>Non Iris</Trans>,
                accessor: "noniris",
                filterable: true
              }
            ]}
            defaultPageSize={5}
            showPaginationBottom={true}
            showPageSizeOptions={false}
            className="-striped -highlight"
            showFilters={true}
            style={ReactTableStyle.main}
          />
        </CardBody>
      </Card>
      <DialogCustomerSj openDialog={openDialog} setOpenDialog={v => setOpenDialog(v)} customerSjIndex={customerSjIndex} defaultLang={defaultLang} />
    </div>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};
const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    customerCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].customerCtx : null,
    availableSjs: state.AuthenticationReducer.user.structureTpc.sjs,
    allSjs: state.MasterValueReducer[StructureSj]
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(TabCustomerSj));
