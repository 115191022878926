import { ApimGetPromise, ApimPostPromise, ApimDeletePromise } from "components/Common/ApimSender";

export function GroupGetAll() {
  return ApimGetPromise("api/tpColumbus/groups");
}

export function GroupTreeGet() {
  return ApimGetPromise("api/tpColumbus/groups/GroupsTree");
}
export function GroupGet(groupId) {
  return ApimGetPromise(`api/tpColumbus/groups/${groupId}`);
}

export function GroupCrupdate(group) {
  return ApimPostPromise(`api/tpColumbus/groups`, group);
}

export function GroupDelete(group) {
  return ApimDeletePromise(`api/tpColumbus/groups/${group.id}`);
}
