import React from 'react';
import FormLabel from "@material-ui/core/FormLabel";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import { Trans } from "@lingui/macro";
import { Divider } from '@material-ui/core';

const CardInlineStyle = {
    card: {
        marginTop: "10px",
    },
    cardHeader: {
        backgroundColor: "rgba(0, 172, 193, 0.6)",
        margin: "0",
        paddingLeft: "10px",
    },
    divider: {
        marginTop: "10px",
        marginBottom: "10px",
    }
};

function RecapThirdParty({ thirdParty }) {

    return (

        <Card
            style={{
                ...CardInlineStyle.card
            }}
        >
            <CardHeader
                style={{
                    ...CardInlineStyle.cardHeader
                }}
                icon>
                <h4><Trans>Third party summary</Trans></h4>
            </CardHeader>
            <CardBody>
                <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                        <FormLabel><Trans>Long name</Trans></FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                        <TextField
                            margin="dense"
                            type="text"
                            fullWidth
                            value={thirdParty.LongName}
                            readonly
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                        <FormLabel><Trans>Colas name</Trans></FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                        <TextField
                            margin="dense"
                            type="text"
                            fullWidth
                            value={thirdParty.CustomLongName}
                        />
                    </GridItem>
                </GridContainer>
                <Divider style={{...CardInlineStyle.divider }} />
                <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                        <FormLabel><Trans>Legal Identifier</Trans></FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                        <TextField
                            margin="dense"
                            type="text"
                            fullWidth
                            value={thirdParty.LegalIdentifier}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                        <FormLabel><Trans>Tax code</Trans></FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                        <TextField
                            margin="dense"
                            type="text"
                            fullWidth
                            value={thirdParty.TaxCode}
                        />
                    </GridItem>
                </GridContainer>
                <Divider style={{ ...CardInlineStyle.divider }} />
                <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                        <FormLabel><Trans>Address Line1</Trans></FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                        <TextField
                            margin="dense"
                            type="text"
                            fullWidth
                            value={thirdParty.Address_Line1}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                        <FormLabel><Trans>Address Line2</Trans></FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                        <TextField
                            margin="dense"
                            type="text"
                            fullWidth
                            value={thirdParty.Address_Line2}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                        <FormLabel><Trans>Zip code</Trans></FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                        <TextField
                            margin="dense"
                            type="text"
                            fullWidth
                            value={thirdParty.Address_ZipCode}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                        <FormLabel><Trans>City</Trans></FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                        <TextField
                            margin="dense"
                            type="text"
                            fullWidth
                            value={thirdParty.Address_City}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                        <FormLabel><Trans>Country</Trans></FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                        <TextField
                            margin="dense"
                            type="text"
                            fullWidth
                            value={thirdParty.Address_CountryCode}
                        />
                    </GridItem>
                </GridContainer>
            </CardBody>
        </Card>
    );
}

export default RecapThirdParty;