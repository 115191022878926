import { InputAdornment } from "@material-ui/core";
import { isNullOrEmpty, isArrayNullOrEmpty, isNum } from "../../../../tools";

export function ValidateGeneral(site) {
  var errors = [];

  if (isNullOrEmpty(site.name)) {
    errors = [...errors, { step: 1, code: "NameMissing", isMandatory: true }];
  }
  if (isNullOrEmpty(site.locationStatusCode)) {
    errors = [...errors, { step: 1, code: "StatusMissing", isMandatory: true }];
  }
  if (isNullOrEmpty(site.address_Line1)) {
    errors = [...errors, { step: 1, code: "AddressLine1Missing", isMandatory: true }];
  }
  if (isNullOrEmpty(site.address_City)) {
    errors = [...errors, { step: 1, code: "AddressCityMissing", isMandatory: true }];
  }
  if (isNullOrEmpty(site.address_ZipCode)) {
    errors = [...errors, { step: 1, code: "AddressZipCodeMissing", isMandatory: true }];
  }
  if (isNullOrEmpty(site.address_CountryCode)) {
    errors = [...errors, { step: 1, code: "AddressCountryMissing", isMandatory: true }];
  }
  if (isNullOrEmpty(site.gpsCoordinates.latitude)) {
    errors = [...errors, { step: 1, code: "AddressGpsMissing", isMandatory: true }];
  }
  if (isNullOrEmpty(site.gpsCoordinates.longitude)) {
    errors = [...errors, { step: 1, code: "AddressGpsMissing", isMandatory: true }];
  }
  if (!isNum(site.gpsCoordinates.latitude)) {
    errors = [...errors, { step: 1, code: "LatitudeWrongFormat", isMandatory: true }];
  }
  if (!isNum(site.gpsCoordinates.longitude)) {
    errors = [...errors, { step: 1, code: "LongitudeWrongFormat", isMandatory: true }];
  }

  return { site: site, errors: errors };
}
export function ValidateType(site) {
  var errors = [];

  if (isNullOrEmpty(site.locationNatureCode)) {
    errors = [...errors, { step: 1, code: "SiteNatureMissing", isMandatory: true }];
  }

  return { site: site, errors: errors };
}

export function ValidateStructures(site, force) {
  var errors = [];

  if (!force && isArrayNullOrEmpty(site.structures)) {
    errors = [...errors, { step: 4, code: "StructureMissing", isMandatory: false }];
  }

  return { site: site, errors: errors };
}
