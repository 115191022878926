import React from "react";
import { RootStore } from 'store/RootStore';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import KeysValuesDisplay from 'components/Common/KeysValuesDisplay.jsx';
import Selector from 'components/Common/Selector.jsx';
import IconButton from "@material-ui/core/IconButton";
import AddIcon from '@material-ui/icons/Add';
import CustomInput from "components/CustomInput/CustomInput.jsx";

//lingui
import { Trans } from "@lingui/macro";
import { isNull, isArrayNullOrEmpty } from "../../../tools";

const CardInlineStyle = {
    card: {
        marginTop: "10px",
    },
    cardHeader: {
        backgroundColor: "rgba(0, 172, 193, 0.6)",
        margin: "0",
        paddingLeft: "10px",
    }
};

class PrivateStep2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newPhone: {
                title: '',
                value: ''
            },
            newEmail: {
                title: '',
                value: ''
            },
            phoneErrors: [],
            emailErrors: []
        }
        this.handleNewPhoneChange = this.handleNewPhoneChange.bind(this);
        this.handleNewPhoneAdd = this.handleNewPhoneAdd.bind(this);
        this.handleNewEmailChange = this.handleNewEmailChange.bind(this);
        this.handleNewEmailAdd = this.handleNewEmailAdd.bind(this);
    }

    handleNewPhoneAdd() {
        var newPhone = this.state.newPhone;
        var errors = [];
        var isnewPhoneOk = true;
        if (newPhone.title === undefined || newPhone.title === '') {
            errors['phoneTitle'] = true;
            isnewPhoneOk = false;
        }
        if (newPhone.value === undefined || newPhone.value === '') {
            errors['phoneValue'] = true;
            isnewPhoneOk = false;
        }

        if (!isnewPhoneOk) {
            this.setState({ phoneErrors: errors });
            return;
        }

        if (!isNull(this.props.handleChange)) {
            var thirdParty = this.props.thirdParty;
            thirdParty = {
                ...thirdParty,
                thirdPartyContacts: thirdParty.thirdPartyContacts.map((item) => {
                    if (item.contactTypeCode == 'GENERAL') {
                        item.details = [
                            ...item.details,
                            {
                                contactDetailTypeCode: 'PHONE',
                                title: newPhone.title,
                                value: newPhone.value
                            }
                        ]
                    }
                    return item;
                })
            }
            this.props.handleChange(thirdParty);
        }

        this.setState({ phoneErrors: [], newPhone: { title: '', value: '' } });
    }

    handleNewEmailAdd() {
        var newEmail = this.state.newEmail;
        var errors = [];
        var isnewEmailOk = true;
        if (newEmail.title === undefined || newEmail.title === '') {
            errors['emailTitle'] = true;
            isnewEmailOk = false;
        }
        if (newEmail.value === undefined || newEmail.value === '') {
            errors['emailValue'] = true;
            isnewEmailOk = false;
        }

        if (!isnewEmailOk) {
            this.setState({ emailErrors: errors });
            return;
        }

        if (!isNull(this.props.handleChange)) {
            var thirdParty = this.props.thirdParty;
            thirdParty = {
                ...thirdParty,
                thirdPartyContacts: thirdParty.thirdPartyContacts.map((item) => {
                    if (item.contactTypeCode == 'GENERAL') {
                        item.details = [
                            ...item.details,
                            {
                                contactDetailTypeCode: 'MAIL',
                                title: newEmail.title,
                                value: newEmail.value
                            }
                        ]
                    }
                    return item;
                })
            }
            this.props.handleChange(thirdParty);
        }

        this.setState({ emailErrors: [], newEmail: { title: '', value: '' } });
    }

    handleNewPhoneChange(event, property) {
        var newPhone = this.state.newPhone;
        newPhone[property] = event.target.value;
        this.setState({ newPhone: newPhone });
    }

    handleNewEmailChange(event, property) {
        var newEmail = this.state.newEmail;
        newEmail[property] = event.target.value;
        this.setState({ newEmail: newEmail });
    }

    render() {
        var item = this.props.thirdParty;
        var contact = item.thirdPartyContacts.find((item) => item.contactTypeCode === 'GENERAL');
        var phones = [], mails = [];
        if (!isNull(contact)) {
            phones = contact.details.filter((item) => item.contactDetailTypeCode === 'PHONE');
            mails = contact.details.filter((item) => item.contactDetailTypeCode === 'MAIL');
        }

        return (
            <form autoComplete="off">
                <div style={{ 'width': '100%', 'height': '90%' }}>
                    <GridContainer justify="center">
                        <GridItem xs={10} sm={10} md={10}>
                            <Card
                                style={{
                                    ...CardInlineStyle.card
                                }}
                            >
                                <CardHeader
                                    style={{
                                        ...CardInlineStyle.cardHeader
                                    }}
                                    icon>
                                    <h4>Phone Numbers</h4>
                                </CardHeader>
                                <CardBody>
                                    <KeysValuesDisplay
                                        collection={phones}
                                        displayedProperties={{
                                            keyProperty: 'title',
                                            valueProperty: 'value'
                                        }}
                                    >
                                        <tr>
                                            <td>
                                                <CustomInput
                                                    labelText={<Trans>Phone Title</Trans>}
                                                    id="criteriaFullTextSearch"
                                                    error={this.state.phoneErrors['phoneTitle']}
                                                    value={this.state.newPhone.title}
                                                    onChange={(event) => { this.handleNewPhoneChange(event, 'title'); }}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <CustomInput
                                                    labelText={<Trans>Phone Number</Trans>}
                                                    id="criteriaFullTextSearch"
                                                    error={this.state.phoneErrors['phoneValue']}
                                                    value={this.state.newPhone.value}
                                                    onChange={(event) => { this.handleNewPhoneChange(event, 'value'); }}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <IconButton aria-label="Add">
                                                    <AddIcon onClick={() => this.handleNewPhoneAdd()} />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    </KeysValuesDisplay>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                        <GridItem xs={10} sm={10} md={10}>
                            <Card
                                style={{
                                    ...CardInlineStyle.card
                                }}
                            >
                                <CardHeader
                                    style={{
                                        ...CardInlineStyle.cardHeader
                                    }}
                                    icon>
                                    <h4>Emails</h4>
                                </CardHeader>
                                <CardBody>
                                    <KeysValuesDisplay
                                        collection={mails}
                                        displayedProperties={{
                                            keyProperty: 'title',
                                            valueProperty: 'value'
                                        }}
                                    >
                                        <tr>
                                            <td>
                                                <CustomInput
                                                    labelText={<Trans>Email Title</Trans>}
                                                    error={this.state.emailErrors['emailTitle']}
                                                    value={this.state.newEmail.title}
                                                    onChange={(event) => { this.handleNewEmailChange(event, 'title'); }}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <CustomInput
                                                    labelText={<Trans>Email</Trans>}
                                                    error={this.state.emailErrors['emailValue']}
                                                    value={this.state.newEmail.value}
                                                    onChange={(event) => { this.handleNewEmailChange(event, 'value'); }}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <IconButton aria-label="Add">
                                                    <AddIcon onClick={() => this.handleNewEmailAdd()} />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    </KeysValuesDisplay>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </form>
        );
    };
}


export default PrivateStep2;