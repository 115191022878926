import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import KeyValueEditor from "components/Common/KeyValueEditor";
import React from "react";
import { connect } from "react-redux";
import { TpIndustryCode } from "store/MasterValue/MasterValueTypes";
import { isArrayNullOrEmpty } from "../../../tools";
import * as Actions from "../../store/actions";

const TabThirdPartySource = function({ thirdPartyCtx, onValueChanged, masterValues }) {
  var thirdParty = thirdPartyCtx.thirdParty;
  var countryCode = thirdParty.countryCode;
  var isEditable = thirdParty.thirdPartyStatusCode === "Closed" ? thirdPartyCtx.isEditableClosed : thirdPartyCtx.isEditable;

  var industryCodes = masterValues[TpIndustryCode];

  return (
    <>
      <KeyValueEditor
        cardLabel={<Trans>Legal identifiers</Trans>}
        keyMasterValue="TpLegalIdentifier"
        keyMasterValueFilter={i => i.countryCode === countryCode}
        keyCollectionKey="code"
        keyCollectionValue={"label"}
        keyPropertyName="legalIdentifierCode"
        valuePropertyName="value"
        items={thirdParty.thirdParty_LegalIdentifiers}
        onValueChanged={items => onValueChanged({ ...thirdParty, thirdParty_LegalIdentifiers: items })}
        isEditable={isEditable}
        maxlength={20}
      />

      <KeyValueEditor
        cardLabel={<Trans>Industry codes</Trans>}
        keyCollection={industryCodes}
        keyCollectionKey="code"
        keyMasterValue="TpIndustryCode"
        keyCollectionValue={"label"}
        keyPropertyName="industryCodeIdentifier"
        valuePropertyName="value"
        items={thirdParty.thirdParty_ActivitySectors}
        onValueChanged={items => onValueChanged({ ...thirdParty, thirdParty_ActivitySectors: items })}
        isEditable={isEditable}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    thirdPartyCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].thirdPartyCtx : null,
    masterValues: state.MasterValueReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onValueChanged: thirdParty => {
      dispatch(Actions.EditThirdParty(thirdParty));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(TabThirdPartySource));
