import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";

import { Checkbox, Input, InputLabel, FormControl, ListItemText, MenuItem, NativeSelect, Select, ListSubheader } from "@material-ui/core";
import Muted from "../Typography/Muted.jsx";
import { isFunction, isNull, isNullOrEmpty, isArrayNullOrEmpty } from "../../tools";

// style
import "assets/scss/material-dashboard-pro-react/react-web-tabs.css";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";

//Lingui
import { Trans } from "@lingui/macro";
import { isArray } from "tools.jsx";

const InputInLineStyle = {
  textInput: {
    marginLeft: "10px"
  },
  listItem: {
    paddingTop: "2px",
    paddingBottom: "2px"
  },
  fullWidth: {
    width: "100%"
  }
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function Selector({
  displayedProperties, // objet {keyProperty, valueProperty} indiquant le nom des champs de l'objet contenu dans items pour affichage
  error, // string: message d'erreur affiché
  fullWidth, // booléen: indique si le champ doit couvrir la largeur du conteneur parent
  id, // string: identifiant HTML
  isEditable, // booléen: indique si le champ est en mode éditable (select) ou non (text)
  items, // array: liste des objets devant être affichés sur le select
  label, // string: description humaine du champ (sera ensapsulé dans un <Trans />)
  multiple, // booléen: indique si le champ est multiple ou non
  native, // booléen: indique si le type de select doit être NativeSelect https://material-ui.com/components/selects
  orderBy, // enum { "key" ; "value" }: trie les items par ordre croissant de clé ou de valeur
  transformValue, // function(item): fonction utilisée pour la transformation d'un item en texte
  value, // string: valeur à assigner (séparé par des , dans le cas d'une sélection multiple)
  onChange, // function(evt): fonction appelée en cas de changement de valeur par l'utilisateur
  useGroups, // booléen: indique si la liste des items gère les groupes
  classes
}) {
  function onLocalChange(event) {
    if (isFunction(onChange)) onChange(event);
  }

  function localTransformValue(item) {
    if (isNull(item)) return "";
    if (isFunction(transformValue)) return transformValue(item);
    else return item[displayedProperties.valueProperty];
  }

  if (!isArray(items)) {
    return <span>EmptyItems</span>;
  }

  if (isEditable) {
    const cleanedValue = isNull(value) ? "" : value;

    if (!isNull(items) && !isNull(orderBy)) {
      if (orderBy === "key") {
        items = items.sort((a, b) => (a[displayedProperties.keyProperty] > b[displayedProperties.keyProperty] ? 1 : -1));
      } else if (orderBy === "value") {
        items = items.sort((a, b) => (a[displayedProperties.valueProperty] > b[displayedProperties.valueProperty] ? 1 : -1));
      }
    }

    if (native === true) {
      var itemsNative = "";
      if (!isArrayNullOrEmpty(items)) {
        itemsNative = items.map(item => {
          var key = item[displayedProperties.keyProperty];
          return (
            <option key={key} value={key}>
              {localTransformValue(item)}
            </option>
          );
        });
      }

      var nativeSelect = (
        <NativeSelect
          inputProps={{ id: id }}
          error={error}
          value={cleanedValue}
          onChange={e => onLocalChange(e)}
          style={fullWidth ? InputInLineStyle.fullWidth : null}
          multiple={multiple ? multiple : false}
        >
          {itemsNative}
        </NativeSelect>
      );

      if (!isNullOrEmpty(label)) {
        return (
          <FormControl style={fullWidth ? InputInLineStyle.fullWidth : null}>
            <InputLabel shrink id={"Label_" + id} htmlFor={id} className={classes.labelRoot}>
              <Trans>{label}</Trans>
            </InputLabel>
            {nativeSelect}
          </FormControl>
        );
      } else {
        return nativeSelect;
      }
    } else {
      var itemsSelect = "";
      var renderValue;

      let buildItems = (items, buildSingleItems) => {
        if (!!useGroups) {
          return items.map((group, idx) => {
            return [<ListSubheader key={idx}>{group.label}</ListSubheader>, ...buildSingleItems(group.items)];
          });
        } else return buildSingleItems(items);
      };

      if (!isArrayNullOrEmpty(items)) {
        if (multiple) {
          let buildSingleItems = items => {
            return items.map((item, idx) => {
              var itemValue = item[displayedProperties.keyProperty];
              return (
                <MenuItem key={idx} value={itemValue}>
                  <Checkbox checked={value.some(v => v === itemValue)} />
                  <ListItemText primary={localTransformValue(item)} />
                </MenuItem>
              );
            });
          };
          itemsSelect = buildItems(items, buildSingleItems);
          renderValue = selected => selected.map(s => localTransformValue(items.find(i => i[displayedProperties.keyProperty] === s))).join(", ");
        } else {
          let buildSingleItems = items =>
            items.map((item, idx) => {
              return (
                <MenuItem key={idx} value={item[displayedProperties.keyProperty]}>
                  {localTransformValue(item)}
                </MenuItem>
              );
            });
          itemsSelect = buildItems(items, buildSingleItems);
        }
      }

      var select = (
        <Select
          inputProps={{ id: "Container_" + id }}
          SelectDisplayProps={{ id: "Clickable_" + id }}
          error={error}
          value={cleanedValue}
          onChange={e => onLocalChange(e)}
          input={<Input id={id} />}
          multiple={multiple}
          renderValue={renderValue}
          MenuProps={{ ...MenuProps, id: "Menu_" + id }}
          style={fullWidth ? InputInLineStyle.fullWidth : null}
        >
          {itemsSelect}
        </Select>
      );

      if (!isNullOrEmpty(label)) {
        return (
          <FormControl style={fullWidth ? InputInLineStyle.fullWidth : null}>
            <InputLabel id={"Label_" + id} htmlFor={id} className={classes.labelRoot}>
              <Trans>{label}</Trans>
            </InputLabel>
            {select}
          </FormControl>
        );
      } else {
        return select;
      }
    }
  } else {
    if (isNullOrEmpty(value))
      value = (
        <em>
          <Trans>Empty</Trans>
        </em>
      );
    else if (!isNullOrEmpty(items)) value = localTransformValue(items.find(item => value == item[displayedProperties.keyProperty]));
    //else
    //    value = value;

    if (!isNullOrEmpty(label)) {
      return (
        <FormControl>
          <InputLabel shrink>
            <Trans>{label}</Trans>
          </InputLabel>
          <Muted>{value}</Muted>
        </FormControl>
      );
    } else {
      return <Muted>{value}</Muted>;
    }
  }
}

export default withStyles(thirdPartySearchCriteriaStyle)(Selector);
