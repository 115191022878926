import { Trans } from "@lingui/macro";
import { Button, Card, CardActions, CircularProgress, Snackbar, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import CardBody from "components/Card/CardBody";
import CardError from "components/Card/CardError";
import FieldText from "components/Common/FieldText";
import KeyValueEditor from "components/Common/KeyValueEditor";
import { extractErrorMessage } from "module/tools";
import React, { useEffect, useState } from "react";
import { LoadMasterValues, TpLegalIdentifier } from "store/MasterValue/MasterValueTypes";
import { isNullOrEmpty } from "tools";
import { GroupCrupdate } from "../../actions/Group.Actions";
import GroupSelect from "../../GroupSelect";

const GroupDetail = ({ group, setGroup, reloadGroups, defaultLang, isGroupEditable }) => {
  const [isLoading, setIsLoading] = useState("init");
  const [apiError, setApiError] = useState(null);
  const [errorCodes, setErrorCodes] = useState([]);
  const [backup, setBackup] = useState(null);
  const [unknownError, setUnknownError] = useState(null);
  const [groupId, setGroupId] = useState(null);

  useEffect(() => {
    if (isLoading === "init") {
      setIsLoading("true");
      LoadMasterValues([TpLegalIdentifier]).then(() => setIsLoading(null));
    }
  }, []);

  useEffect(() => {
    setUnknownError(extractErrorMessage(errorCodes, "Unknown", "FR"));
  }, [errorCodes]);

  useEffect(() => {
    if (group && (!backup || groupId != group.id)) {
      const { hasChanged, ...g } = group;
      setBackup(JSON.stringify(g));
      setErrorCodes([]);
      setGroupId(group.id);
    }
  }, [group]);

  if (apiError) return <CardError error={apiError} />;
  if (!group) return <></>;
  if (!!isLoading) {
    return <CircularProgress />;
  }

  const setGroupValue = g => {
    const { hasChanged, ...g1 } = g;
    setGroup({ ...g1, hasChanged: JSON.stringify(g1) !== backup });
  };

  const saveGroup = g => {
    GroupCrupdate(g)
      .then(() => reloadGroups())
      .catch(e => {
        if (e?.response?.status === 400) {
          setErrorCodes(e.response.data);
        } else {
          setApiError(e);
        }
      });
  };

  return (
    <Card>
      <CardBody>
        <div>
          { !!isGroupEditable ?
            <FieldText
            label={<Trans>Group_Name</Trans>}
            value={group.label}
            onChange={e => setGroupValue({ ...group, label: e.target.value })}
            fullWidth
            error={extractErrorMessage(errorCodes, "LABEL", defaultLang)}
            showErrorTooltip
            isEditable={true}
          />
          :
            <div>
              <label >{<Trans>Group_Name</Trans>}</label>
              <br />
              <label > {group.label}</label>
            </div>
            }
        </div>
        <div>
          <GroupSelect
            label={<Trans>Group_Parent</Trans>}
            groupId={group.parentId}
            setGroupId={groupId => setGroupValue({ ...group, parentId: groupId })}
            isEditable = {isGroupEditable}
            error={extractErrorMessage(errorCodes, "PARENT", defaultLang)}
          />
        </div>
        <div>
          <CountryAutocomplete
            label={<Trans>Country</Trans>}
            countryCode={group.countryCode}
            onChange={codeIso2 => setGroupValue({ ...group, countryCode: codeIso2, legalIdentifiers: [] })}
            isEditable = {isGroupEditable}
            isRequired
            error={extractErrorMessage(errorCodes, "COUNTRY", defaultLang)}
          />
        </div>
        <div>
          <KeyValueEditor
            cardLabel={<Trans>Legal identifiers</Trans>}
            keyMasterValue="TpLegalIdentifier"
            keyMasterValueFilter={mv => mv.countryCode === group.countryCode}
            keyCollectionKey="code"
            keyCollectionValue="label"
            keyPropertyName="legalIdentifierCode"
            valuePropertyName="value"
            items={group.legalIdentifiers}
            onValueChanged={items => setGroupValue({ ...group, legalIdentifiers: items })}
            isEditable = {isGroupEditable}
            maxlength={20}
          />
        </div>
      </CardBody>
      <CardActions>
        {!!group.hasChanged && (
          <>
            <Button color="primary" onClick={() => saveGroup(group)}>
              <Trans>Save</Trans>
            </Button>
            <Button onClick={() => setGroup(null)}>
              <Trans>Cancel</Trans>
            </Button>
            {!isNullOrEmpty(unknownError) && (
              <Alert onClose={() => setUnknownError(null)} severity="error">
                {unknownError}
              </Alert>
            )}
          </>
        )}
      </CardActions>
    </Card>
  );
};

export default GroupDetail;
