import { PublicClientApplication } from "@azure/msal-browser";
import { ApimGet, ApimMultipleGet } from "components/Common/ApimSender";
import { isNullOrEmpty } from "tools";
import { apiLoginRequest, msalConfig } from "services/authentication/MsalAuth";

export function FreeSearchAppInsightDatas(criteria, callbackFn) {
  var queryStringCriteria = ConvertCriteriaToFreeSearchQueryString(criteria);
  ApimGet("api/PublicSupervision/Query" + encodeURI(queryStringCriteria), callbackFn, () => callbackFn([]));
}

export function SearchAppInsightDatas(criteria, endSearchAppInsightsData) {
  var queryStringCriteria = ConvertCriteriaToQueryString(criteria);
  ApimGet("api/PublicSupervision/Query" + encodeURI(queryStringCriteria), endSearchAppInsightsData, () => endSearchAppInsightsData([]));
}

export function SearchAppInsightDatasForGlobal(objectType, sinceNbHours, endSearchAppInsightsData) {
  var nbHours = "24";
  if (!isNullOrEmpty(sinceNbHours)) nbHours = sinceNbHours;
  var kustoRequest = 'name == "MDM_Supervision" and  customDimensions["OperationType"] == "' + objectType + '"';
  var queryStringCriteria = "?Type=CUSTOMEVENT&Period=T" + nbHours + "&Filter=" + kustoRequest;
  const url = "api/PublicSupervision/Query" + encodeURI(queryStringCriteria);
  ApimGet(url, endSearchAppInsightsData, () => endSearchAppInsightsData([]));
}

export function SearchAppInsightDatasForDetail(parentId, endSearchAppInsightsData) {
  var kustoRequest = 'operation_ParentId == "' + parentId + '"';
  var queryStringCriteria = "?Period=P8D&Type=DEPENDENCY&Filter=" + kustoRequest;
  var queryStringCriteriaTrace = "?Period=P8D&Type=TRACE&Filter=" + kustoRequest;
  var urlActions = [
    {
      url: "api/PublicSupervision/Query" + encodeURI(queryStringCriteria),
      name: "pivot"
    },
    {
      url: "api/PublicSupervision/Query" + encodeURI(queryStringCriteriaTrace),
      name: "trace"
    }
  ];

  ApimMultipleGet(urlActions, res => {
    var pivots = res.find(item => item.name == "pivot").array;
    var traces = res.find(item => item.name == "trace").array;
    endSearchAppInsightsData({ pivots: pivots, traces: traces });
  });
}

export function CheckService(serviceName, endCheck) {
  var urlActions = [
    {
      url: "api/PublicSupervision/CheckSearch/" + serviceName,
      name: "Search"
    },
    {
      url: "api/PublicSupervision/CheckDetail/" + serviceName,
      name: "Detail"
    }
  ];

  ApimMultipleGet(
    urlActions,
    res => {
      var globalStatus = "OK";

      var searchResult = res.find(item => item.name == "Search");
      var detailResult = res.find(item => item.name == "Detail");

      if (searchResult.status === "KO" || detailResult.status === "KO") globalStatus = "KO";

      endCheck({ statusGolbal: globalStatus, statusSearch: searchResult.status, statusDetail: detailResult.status });
    },
    e => console.error(e),
    single => {
      if (single.status === 200) {
        return { name: single.name, status: "OK" };
      } else if (single.status === 204) {
        return { name: single.name, status: "KO" };
      }
    }
  );
}

export function SearchAppInsightDatasGroupForGlobal(servicesName, endCheck) {
  var urlActions = [];
  servicesName.forEach(item => {
    var nbHours = "24";
    var kustoRequest = 'name == "MDM_Supervision" and  customDimensions["OperationType"] == "' + item + '"';
    var queryStringCriteria = "?Type=CUSTOMEVENT&Period=T" + nbHours + "&Filter=" + kustoRequest;
    const url = "api/PublicSupervision/Query" + encodeURI(queryStringCriteria);
    urlActions.push({
      url: url,
      name: item
    });
  });

  ApimMultipleGet(
    urlActions,
    res => {
      var globalStatus = "OK";
      var result = {};

      res.forEach(item => {
        result[item.name] = item.status;
        if (item.status !== "OK") globalStatus = "KO";
      });

      result["statusGolbal"] = globalStatus;

      endCheck(result);
    },
    e => console.error(e),
    single => {
      if (single.status === 200) {
        return { name: single.name, status: "OK" };
      } else if (single.status === 204) {
        return { name: single.name, status: "KO" };
      }
    }
  );
}

// convertir criterias
function ConvertCriteriaToQueryString(criteria) {
  var queryString = "?Type=REQUEST";

  var timespan = "";
  var requestKusto = "";

  if (!isNullOrEmpty(criteria.day)) {
    var startDate = new Date(criteria.day.getFullYear(), criteria.day.getMonth(), criteria.day.getDate());
    var startdd = String(startDate.getDate()).padStart(2, "0");
    var startmm = String(startDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    var startyyyy = startDate.getFullYear();

    var endDate = null;
    if (!isNullOrEmpty(criteria.time)) {
      var starttime = criteria.time.padStart(2, "0");
      var endtime = (criteria.time + 1).padStart(2, "0");

      timespan =
        startyyyy + "-" + startmm + "-" + startdd + "T" + starttime + ":00%2F" + startyyyy + "-" + startmm + "-" + startdd + "T" + endtime + ":00";
    } else {
      endDate = startDate;
      endDate.setDate(endDate.getDate() + 1);
      var enddd = String(endDate.getDate()).padStart(2, "0");
      var endmm = String(endDate.getMonth() + 1).padStart(2, "0"); //January is 0!
      var endyyyy = endDate.getFullYear();

      timespan = startyyyy + "-" + startmm + "-" + startdd + "%2F" + endyyyy + "-" + endmm + "-" + enddd;
    }

    queryString += "&Period=" + timespan;
  }

  if (!isNullOrEmpty(criteria.type)) {
    requestKusto = "name contains('" + criteria.type + "')";

    if (criteria.direction !== null && criteria.direction !== "") requestKusto += " and name contains('" + criteria.direction + "')";

    if (criteria.application !== null && criteria.application !== "") requestKusto += " and name contains('" + criteria.application + "')";

    queryString += "&Filter=" + requestKusto;
  }

  return queryString;
}

// convertir criterias
function ConvertCriteriaToFreeSearchQueryString(criteria) {
  var queryString = "?Type=REQUEST";
  if (!isNullOrEmpty(criteria.type)) {
    queryString = "?Type=" + criteria.type.value;
  }

  var startDate = new Date(criteria.startDate);
  var endDate = new Date(criteria.endDate);
  startDate.setHours(startDate.getUTCHours());
  endDate.setHours(endDate.getUTCHours());

  var periodPart = "&Period=" + ConvertDateToLocalFormat(startDate) + "%2F" + ConvertDateToLocalFormat(endDate);
  queryString += periodPart;

  var requestKusto = "";
  var andKeyWord = "";
  if (!isNullOrEmpty(criteria.filter)) {
    var filterParts = criteria.filter.split(" ");
    filterParts.map(filterPart => {
      requestKusto += andKeyWord + "name contains('" + filterPart + "')";
      andKeyWord = " and ";
    });
  }

  if (!isNullOrEmpty(criteria.success) && !isNullOrEmpty(criteria.success.value)) {
    requestKusto = requestKusto + andKeyWord + "success == " + criteria.success.value;
    andKeyWord = " and ";
  }

  queryString += "&Filter=" + requestKusto;

  return queryString;
}

function ConvertDateToLocalFormat(valueToConvert) {
  let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(valueToConvert);
  let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(valueToConvert);
  let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(valueToConvert);
  let hour = new Intl.DateTimeFormat("en", { hour: "2-digit", hour12: false }).format(valueToConvert);
  let minute = new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(valueToConvert);

  var iso = year + "-" + month + "-" + day + "T" + hour + ":" + minute;

  return iso;
}
