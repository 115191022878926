import { Trans } from "@lingui/macro";
import FormLabel from "@material-ui/core/FormLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import { TpCustomerNature, TpScope } from "store/MasterValue/MasterValueTypes";
import RecapThirdParty from "../commonThirdPartyWorkflow/RecapThirdParty";

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

function RecapCustomerCreation({ customerSj, setCustomerSj }) {
  return (
    <div className="divContainer">
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <RecapThirdParty thirdParty={customerSj?.customer?.thirdParty} />
        </GridItem>

        <GridItem xs={6} sm={6} md={6}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                style={{
                  ...CardInlineStyle.card
                }}
              >
                <CardHeader
                  style={{
                    ...CardInlineStyle.cardHeader
                  }}
                  icon
                >
                  <h4>
                    <Trans>Customer summary</Trans>
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Nature</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete
                        isEditable={true}
                        mvType={TpCustomerNature}
                        selectedValue={customerSj.customer.customerNatureCode}
                        onValueChange={v => setCustomerSj({ ...customerSj, customer: { ...customerSj.customer, customerNatureCode: v } })}
                        size="small"
                        prefixCode
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Scope</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete
                        isEditable={true}
                        mvType={TpScope}
                        selectedValue={customerSj.customer.scopeCode}
                        onValueChange={v => setCustomerSj({ ...customerSj, customer: { ...customerSj.customer, scopeCode: v } })}
                        size="small"
                        prefixCode
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                style={{
                  ...CardInlineStyle.card
                }}
              >
                <CardHeader
                  style={{
                    ...CardInlineStyle.cardHeader
                  }}
                  icon
                >
                  <h4>
                    <Trans>Link Customer/Sj summary</Trans>
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Sj</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <TextField margin="dense" id="longName" type="text" fullWidth readonly value={customerSj.sjCode} />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Id Iris 2</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <TextField margin="dense" id="longNameColas" type="text" fullWidth value={customerSj.aN8Iris2} />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Id Iris 3</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <TextField margin="dense" id="longNameColas" type="text" fullWidth value={customerSj.aN8Iris3} />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Cookie</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <TextField margin="dense" id="longNameColas" type="text" fullWidth value={customerSj.cookie} />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(thirdPartySearchCriteriaStyle)(RecapCustomerCreation);
