import { Trans } from "@lingui/macro";
import { FormControlLabel, FormLabel, Switch } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Skeleton } from "@material-ui/lab";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import CityZipAutocomplete from "components/Autocomplete/CityZipAutocomplete";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardError from "components/Card/CardError";
import CardHeader from "components/Card/CardHeader";
import { ApimGet } from "components/Common/ApimSender";
import FieldText from "components/Common/FieldText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import GpsAutocomplete from "components/Autocomplete/GpsAutocomplete";
import React, { useEffect, useState } from "react";
import { displayWarning, emptyIfNull } from "../../../../tools";
import { isNullOrUndefined } from "@microsoft/applicationinsights-core-js";

const TabThirdPartyAddress = function({ thirdParty, setThirdParty, isEditable, defaultLang }) {
  const [error, setError] = useState(null);
  const [address, setAddress] = useState(null);
  const [gpsCoordinates, setGpsCoordinates] = useState(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  var isManual = !thirdParty.isFromInfolegal && !thirdParty.isFromAltares;
  var isFieldEditable = isEditable && (isManual || thirdParty.isAddressOverriden);

  useEffect(() => {
    if (!isManual) {
      ApimGet(
        "api/TpColumbus/ThirdParties/" + thirdParty.identifier + "/externalAddress",
        item => (item ? setAddress(item) : setError({ message: "No external address found, please refresh ThirdParty from external repositories." })),
        error => setError(error)
      );
    }
    if (isFirstLoad) {
      setGpsCoordinates(thirdParty.gpsCoordinates);
      setIsFirstLoad(false);
    }
    if (isNullOrUndefined(thirdParty.gpsCoordinates)) {
      thirdParty.gpsCoordinates = { latitude: null, longitude: null };
    }
  }, [thirdParty]);

  var chkOverriden =
    !isManual && isEditable ? (
      <FormControlLabel
        control={
          <Switch
            checked={thirdParty.isAddressOverriden}
            onChange={() => {
              let isOverriden = !thirdParty.isAddressOverriden;
              let tp = { ...thirdParty, isAddressOverriden: isOverriden };
              if (!isOverriden && address) {
                tp = {
                  ...tp,
                  address_Line1: address.line1,
                  address_Line2: address.line2,
                  address_ZipCode: address.zipCode,
                  address_City: address.city,
                  address_StateCode: address.stateCode,
                  address_CountyCode: address.countyCode,
                  address_CountryCode: address.countryCode
                };
              }
              setThirdParty(tp);
            }}
            name="checkedA"
          />
        }
        label={<Trans>ThirdParty_Address_Override</Trans>}
      />
    ) : (
      <></>
    );

  var cardExternal = !isManual && thirdParty.isAddressOverriden ? <CardAddress address={address} error={error} defaultLang={defaultLang} thirdParty={thirdParty} /> : <></>;

  return (
    <GridContainer>
      <GridItem xs={7} sm={7} md={7}>
        <Card
          style={{
            ...CardInlineStyle.card
          }}
        >
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <h4>
              <Trans>Address</Trans>
            </h4>
          </CardHeader>
          <CardBody>
            {chkOverriden}
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Line 1</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                {displayWarning(thirdParty.warnings, "Address/Line1")}
                <FieldText
                  isEditable={isFieldEditable}
                  id="line1"
                  value={emptyIfNull(thirdParty.address_Line1)}
                  onChange={e => {
                    var value = e.target.value;
                    if (value.length > 40) value = value.substring(0, 40);
                    setThirdParty({ ...thirdParty, address_Line1: value });
                  }}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Line 2</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                {displayWarning(thirdParty.warnings, "Address/Line2")}
                <FieldText
                  isEditable={isFieldEditable}
                  id="line2"
                  value={emptyIfNull(thirdParty.address_Line2)}
                  onChange={e => {
                    var value = e.target.value;
                    if (value.length > 40) value = value.substring(0, 40);
                    setThirdParty({ ...thirdParty, address_Line2: value });
                  }}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>City</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <CityZipAutocomplete
                  countryCode={thirdParty ? thirdParty.address_CountryCode : ""}
                  zipCode={thirdParty ? thirdParty.address_ZipCode : ""}
                  city={thirdParty ? thirdParty.address_City : ""}
                  countyCode={thirdParty ? thirdParty.address_CountyCode : ""}
                  stateCode={thirdParty ? thirdParty.address_StateCode : ""}
                  onZipCityChange={(zipCode, city) => {
                    setThirdParty({ ...thirdParty, address_ZipCode: zipCode, address_City: city });
                  }}
                  onCountyStateChange={countyState => {
                    setThirdParty({ ...thirdParty, address_StateCode: countyState.stateCode, address_CountyCode: countyState.countyCode });
                  }}
                  isEditable={isFieldEditable}
                  defaultLang={defaultLang}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Country</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <CountryAutocomplete
                  countryCode={thirdParty.address_CountryCode}
                  onChange={codeIso2 => {
                    if (thirdParty.address_CountryCode !== codeIso2 && (thirdParty.address_CountryCode === "FR" || codeIso2 === "FR"))
                      setThirdParty({ ...thirdParty, address_ZipCode: null, address_City: null, address_CountryCode: codeIso2 });
                    else setThirdParty({ ...thirdParty, address_CountryCode: codeIso2 });
                  }}
                  defaultLang={defaultLang}
                  isEditable={isFieldEditable}
                  isRequired
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>LatitudeLongitude</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <GpsAutocomplete
                  isEditable={isFieldEditable}
                  defaultLang={defaultLang}
                  source={thirdParty}
                  setSource={c => {
                    setThirdParty(c);
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={5} sm={5} md={5}>
        {cardExternal}
      </GridItem>
    </GridContainer>
  );
};

const CardAddress = ({ address, error, defaultLang, gpsCoordinates }) => {
  if (error != null) {
    return <CardError error={error} />;
  }

  return (
    <Card>
      <CardHeader>
        <Trans>Address_From_ExternalRepositories</Trans>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <Trans>Line 1</Trans>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            {address ? address.line1 : <Skeleton />}
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <Trans>Line 2</Trans>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            {address ? address.line2 : <Skeleton />}
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <Trans>City</Trans>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            {address ? (
              <>
                {address.zipCode} {address.city}
              </>
            ) : (
              <Skeleton />
            )}
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <Trans>Country</Trans>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            {address ? <CountryAutocomplete countryCode={address.countryCode} defaultLang={defaultLang} isEditable={false} /> : <Skeleton />}
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>LatitudeLongitude</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
          {gpsCoordinates ? <><Trans>Latitude</Trans>  :  {gpsCoordinates.latitude} ;<Trans>Longitude</Trans> : {gpsCoordinates.longitude}  </> : <Skeleton />} 
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(TabThirdPartyAddress);
