import { Card, CardContent, CircularProgress, Typography } from "@material-ui/core";
import { CloudUpload, Settings } from "@material-ui/icons";
import CardError from "components/Card/CardError";
import Button from "components/CustomButtons/Button";
import CustomTable from "components/Table/CustomTable";
import { StructureCrupdate, StructureGet } from "module/structure/actions/StructureActions";
import React, { useMemo, useRef, useState } from "react";
import { isNullOrEmpty } from "tools";

const LocationMigrate = ({}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [structures, setStructures] = useState(null);
  const uploadFileRef = useRef();

  const PreserveAttributes = (s, changedStructure) => {
    if (s.structureTypeCode == "UE") {
      var ue = s.ue;
      var changedUe = changedStructure.ue;
      s = { ...s, ue: { ...ue, ...changedUe } };
      return s;
    }
    if (s.structureTypeCode == "UP") {
      var up = s.up;
      var changedUp = changedStructure.up;
      s = { ...s, up: { ...up, ...changedUp } };
      return s;
    } else return { ...s, ...changedStructure };
  };

  if (!isNullOrEmpty(structures)) {
    var structureRun = structures.find(l => l.migrateStatus === "ready");
    if (structureRun) {
      StructureGet(
        structureRun.structureTypeCode,
        structureRun.identifiant,
        s => {
          s = PreserveAttributes(s, structureRun);
          StructureCrupdate(
            s,
            () => {
              let nextId = false;
              setStructures(
                structures.map(st => {
                  if (st.identifiant === structureRun.identifiant) {
                    nextId = true;
                    return { ...st, migrateStatus: "done" };
                  }
                  if (nextId) {
                    nextId = false;
                    return { ...st, migrateStatus: "ready" };
                  }
                  return st;
                })
              );
            },
            e => {
              setStructures(
                structures.map(str => {
                  if (str.identifiant === structureRun.identifiant) return { ...str, migrateStatus: "error", migrateError: e.message };
                  return str;
                })
              );
            }
          );
        },
        e => {
          setStructures(
            structures.map(s => {
              if (s.identifiant === structureRun.identifiant) return { ...s, migrateStatus: <CardError error={e} /> };
              return s;
            })
          );
        }
      );
      setStructures(
        structures.map(s => {
          if (s.identifiant === structureRun.identifiant) return { ...s, migrateStatus: "inProgress" };
          return s;
        })
      );
    } else if (!structures.some(s => s.migrateStatus === "inProgress") && !!isLoading) {
      setIsLoading(false);
    }
  }

  const extractFile = e => {
    e.preventDefault();
    setIsLoading(true);
    var file = e.target.files[0];
    if (!file.name.toLowerCase().endsWith(".csv")) return;
    e.target.files[0].text().then(txt => {
      var lines = txt.split("\r\n");
      var structuresToMigrate = [];
      // on récupére l'index de l'identifiant dans le header
      var header = lines[0].split(";");
      var identifierIndex = header.indexOf("Identifiant", 0);
      // on saute la 1ère ligne (headers)
      for (var i = 1; i < lines.length; i++) {
        var line = lines[i];
        if (isNullOrEmpty(line)) continue;

        var columns = line.split(";");

        var structureIdentifier = columns[identifierIndex];
        if (structureIdentifier.includes("DIR") || structureIdentifier.includes("COLAS")) {
          var struct = {
            identifiant: columns[identifierIndex],
            structureTypeCode: "DIR",
            libelle: columns[1]
          };
          structuresToMigrate.push(struct);
        } else if (structureIdentifier.includes("ERT")) {
          var struct = {
            identifiant: columns[identifierIndex],
            structureTypeCode: "ERT",
            libelle: columns[7],
            ert: {
              libelleCourt: columns[6],
              Address_CountyCode: columns[8],
              codification: columns[9],
              siteInformatique: columns[10]
            }
          };
          structuresToMigrate.push(struct);
        } else if (structureIdentifier.includes("SJ")) {
          var struct = {
            identifiant: columns[identifierIndex],
            structureTypeCode: "SJ",
            libelle: columns[6],
            sj: {
              raisonSociale: columns[7],
              raisonSocialeComplet: columns[8],
              address_Line1: columns[9],
              address_Line2: columns[10],
              address_ZipCode: columns[11],
              // address_CountyCode: columns[14],
              // address_CountryCode: columns[13],
              address_City: columns[12],
              // address_StateCode: columns[15],
              gpsCoordinates: { longitude: parseFloat(columns[16].replace(",", ".")), latitude: parseFloat(columns[17].replace(",", ".")) },
              codeJdeSepSarl: columns[18],
              formeJuridique: columns[19],
              codeMig: columns[20],
              codeActivite: columns[21],
              identifiantLegal: columns[22],
              taxCode: columns[23],
              codeHra: columns[24],
              codeIris2: columns[25],
              codeIris3: columns[26],
              siteInformatique: columns[27],
              isSarlSep: columns[28],
              isGestionAdministrative: columns[29],
              IsSiegeFiliale: columns[30],
              isGestionTechnique: columns[33],
              isGestionBudgetInvest: columns[36],
              isSuccursale: columns[37],
              capitalSocial: columns[38],
              partenaires: columns[39],
              rCS: columns[40]
            }
          };
          structuresToMigrate.push(struct);
        } else if (structureIdentifier.includes("UE")) {
          var struct = {
            identifiant: columns[identifierIndex],
            structureTypeCode: "UE",
            libelle: columns[10],
            ue: {
              libelleCourt: columns[9],
              address_Line1: columns[11],
              address_Line2: columns[12],
              address_City: columns[14],
              address_ZipCode: columns[13],
              address_CountryCode: isNullOrEmpty(columns[15]) ? null : columns[15],
              address_StateCode: isNullOrEmpty(columns[16]) ? null : columns[16],
              address_CountyCode: isNullOrEmpty(columns[17]) ? null : columns[17],
              gpsCoordinates: { longitude: parseFloat(columns[18].replace(",", ".")), latitude: parseFloat(columns[19].replace(",", ".")) },
              secteur: columns[21],
              codeHra: columns[22],
              codeIris3: columns[23],
              activitePricipaleCode: columns[24],
              isBilan: columns[33],
              codeCol: columns[36]
            }
          };
          structuresToMigrate.push(struct);
        } else if (structureIdentifier.includes("UP")) {
          var struct = {
            identifiant: columns[identifierIndex],
            structureTypeCode: "UP",
            libelle: columns[9],
            up: {
              address_Line1: columns[10],
              address_Line2: columns[11],
              address_ZipCode: columns[12],
              address_City: columns[13],
              address_CountryCode: columns[14],
              address_StateCode: columns[16],
              address_CountyCode: columns[15],
              gpsCoordinates: { longitude: parseFloat(columns[17].replace(",", ".")), latitude: parseFloat(columns[18].replace(",", ".")) },
              idReporting: columns[19],
              typeInstallationCode: columns[20],
              ActiviteLevel2Code: columns[21],
              isMobile: columns[22],
              codeCol: columns[23]
              //siret
            }
          };
          structuresToMigrate.push(struct);
        } else {
          if (header[8] === "Identifiant" && header[25] === "UeJadeIdentifiant") {
            var struct = {
              identifiant: columns[identifierIndex],
              structureTypeCode: "EP",
              libelle: columns[9],
              ep: {
                address_Line1: columns[10],
                address_Line2: columns[11],
                address_ZipCode: columns[12],
                address_City: columns[13],
                address_CountryCode: columns[14],
                address_CountyCode: columns[15],
                address_StateCode: columns[16],
                gpsCoordinates: { longitude: parseFloat(columns[17].replace(",", ".")), latitude: parseFloat(columns[18].replace(",", ".")) },
                codeNaf: columns[19],
                //legalIdentifier
                //numTel
                //Email
                //NumFawx
                isSiegeSociete: columns[24]
              }
            };
            structuresToMigrate.push(struct);
          }
        }
      }
      setStructures(structuresToMigrate);
      setIsLoading(false);
    });
  };

  const migrate = () => {
    setIsLoading(true);
    setStructures(
      structures.map((l, idx) => {
        if (idx === 0) return { ...l, migrateStatus: "ready" };
        return l;
      })
    );
  };

  return (
    <>
      <Card>
        <CardContent>
          {!!isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Typography>
                Pour importer en masse les modifications de structures, utiliser le fichier CSV exporté par le moteur de recherche de structures.
              </Typography>
              <div>
                <Button size="small" color="info" onClick={() => uploadFileRef.current.click()}>
                  <CloudUpload /> Upload file
                </Button>
                <input type="file" accept="csv" onChange={e => extractFile(e)} ref={uploadFileRef} style={{ display: "none" }} />
                {isNullOrEmpty(structures) ? (
                  <></>
                ) : (
                  <Button size="small" color="info" onClick={() => migrate()}>
                    <Settings /> Apply migration
                  </Button>
                )}
              </div>
            </>
          )}
        </CardContent>
      </Card>
      <StructureMigrateTable structures={structures} />
    </>
  );
};

const StructureMigrateTable = ({ structures }) => {
  const columns = useMemo(() => [
    { Header: "Statut", accessor: "migrateStatus" },
    // { Header: "Message", accessor: "migrateError" },
    {
      Header: "Identifier",
      accessor: "identifiant"
    },
    { Header: "Libelle", accessor: "libelle" },
    { Header: "Type", accessor: "structureTypeCode" }
  ]);

  if (isNullOrEmpty(structures)) return <></>;

  return (
    <Card>
      <CardContent></CardContent>
      <CustomTable columns={columns} data={structures} />
    </Card>
  );
};

export default LocationMigrate;
