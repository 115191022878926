import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-6';
import { connect, useDispatch } from 'react-redux';
import * as Actions from 'module/amc/store/actions/CustomerContract.Actions';
import * as Tools from 'module/tools';
import { displayWarningFromErrorMessages, getErrorStateFromErrorMessages, getErrorMessage } from 'module/tools';

// custom components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CountryAutocomplete from 'components/Autocomplete/CountryAutocomplete';
import Select, { components } from 'react-select';
import { AntTabs, AntTab, TabPanel } from 'components/Common/Tabs';

// helpers
import LocalStorageHelper from "services/common/LocalStorageHelper";

// core components
import { Check, SearchOutlined, ViewDay, ViewHeadline, ViewQuilt } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Checkbox, FormControlLabel, Typography, FormLabel, TextField } from "@material-ui/core";

//lingui
import { Trans } from '@lingui/macro';

import { Warning, CheckCircleOutlined } from '@material-ui/icons';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import FieldText from 'components/Common/FieldText';

function CustomerContractCustomerDatasPart({ amc, masterValues, itemChanged, isReadonly }) {
    const dispatch = useDispatch();
    const [tabIdx, setTabIdx] = React.useState(0);

    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");

    var item = !!amc.currentItem['CUSTOMERCONTRACT'] ? amc.currentItem['CUSTOMERCONTRACT'] : {};
    var warnings = amc.errors['CUSTOMERCONTRACT'];

    return (
        <Card
            style={{
                ...CardInlineStyle.card
            }}
        >
            <CardHeader
                style={{
                    ...CardInlineStyle.cardHeader
                }}
                icon>
                <h4><Trans>Customer Informations</Trans></h4>
            </CardHeader>
            <CardBody>
                <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                        <FormLabel><Trans>Project Manager</Trans></FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                        <FieldText
                            isEditable={!isReadonly}
                            id="projectManager"
                            value={item.projectManager}
                            onChange={
                                (e) => itemChanged({ ...item, projectManager: e.target.value })
                            }
                        />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                        <FormLabel><Trans>Project Manager Reference</Trans></FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                        <FieldText
                            isEditable={!isReadonly}
                            id="projectManagerReference"
                            value={item.projectManagerReference}
                            onChange={
                                (e) => itemChanged({ ...item, projectManagerReference: e.target.value })
                            }
                        />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                        {displayWarningFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/CUSTOMERID")}
                        <FormLabel error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/CUSTOMERID")}><Trans>Customer</Trans></FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                        <FieldText
                            isEditable={!isReadonly}
                            id="customerId"
                            value={item.customerId}
                            showErrorTooltip={false}
                            error={getErrorMessage(warnings, "CUSTOMERCONTRACT/GENERAL/CUSTOMERID")}
                            onChange={
                                (e) => itemChanged({ ...item, customerId: e.target.value })
                            }
                        />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                        {displayWarningFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/FIRSTORDERNUMBER")}
                        <FormLabel error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/FIRSTORDERNUMBER")}><Trans>First Order Number</Trans></FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                        <FieldText
                            isEditable={!isReadonly}
                            id="firstOrderNumber"
                            value={item.firstOrderNumber}
                            showErrorTooltip={false}
                            error={getErrorMessage(warnings, "CUSTOMERCONTRACT/GENERAL/FIRSTORDERNUMBER")}
                            onChange={
                                (e) => itemChanged({ ...item, firstOrderNumber: e.target.value })
                            }
                        />
                    </GridItem>
                </GridContainer>
            </CardBody>
        </Card>
    );
}

const CardInlineStyle = {
    card: {
        marginTop: "10px",
    },
    cardHeader: {
        backgroundColor: "rgba(0, 172, 193, 0.6)",
        margin: "0",
        paddingLeft: "10px",
    }
};

const mapStateToProps = state => {
    return {
        amc: state.AMC_Reducer,
        masterValues: state.MasterValueReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        itemChanged: item => {
            dispatch(Actions.ItemChanged(item))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(amcSearchStyle)(CustomerContractCustomerDatasPart));