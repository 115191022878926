import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-6';
import { connect, useDispatch } from 'react-redux';
import * as Actions from 'module/amc/store/actions/Job.Actions';

import LocalStorageHelper from "services/common/LocalStorageHelper";

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';
import { Add, Assignment, Close, Description, Done, Lock, LockOpen, ViewDay, ViewHeadline } from '@material-ui/icons';

import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";


//lingui
import { Trans } from '@lingui/macro';

function JobTableForExternalUse({ jobs, get, classes }) {
    const dispatch = useDispatch();

    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");

    var [isFirstTime, setIsFirstTime] = useState(true);
    var result = jobs;

    //if (isFirstTime) {
    //    setIsFirstTime(false);
    //    search();
    //}

    const reactTableColumns = [
        {
            accessor: "actions",
            width: 50,
            sortable: false,
            filterable: false,
        },
        {
            Header: <Trans>Id</Trans>,
            accessor: "id"
        },
        {
            Header: <Trans>Id ERP</Trans>,
            accessor: "idErp"
        },
        {
            Header: <Trans>Id Local</Trans>,
            accessor: "applicationSourceIdentifier"
        },
        {
            Header: <Trans>Ue</Trans>,
            accessor: "ueIdentifier"
        },
        {
            Header: <Trans>Label</Trans>,
            accessor: "label"
        }
    ];

    var component = "";
    if (!!result) {
        var items = ConvertDatas(result, get);
        component = (
            <ReactTable
                data={items.tabItems}
                columns={reactTableColumns}
                showPaginationBottom={true}
                className="-striped -highlight"
                style={ReactTableStyle.main}
                pageSize={10}
            />
        );
    }


    return (
        <div>
            <Card>
                <CardBody>
                    {component}
                </CardBody>
            </Card>
        </div>
    );
}


///-----------------------------------------------------------
/// Convertit le résultat d'une recherche en item pour react-table
///-----------------------------------------------------------
function ConvertDatas(result, getItem) {
    if (result.items == null || result.items.length === 0)
        return [];

    var datas = {
        pages: result.nbOfPages,
        datas: result,
        tabItems: []
    };
    result.items.map((prop, key) => {
        datas.tabItems.push({
            ...prop,
            actions: (
                // we've added some custom button actions
                <div className="actions-right">
                    {/* use this button to add a like kind of action */}
                    <Button
                        justIcon
                        round
                        simple
                        onClick={() => {
                            if (!!getItem)
                                getItem(prop.id);
                        }}
                        color="info"
                        className="like"
                    >
                        <Assignment />
                    </Button>{" "}
                </div>
            )
        });
    });

    return datas;
}

const ReactTableStyle = {
    main: {
        height: "100%",
        overflow: "hidden"
    }
};

const mapStateToProps = state => {
    return {
        amc: state.AMC_Reducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        get: (id) => {
            dispatch(Actions.Get(id))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(amcSearchStyle)(JobTableForExternalUse));