import React, { useEffect, useState } from "react";
import ReactTable from "react-table-6";
import { connect, useDispatch } from "react-redux";
import * as Actions from "module/amc/store/actions/Project.Actions";
import * as Tools from "module/tools";
import en from "locale/en/messages";
import fr from "locale/fr/messages";

//lingui
import { setupI18n } from "@lingui/core";
import { t } from "@lingui/macro";

// custom components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import Select, { components } from "react-select";
import { AntTabs, AntTab, TabPanel } from "components/Common/Tabs";

// core components
import { Check, SearchOutlined, ViewDay, ViewHeadline, ViewQuilt } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Checkbox, FormControlLabel, Typography, FormLabel, TextField } from "@material-ui/core";

//lingui
import { Trans } from "@lingui/macro";

import { Warning, CheckCircleOutlined } from "@material-ui/icons";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import FieldText from "components/Common/FieldText";

function ProjectFlagsPart({ amc, masterValues, itemChanged, isReadonly, defaultLang }) {
  const dispatch = useDispatch();
  const [tabIdx, setTabIdx] = React.useState(0);

  const i18n = setupI18n({ catalogs: { fr: fr, en: en }, language: defaultLang });

  var item = !!amc.currentItem["PROJECT"] ? amc.currentItem["PROJECT"] : {};
  var warnings = amc.errors["PROJECT"];

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Flags</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={2} sm={2} md={2}>
            <FormControlLabel
              value={item.isBudgetaryControlMonitoredProject}
              control={
                <Checkbox
                  checked={item.isBudgetaryControlMonitoredProject}
                  disabled={isReadonly}
                  onChange={e => itemChanged({ ...item, isBudgetaryControlMonitoredProject: e.target.checked })}
                />
              }
              label={i18n._(t`Is Budgetary Control Monitored Project`)}
              labelPlacement="start"
            />
          </GridItem>
          <GridItem xs={2} sm={2} md={2}>
            <FormControlLabel
              value={item.isBudgetMonitoringByPhase}
              control={
                <Checkbox
                  checked={item.isBudgetMonitoringByPhase}
                  disabled={isReadonly}
                  onChange={e => itemChanged({ ...item, isBudgetMonitoringByPhase: e.target.checked })}
                />
              }
              label={i18n._(t`Is Budget Monitoring By Phase`)}
              labelPlacement="start"
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer,
    masterValues: state.MasterValueReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    itemChanged: item => {
      dispatch(Actions.ItemChanged(item));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(ProjectFlagsPart));
