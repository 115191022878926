import { Trans } from "@lingui/macro";
import { Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { PersonAddOutlined } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { isArrayNullOrEmpty, isNullOrEmpty } from "tools";
import uuidv4 from "uuid/v4"; // Generate GUID

const TabThirdPartyContact = function({ thirdParty, setThirdParty, isEditable, masterValues, defaultLang }) {
  var thirdPartyId = thirdParty.identifier;
  var contacts = thirdParty.contacts;
  var countryCode = thirdParty.countryCode;
  // var countryPhoneCode = "";
  // if (!isNullOrEmpty(countryCode)) {
  //   countryPhoneCode = countries.find(c => c.codeIso2 === countryCode).defaultPhoneCode;
  // }

  function addContact() {
    setThirdParty({
      ...thirdParty,
      contacts: [
        ...thirdParty.contacts,
        {
          id: uuidv4(),
          thirdPartyIdentifier: thirdPartyId,
          contactTypeCode: "GENERAL",
          lastName: "",
          firstName: "",
          details: [],
          action: "INSERT",
          isEdit: true
        }
      ]
    });
  }

  var cards;
  if (isArrayNullOrEmpty(contacts)) {
    cards = (
      <Typography>
        <Trans>NoAvailableContact</Trans>
      </Typography>
    );
  } else {
    cards = (
      <GridContainer>
        {contacts.map((ctx, idx) => {
          if (ctx.action === "DELETE") {
            return "";
          }
          return (
            <GridItem xs={6} sm={6} md={6}>
              {/* <ContactCard
                key={idx}
                contact={ctx.contact}
                contactTypes={contactTypes}
                isCreation={ctx.action === "INSERT"}
                isEditable={isEditable}
                isDeletable={true}
                onContactSave={c => dispatch(Actions.ThirdPartyContactUpdate(c))}
                onContactDelete={(c, isCreation) => dispatch(Actions.ThirdPartyContactRemove(c.id, isCreation))}
                countryPhoneCode={countryPhoneCode}
                defaultLang={defaultLang}
              /> */}
            </GridItem>
          );
        })}
      </GridContainer>
    );
  }

  var btnAddContact = "";
  if (isEditable)
    btnAddContact = (
      <Button onClick={() => addContact()}>
        <PersonAddOutlined />
        Nouveau contact
      </Button>
    );

  return (
    <div>
      {cards}
      {btnAddContact}
    </div>
  );
};

export default withStyles(thirdPartySearchCriteriaStyle)(TabThirdPartyContact);
