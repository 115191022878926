import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Card from 'components/Card/Card';
import { Trans } from "@lingui/macro";
import PartThirdParties from '../common/PartThirdParties';
import PartLocations from '../common/PartLocations';
import FieldText from 'components/Common/FieldText';
import GpsAutocomplete from "components/Autocomplete/GpsAutocomplete";
import CityZipAutocomplete from 'components/Autocomplete/CityZipAutocomplete';
import CountryAutocomplete from 'components/Autocomplete/CountryAutocomplete';
import { Domain, LocationOn } from '@material-ui/icons';
import { extractErrorMessage } from 'module/tools';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Muted from 'components/Typography/Muted';

const TabGeneral = ({ structure, setStructure, masterValues, defaultLang, isEditable, errors }) => {
    const ert = structure.ert;
    const setErt = v => setStructure({ ...structure, ert: v });

    return (
        <>
            <Card
                style={{
                    ...CardInlineStyle.card
                }}
            >
                <CardHeader
                    style={{
                        ...CardInlineStyle.cardHeader
                    }}
                    icon>
                    <h4><Trans>Structure_Part_General</Trans></h4>
                </CardHeader>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <GridContainer>
                                <GridItem xs={5} sm={5} md={5}><Muted><Trans>Line1</Trans></Muted></GridItem>
                                <GridItem xs={7} sm={7} md={7}>
                                    <FieldText
                                        isEditable={isEditable}
                                        value={ert.address_Line1}
                                        onChange={e => setErt({ ...ert, address_Line1: e.target.value })}
                                        error={extractErrorMessage(errors, "ERT_ADDR_LINE1", defaultLang)}
                                        showErrorTooltip
                                        size="small"
                                    />
                                </GridItem>
                                <GridItem xs={5} sm={5} md={5}><Muted><Trans>Line2</Trans></Muted></GridItem>
                                <GridItem xs={7} sm={7} md={7}>
                                    <FieldText
                                        isEditable={isEditable}
                                        value={ert.address_Line2}
                                        onChange={e => setErt({ ...ert, address_Line2: e.target.value })}
                                        error={extractErrorMessage(errors, "ERT_ADDR_LINE2", defaultLang)}
                                        showErrorTooltip
                                        size="small"
                                    />
                                </GridItem>
                                <GridItem xs={5} sm={5} md={5}><Muted><Trans>City</Trans></Muted></GridItem>
                                <GridItem xs={7} sm={7} md={7}>
                                    <CityZipAutocomplete
                                        countryCode={ert.address_CountryCode}
                                        zipCode={ert.address_ZipCode}
                                        city={ert.address_City}
                                        countyCode={ert.address_CountyCode}
                                        stateCode={ert.address_StateCode}
                                        onZipCityChange={(zipCode, city) => {
                                            setErt({ ...ert, address_ZipCode: zipCode, address_City: city })
                                        }}
                                        onCountyStateChange={(countyState) => {
                                            setErt({ ...ert, address_StateCode: countyState.stateCode, address_CountyCode: countyState.countyCode })
                                        }}
                                        isEditable={isEditable}
                                        size="small"
                                        defaultLang={defaultLang}
                                    />
                                </GridItem>
                                <GridItem xs={5} sm={5} md={5}>
                                    <Muted><Trans>Country</Trans></Muted>
                                </GridItem>
                                <GridItem xs={7} sm={7} md={7}>
                                    <CountryAutocomplete
                                        countryCode={ert.address_CountryCode}
                                        onChange={(codeIso2) => {
                                            if (ert.address_CountryCode !== codeIso2 && (ert.address_CountryCode === 'FR' || codeIso2 === 'FR'))
                                                setErt({ ...ert, address_ZipCode: null, address_City: null, address_CountryCode: codeIso2 });
                                            else
                                                setErt({ ...ert, address_CountryCode: codeIso2 });
                                        }}
                                        defaultLang={defaultLang}
                                        isEditable={isEditable}
                                        size="small"
                                    />
                                </GridItem>
                                <GridItem xs={5} sm={5} md={5}>
                                    <Muted>
                                        <Trans>LatitudeLongitude</Trans>
                                    </Muted>
                                </GridItem>
                                <GridItem xs={7} sm={7} md={7}>
                                    <GpsAutocomplete
                                        isEditable={isEditable}
                                        source={ert}
                                        setSource={c => {
                                            setErt(c);
                                        }}
                                        showMap
                                    />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        </>
    );
};

const CardInlineStyle = {
    card: {
        marginTop: "10px",
    },
    cardHeader: {
        backgroundColor: "rgba(0, 172, 193, 0.6)",
        margin: "0",
        paddingLeft: "10px",
    }
};

export default withStyles(structureStyle)(TabGeneral);