import axios from "axios";
import { ApimGet, ApimPost } from "components/Common/ApimSender";
import { HasOneOfRights } from "services/user/UserHelper";
import * as Actions from "./types";

export function GetAccount(thirdPartyId) {
  return (dispatch, getState) => {
    ApimGet(
      "api/TpCopernic/Accounts/" + thirdPartyId,
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_ACCOUNT_GET,
          account: responseJson,
          isEditable: HasOneOfRights(["thirdparty_supplier.edit", "thirdparty_customer.edit"]),
          isEditableClosed: HasOneOfRights(["thirdparty_supplier.edit_closed", "thirdparty_customer.edit_closed"])
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_ACCOUNT_ERROR,
          error: error
        });
      }
    );
  };
}

export function EditAccount(account) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_ACCOUNT_EDITVALUE,
      account: account
    });
  };
}

export function SaveAccount(account, reloadThirdParty) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.TP_COPERNIC_ACCOUNT_SAVE_BEGIN
    });

    ApimPost(
      "api/TpCopernic/Accounts",
      account,
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_ACCOUNT_SAVE_END,
          successType: reloadThirdParty ? "SUCCESS_RELOAD" : "SUCCESS",
          account: responseJson
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_ACCOUNT_ERROR,
          error: error
        });
      }
    );
  };
}
