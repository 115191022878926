import { Trans } from "@lingui/macro";
import { ApimGet, ApimPost } from "components/Common/ApimSender";
import React from "react";
import { isArrayNullOrEmpty, isNullOrEmpty } from "tools";
import * as Actions from "./types";

export function CleanSearchThirdParties() {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_SEARCH_CLEAN,
      searchScope: "thirdParties"
    });
  };
}

var thirdPartyTypeOptions = [
  { value: "isSupplier", label: <Trans>isSupplier</Trans> },
  { value: "isPointOfSale", label: <Trans>isPointOfSale</Trans> },
  { value: "isCustomer", label: <Trans>isCustomer</Trans> },
  { value: "isBeneficiary", label: <Trans>isBeneficiary</Trans> },
  { value: "isAccount", label: <Trans>isAccount</Trans> }
];

var thirdPartyCategoryOptions = [
  { value: "BOU", label: <Trans>Bouygues</Trans> },
  { value: "COL", label: <Trans>Colas</Trans> },
  { value: "GEN", label: <Trans>Generic</Trans> },
  { value: "PAR", label: <Trans>Individual</Trans> },
  { value: "PRI", label: <Trans>Private</Trans> },
  { value: "PRP", label: <Trans>Parapublic</Trans> },
  { value: "PUB", label: <Trans>Public</Trans> }
];

export function SetComplexCriterias(complexQuery, criterias) {
  return dispatch => {
    dispatch({
      type: Actions.TP_COPERNIC_SET_SEARCH_COMPLEX_REQUEST,
      complexCriterias: criterias,
      complexQuery: complexQuery
    });
  };
}

export function SetCriterias(criterias) {
  return dispatch => {
    dispatch({
      type: Actions.TP_COPERNIC_SET_SEARCH_CRITERIA,
      criterias: criterias
    });
  };
}

export function SetSearchMode(searchMode) {
  return dispatch => {
    dispatch({
      type: Actions.TP_COPERNIC_SET_SEARCH_MODE,
      searchMode: searchMode
    });
  };
}

export function GetDefaultThirdPartyTypeValues(criterias) {
  var tabs = [];
  if (criterias != null && criterias !== undefined) {
    for (var i = 0; i < thirdPartyTypeOptions.length; i++) {
      if (criterias[thirdPartyTypeOptions[i].value]) tabs.push(thirdPartyTypeOptions[i]);
    }
  }
  return tabs;
}

export function GetDefaultThirdPartyCategoryValues(criterias) {
  var temp = [];
  if (criterias != null && criterias !== undefined && !isNullOrEmpty(criterias.category)) {
    var split = criterias.category.split("|");
    for (var i = 0; i < thirdPartyCategoryOptions.length; i++) {
      if (
        split.find(item => {
          return item === thirdPartyCategoryOptions[i].value;
        })
      )
        temp.push(thirdPartyCategoryOptions[i]);
    }
  }
  return temp;
}

export function SearchThirdParties() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.TP_COPERNIC_SEARCH_BEGIN,
      searchScope: "thirdParties"
    });

    const criterias = getState().TP_Search.criterias;
    const searchMode = getState().TP_Search.searchMode;

    const queryString = searchMode === "Simple" ? ConvertCriteriaToSimpleQueryString(criterias) : ConvertCriteriaToQueryString(criterias);
    ApimGet(
      "api/TpCopernic/ThirdParties" + queryString,
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_SEARCH_END,
          results: responseJson,
          searchScope: "thirdParties"
        });
      },
      error => {
        dispatch({
          type: Actions.TP_COPERNIC_SEARCH_ERROR,
          error: error
        });
      }
    );
  };
}

export function ComplexSearchThirdParties() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.TP_COPERNIC_SEARCH_BEGIN,
      criterias: criterias,
      searchMode: searchMode,
      searchScope: "thirdParties"
    });

    const criterias = getState().TP_Search.complexCriterias;
    const simpleCriterias = getState().TP_Search.criterias;
    const searchMode = getState().TP_Search.searchMode;
    var pagingAndOrderProperties = { pageSize: 10 };

    if (!!simpleCriterias) {
      pagingAndOrderProperties = {
        orderProperty: !!simpleCriterias.orderProperty && typeof simpleCriterias.orderProperty !== "undefined" ? simpleCriterias.orderProperty : null,
        isDescendingOrder: typeof simpleCriterias.isDescendingOrder !== "undefined" ? simpleCriterias.isDescendingOrder : true,
        startPage: !!simpleCriterias.startPage && typeof simpleCriterias.startPage !== "undefined" ? simpleCriterias.startPage : 0,
        pageSize: simpleCriterias.pageSize
      };
    }

    var objectCriteria = {
      sqlRequest: criterias,
      pagingAndOrderProperties: pagingAndOrderProperties
    };

    ApimPost(
      "api/TpCopernic/ThirdParties/ComplexSearch",
      objectCriteria,
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_SEARCH_END,
          results: responseJson,
          searchScope: "thirdParties"
        });
      },
      error => {
        dispatch({
          type: Actions.TP_COPERNIC_SEARCH_ERROR,
          error: error
        });
      }
    );
  };
}

// Convert Criteria To Simple Query String
function ConvertCriteriaToSimpleQueryString(criteria) {
  var queryString = "";
  var queryParams = [];

  if (typeof criteria.criteriaFullText !== "undefined" && criteria.criteriaFullText !== null && criteria.criteriaFullText !== "") {
    queryParams.push("FreeSearch=" + criteria.criteriaFullText);
  }
  if (criteria.criteriaCountry !== null && criteria.criteriaCountry !== "") {
    queryParams.push("Country=" + criteria.criteriaCountry);
  }
  if (!isNullOrEmpty(criteria.startPage)) {
    queryParams.push("StartPage=" + criteria.startPage);
  }
  if (!isNullOrEmpty(criteria.pageSize)) {
    queryParams.push("PageSize=" + criteria.pageSize);
  }
  if (!isNullOrEmpty(criteria.orderProperty)) {
    queryParams.push("OrderProperty=" + criteria.orderProperty);
  }
  if (!isNullOrEmpty(criteria.isDescendingOrder)) {
    queryParams.push("IsDescendingOrder=" + criteria.isDescendingOrder);
  }

  if (!isArrayNullOrEmpty(queryParams)) queryString = "?" + queryParams.join("&");

  return queryString;
}

function ConvertCriteriaToQueryString(criteria) {
  var queryString = "";
  var queryParams = [];

  if (!isNullOrEmpty(criteria.criteriaMdmId)) {
    queryParams.push("MdmId=" + criteria.criteriaMdmId);
  }
  if (!isNullOrEmpty(criteria.criteriaMigCode)) {
    queryParams.push("MigCode=" + criteria.criteriaMigCode);
  }
  if (!isNullOrEmpty(criteria.criteriaLegalIdentifier)) {
    queryParams.push("LegalIdentifier=" + criteria.criteriaLegalIdentifier);
  }
  if (!isNullOrEmpty(criteria.criteriaAddress)) {
    queryParams.push("LineAddress=" + criteria.criteriaAddress);
  }
  if (!isNullOrEmpty(criteria.category)) {
    queryParams.push("Category=" + criteria.category);
  }
  if (!isNullOrEmpty(criteria.criteriaLongName)) {
    queryParams.push("LongName=" + criteria.criteriaLongName);
  }
  if (!isNullOrEmpty(criteria.criteriaCity)) {
    queryParams.push("City=" + criteria.criteriaCity);
  }
  if (!isNullOrEmpty(criteria.criteriaCountry)) {
    queryParams.push("Country=" + criteria.criteriaCountry);
  }
  if (criteria.criteriaTpCountry !== null && criteria.criteriaTpCountry !== "") {
    queryParams.push("TpCountry=" + criteria.criteriaTpCountry);
  }
  if (!isNullOrEmpty(criteria.localSearch)) {
    queryParams.push("LocalSearch=" + criteria.localSearch);
  }
  if (!isNullOrEmpty(criteria.isCustomer)) {
    queryParams.push("CustomerSearch=" + criteria.isCustomer);
  }
  if (!isNullOrEmpty(criteria.isSupplier)) {
    queryParams.push("SupplierSearch=" + criteria.isSupplier);
  }
  if (!isNullOrEmpty(criteria.isPointOfSale)) {
    queryParams.push("PointOfSaleSearch=" + criteria.isPointOfSale);
  }
  if (!isNullOrEmpty(criteria.isBeneficiary)) {
    queryParams.push("BeneficiarySearch=" + criteria.isBeneficiary);
  }
  if (!isNullOrEmpty(criteria.isAccount)) {
    queryParams.push("AccountSearch=" + criteria.isAccount);
  }
  if (!isNullOrEmpty(criteria.startPage)) {
    queryParams.push("StartPage=" + criteria.startPage);
  }
  if (!isNullOrEmpty(criteria.pageSize)) {
    queryParams.push("PageSize=" + criteria.pageSize);
  }
  if (!isNullOrEmpty(criteria.orderProperty)) {
    queryParams.push("OrderProperty=" + criteria.orderProperty);
  }
  if (!isNullOrEmpty(criteria.isDescendingOrder)) {
    queryParams.push("IsDescendingOrder=" + criteria.isDescendingOrder);
  }
  if (!isNullOrEmpty(criteria.beneficiarySupplierSearch)) {
    queryParams.push("BeneficiarySupplierSearch=" + criteria.beneficiarySupplierSearch);
  }
  if (!isArrayNullOrEmpty(queryParams)) queryString = "?" + queryParams.join("&");

  return queryString;
}
