export function MapPositions(countryCode) {
    if (!countryCode)
        countryCode = 'FR';
    
    countryCode = countryCode.toUpperCase();

    switch (countryCode) {
        case 'AD':
            return {
                position: [1.6011705, 42.5361235],
                zoom: 9.26
            };
        case 'AE':
            return {
                position: [54.1825168, 24.1587283],
                zoom: 6
            };
        case 'AT':
            return {
                position: [12.859114687618671, 47.64443591896139],
                zoom: 6
            };
        case 'AU':
            return {
                position: [136.3668471, -27.7926551],
                zoom: 3
            };
        case 'BA':
            return {
                position: [17.8583232, 43.8765995],
                zoom: 6.30
            };
        case 'BE':
            return {
                position: [4.4506803, 50.5093414],
                zoom: 6.5
            };
        case 'BF':
            return {
                position: [-1.7666292, 12.2488781],
                zoom: 5.75
            };
        case 'BJ':
            return {
                position: [2.2509365, 9.1702114],
                zoom: 5.75
            };
        case 'CA':
            return {
                position: [-102.4278314, 59.5804057],
                zoom: 2.5
            };
        case 'CG':
            return {
                position: [14.4940281, -0.8679301],
                zoom: 5.25
            };
        case 'CH':
            return {
                position: [8.3953406, 46.7749371],
                zoom: 6.4
            };
        case 'CI':
            return {
                position: [-5.7550799, 7.5197131],
                zoom: 5.75
            };
        case 'CL':
            return {
                position: [-72.9419311, -38.9412301],
                zoom: 2.75
            };
        case 'CN':
            return {
                position: [103.801376, 35.637593],
                zoom: 2.75
            };
        case 'CZ':
            return {
                position: [15.5872537, 49.7335109],
                zoom: 6.25
            };
        case 'DE':
            return {
                position: [9.89371297228763, 51.2867008520723],
                zoom: 4.793879866235255
            };
        case 'DJ':
            return {
                position: [42.54753, 11.7778241],
                zoom: 7.25
            };
        case 'DK':
            return {
                position: [10.2025026, 56.1297739],
                zoom: 5.8
            };
        case 'DZ':
            return {
                position: [3.151198, 28.4526437],
                zoom: 3.9
            };
        case 'EG':
            return {
                position: [30.3921367, 26.7102204],
                zoom: 4.5
            };
        case 'ES':
            return {
                position: [-3.8749073, 40.1216758],
                zoom: 5
            };
        case 'FI':
            return {
                position: [26.0233307, 65.2654234],
                zoom: 3.75
            };
        case 'FR':
            return {
                position: [2.53333, 46.48333],
                zoom: 4.5
            };
        case 'GA':
            return {
                position: [11.6518919, -0.775785],
                zoom: 5.75
            };
        case 'GB':
            return {
                position: [-4.232587774930266, 54.71598338057123],
                zoom: 4.313496063643258
            };
        case 'GN':
            return {
                position: [-10.9031215, 10.1634095],
                zoom: 5.8
            };
        case 'GP':
            return {
                position: [-61.4246289, 16.1437606],
                zoom: 8.75
            };
        case 'GQ':
            return {
                position: [10.5290814, 1.5223737],
                zoom: 7.85
            };
        case 'HR':
            return {
                position: [16.3961204, 44.4546137],
                zoom: 5.75
            };
        case 'HU':
            return {
                position: [19.3331041, 47.0981383],
                zoom: 6.25
            };
        case 'ID':
            return {
                position: [117.6717551, -3.8087789],
                zoom: 3.6
            };
        case 'IE':
            return {
                position: [-8.2409878, 53.3230285],
                zoom: 5.5
            };
        case 'IN':
            return {
                position: [80.1003248, 21.2456012],
                zoom: 3.4
            };
        case 'IS':
            return {
                position: [-18.5149244, 64.9291014],
                zoom: 5.25
            };
        case 'IT':
            return {
                position: [12.4001689, 41.9991568],
                zoom: 4.5
            };
        case 'KE':
            return {
                position: [38.2596087, 0.0841923],
                zoom: 5
            };
        case 'KH':
            return {
                position: [104.8892735, 12.2437827],
                zoom: 6
            };
        case 'KM':
            return {
                position: [43.7405, -11.9239923],
                zoom: 8
            };
        case 'KR':
            return {
                position: [127.8914078, 35.8593905],
                zoom: 5.5
            };
        case 'LI':
            return {
                position: [9.5467607, 47.1588661],
                zoom: 10
            };
        case 'LU':
            return {
                position: [6.0982489, 49.8026689],
                zoom: 8.15
            };
        case 'LY':
            return {
                position: [17.5541139, 26.3083504],
                zoom: 4.5
            };
        case 'MA':
            return {
                position: [-6.6916763, 31.4479332],
                zoom: 5
            };
        case 'MC':
            return {
                position: [7.4269324, 43.7370847],
                zoom: 13
            };
        case 'MG':
            return {
                position: [46.9799276, -19.0387601],
                zoom: 4.5
            };
        case 'MQ':
            return {
                position: [-60.9974696, 14.6229069],
                zoom: 9
            };
        case 'MU':
            return {
                position: [57.5600649, -20.2693799],
                zoom: 9
            };
        case 'MY':
            return {
                position: [108.7472153, 4.2540558],
                zoom: 4.75
            };
        case 'MZ':
            return {
                position: [35.3380695, -18.5880897],
                zoom: 4.25
            };
        case 'NA':
            return {
                position: [17.2681389, -23.1495059],
                zoom: 4.75
            };
        case 'NL':
            return {
                position: [5.3569994, 52.113256],
                zoom: 6.25
            };
        case 'NO':
            return {
                position: [16.6782748, 65.2990681],
                zoom: 3.5
            };
        case 'OM':
            return {
                position: [55.0786028, 21.5359527],
                zoom: 5
            };
        case 'PE':
            return {
                position: [-75.2155532, -9.7438764],
                zoom: 4
            };
        case 'PL':
            return {
                position: [19.0036958, 51.7885705],
                zoom: 5
            };
        case 'PT':
            return {
                position: [-19.2139541, 38.7892702],
                zoom: 4.5
            }
        case 'QA':
            return {
                position: [51.1857835, 25.2523868],
                zoom: 7.25
            };
        case 'RE':
            return {
                position: [55.520745, -21.1507286],
                zoom: 9
            };
        case 'RO':
            return {
                position: [25.0622414, 45.8970206],
                zoom: 5.5
            };
        case 'RU':
            return {
                position: [101.5815353, 65.4635002],
                zoom: 1.75
            };
        case 'RS':
            return {
                position: [20.6278317, 44.22732],
                zoom: 5.75
            };
        case 'SA':
            return {
                position: [45.2742868, 24.6155514],
                zoom: 4.25
            };
        case 'SE':
            return {
                position: [17.1481459, 62.9811467],
                zoom: 3.25
            };
        case 'SG':
            return {
                position: [103.8302722, 1.3233923],
                zoom: 9.75
            };
        case 'SI':
            return {
                position: [14.9782222, 46.1339632],
                zoom: 7
            };
        case 'SK':
            return {
                position: [19.4372814, 48.5748883],
                zoom: 6.5
            };
        case 'SN':
            return {
                position: [-14.6188182, 14.4157353],
                zoom: 6.25
            };
        case 'TG':
            return {
                position: [1.1398575, 8.5245609],
                zoom: 6
            };
        case 'TH':
            return {
                position: [100.6195278, 13.3104358],
                zoom: 4.5
            };
        case 'TN':
            return {
                position: [9.7386482, 33.7854133],
                zoom: 5
            };
        case 'TR':
            return {
                position: [35.27912, 38.8647139],
                zoom: 5
            };
        case 'UA':
            return {
                position: [30.9321707, 48.5342072],
                zoom: 4.75
            };
        case 'US':
            return {
                position: [-119.7065217, 54.3780892],
                zoom: 2
            };
        case 'VE':
            return {
                position: [-67.5033176, 6.7157086],
                zoom: 4.5
            };
        case 'VN':
            return {
                position: [107.4541484, 16.0901796],
                zoom: 4.5
            };
        case 'YT':
            return {
                position: [45.1506105, -12.8392309],
                zoom: 9.75
            };
        case 'ZA':
            return {
                position: [22.2565, -28.7357865],
                zoom: 4.4
            };
        case 'ZM':
            return {
                position: [27.5308611, -13.5716361],
                zoom: 5
            };
    }

    return {
        position: [2.53333, 46.48333],
        zoom: 4.5
    };
};