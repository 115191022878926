import React, { useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import { Add, ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { Trans } from "@lingui/macro";
import StructureAutocomplete from "components/Autocomplete/StructureAutocomplete";
import { StructureNew } from "../actions/StructureActions";
import * as StructureActions from "module/structure/store/actions";
import AlertError from "../../../components/Card/AlertError";
import { HasRight } from "services/user/UserHelper";
import ItemAutoComplete from "components/Autocomplete/ItemAutoComplete";

const BtnCreateStructure = ({ defaultLang, availableErts, availableSjs }) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const initialState = { type: null, typeLabel: null, parentId: null, parentType: null, error: null, errorParent: null };
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();

  const menuList = useMemo(() => {
    if (HasRight("structure.edit"))
      return [
        { type: "DIR", label: <Trans>Structure_Tree_NewDIR</Trans> },
        { type: "ERT", label: <Trans>Structure_Tree_NewERT</Trans> },
        { type: "SJ", label: <Trans>Structure_Tree_NewSJ</Trans> },
        { type: "UE", label: <Trans>Structure_Tree_NewUE</Trans> },
        { type: "UP", label: <Trans>Structure_Tree_NewUP</Trans> },
        { type: "EP", label: <Trans>Structure_Tree_NewEP</Trans> },
        { type: "AREA", label: <Trans>Structure_Tree_NewAREA</Trans> },
        { type: "DIV", label: <Trans>Structure_Tree_NewDIV</Trans> },
        { type: "COMP", label: <Trans>Structure_Tree_NewCOMP</Trans> }
      ];

    if (HasRight("structure.edit_ue")) return [{ type: "UE", label: <Trans>Structure_Tree_NewUE</Trans> }];

    return [];
  }, []);

  const availableStructures = useMemo(() => [...availableErts, ...availableSjs], [availableErts, availableSjs]);

  const createStructure = () => {
    if (!state.parentId || !state.parentType) {
      setState({ ...state, error: null, errorParent: <Trans>Field_Required</Trans> });
      return;
    }

    setState({ ...state, isLoading: true, error: null });

    StructureNew(
      state.parentType,
      state.parentId,
      state.type,
      s => {
        dispatch(StructureActions.OpenStructure(null, null, s));
        setState(initialState);
      },
      e => setState({ ...state, isLoading: false, error: e })
    );
  };

  let error = <></>;
  if (state.error) {
    error = <AlertError error={state.error} />;
  }

  let ddlStructures = <></>;
  if (HasRight("structure.edit"))
    ddlStructures = (
      <StructureAutocomplete
        isEditable
        structureTypes={["DIR", "ERT", "SJ", "UE", "UP", "EP", "AREA", "DIV", "COMP"]}
        value={state.parentId}
        onChange={v => setState({ ...state, errorParent: null, parentId: v ? v.identifiant : null, parentType: v ? v.structureType : null })}
        defaultLang={defaultLang}
        size="small"
        label={<Trans>Structure_Create_SetParent</Trans>}
        error={state.errorParent}
        showErrorTooltip
      />
    );

  if (HasRight("structure.edit_ue"))
    ddlStructures = (
      <ItemAutoComplete
        isEditable
        items={availableStructures.map(struct => {
          return { ...struct, code: struct.identifier };
        })}
        selectedValue={state.parentId}
        onValueChange={structCode => {
          var struct = availableStructures.find(s => s.identifier == structCode);
          console.log(struct);
          setState({ ...state, errorParent: null, parentId: struct ? struct.identifier : null, parentType: struct ? struct.structureType : null });
        }}
        formatText={ue => `${ue.identifier} - ${ue.oldIdentifier} - ${ue.label}`}
        maxResultNumber={100}
        label={<Trans>Structure_Create_SetParent</Trans>}
        error={state.errorParent}
        showErrorTooltip
        size="small"
      />
    );

  return (
    <>
      <Button variant="contained" size="sm" onClick={e => setMenuAnchor(e.currentTarget)} color="info">
        <Add />
        <Trans>Structure_Create</Trans>
        {Boolean(menuAnchor) ? <ArrowDropUp /> : <ArrowDropDown />}
      </Button>
      <Menu
        id="menuCreate"
        anchorEl={menuAnchor}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        PaperProps={{
          style: {
            width: "40ch"
          }
        }}
      >
        {menuList.map(st => {
          return (
            <MenuItem
              key={`create_${st.type}`}
              onClick={() => {
                setState({ ...state, type: st.type, typeLabel: st.label });
                setMenuAnchor(null);
              }}
            >
              {st.label}
            </MenuItem>
          );
        })}
      </Menu>
      <Dialog onClose={() => setState(initialState)} open={Boolean(state && state.type)} maxWidth="xs" fullWidth>
        <DialogTitle>{state.typeLabel}</DialogTitle>
        <DialogContent>
          {error}
          {ddlStructures}
        </DialogContent>
        <DialogActions>
          {state.isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Button onClick={() => setState(initialState)}>
                <Trans>Cancel</Trans>
              </Button>
              <Button onClick={createStructure} color="info">
                <Trans>Create</Trans>
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = state => {
  return {
    availableErts: state.AuthenticationReducer.user?.structuresStruct?.erts,
    availableSjs: state.AuthenticationReducer.user?.structuresStruct?.sjs
  };
};

export default connect(mapStateToProps)(BtnCreateStructure);
