import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { HasRight } from "services/user/UserHelper";
import { CommonCountry } from "store/MasterValue/MasterValueTypes";
import { isArrayNullOrEmpty, isNull } from "../../../tools";
import PaymentCharacteristicLegalDatas from "./PaymentCharacteristicLegalDatas";

const DialogPaymentCharacteristic = function({ supplier, paymentCharacteristic, setPaymentCharacteristic, userInfo, masterValues, errors, classes }) {
  const [state, setState] = useState({});

  useEffect(() => {
    if (!paymentCharacteristic) return;

    let isAdmin = userInfo.isAdmin;
    let isIbanEditable = false;
    let isDateEditable = false;

    if (userInfo.canAdd || userInfo.canEdit) {
      let supplierErtIds = [...new Set(supplier.supplierSjs.map(supplierSj => supplierSj.sj?.ertIdentifier))];
      let allowedErts =
        !isNull(userInfo.structures) &&
        userInfo.structures.erts.some(ert => supplierErtIds.some(sErtId => ert.identifier === sErtId)) &&
        masterValues[CommonCountry].some(
          c =>
            c.code === supplier.thirdParty.address_CountryCode &&
            !isArrayNullOrEmpty(c.allowedPayCharTypes) &&
            c.allowedPayCharTypes.some(p => p === paymentCharacteristic.paymentCharacteristicTypeCode) &&
            !!c.allowPayCharManualEdit
        );
      let needApprobationScore50 = allowedErts && paymentCharacteristic.sisScore === 50 && userInfo.needApprobation;
      let needApprobationAnyScore = allowedErts && userInfo.needApprobation;

      if (allowedErts && !(userInfo.isAdmin || needApprobationScore50 || paymentCharacteristic.sisScore <= 70)) {
        isIbanEditable = false;
      } else if (userInfo.isAdmin || needApprobationScore50 || allowedErts || paymentCharacteristic.sisScore <= 70) {
        isIbanEditable = true;
      }
      isDateEditable = userInfo.isAdmin || needApprobationAnyScore || allowedErts;

      setState({ isAdmin, isIbanEditable, isDateEditable });
    }
  }, [userInfo, paymentCharacteristic]);

  const erts = userInfo.structures.erts.filter(ert => supplier.supplierSjs.some(ssj => ssj.sj.ertIdentifier === ert.identifier));

  if (!paymentCharacteristic) return <></>;

  return (
    <>
      <PaymentCharacteristicLegalDatas
        payChar={paymentCharacteristic}
        supplier={supplier}
        errors={errors}
        isIbanEditable={state.isIbanEditable}
        isDateEditable={state.isDateEditable}
        savePaymentCharacteristic={setPaymentCharacteristic}
        classes={classes}
        erts={erts}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer,
    userInfo: {
      isAdmin: HasRight("application.admin"),
      canAdd: HasRight("thirdparty_supplier.add_iban"),
      canEdit: HasRight("thirdparty_supplier.edit_iban"),
      structures: state.AuthenticationReducer.user.structureTps
    }
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(DialogPaymentCharacteristic));
