import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as Actions from '../../store/actions';
import { isNull, isArrayNullOrEmpty } from '../../../tools';

import ReactTable from "react-table-6";
import Button from "../../../../components/CustomButtons/Button";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import { CircularProgress } from '@material-ui/core';
import { Assignment } from '@material-ui/icons';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "../../../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

//lingui
import { Trans } from '@lingui/macro';

const TabBeneficiarySupplier = function ({ beneficiaryCtx, openThirdParty }) {
    var dispatch = useDispatch();
    var [isFirstTime, setIsFirstTime] = useState(true);
    var beneficiary = beneficiaryCtx.beneficiary;
    var suppliers = beneficiaryCtx.suppliers;

    useEffect(() => {
        if (isFirstTime) {
            dispatch(Actions.GetSuppliersByBeneficiaryId(beneficiary.thirdPartyIdentifier));
            setIsFirstTime(false);
        }
    });

    ///------------------------------
    /// Crée le contenu (les lignes) de la table-react affichant les Suppliers associés
    ///------------------------------
    function convertDataToReactTable(suppliers) {
        if (isNull(suppliers))
            return [];

        return suppliers.map((prop, key) => {
            var supplier = prop;
            return {
                identifier: supplier.thirdPartyIdentifier,
                label: supplier.label,
                legalIdentifier: supplier.thirdParty.legalIdentifier,
                zipcode: supplier.thirdParty.address_ZipCode,
                city: supplier.thirdParty.address_City,
                actions: (
                    // we've added some custom button actions
                    <div className="actions-right">
                        {/* use this button to add a like kind of action */}
                        <Button
                            justIcon
                            round
                            simple
                            onClick={() => {
                                openThirdParty(supplier.thirdPartyIdentifier);
                            }}
                            color="info"
                            className="like"
                        >
                            <Assignment />
                        </Button>
                    </div>
                )
            };
        });
    }

    if (isFirstTime)
        return (<center><CircularProgress /></center>);

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card
                    style={{
                        ...CardInlineStyle.card
                    }}
                >
                    <CardHeader
                        style={{
                            ...CardInlineStyle.cardHeader
                        }}
                        icon>
                        <h4><Trans>Suppliers</Trans></h4>
                    </CardHeader>
                    <CardBody>
                        <ReactTable
                            data={convertDataToReactTable(suppliers)}
                            columns={[
                                {
                                    accessor: "actions",
                                    width: 50,
                                    sortable: false,
                                    filterable: false,
                                },
                                {
                                    Header: <Trans>Identifier</Trans>,
                                    accessor: "identifier",
                                    filterable: true
                                },
                                {
                                    Header: <Trans>Label</Trans>,
                                    accessor: "label",
                                    filterable: true
                                },
                                {
                                    Header: <Trans>Legal Identifier</Trans>,
                                    accessor: "legalIdentifier",
                                    filterable: true
                                },
                                {
                                    Header: <Trans>Zip code</Trans>,
                                    accessor: "zipcode",
                                    filterable: true
                                },
                                {
                                    Header: <Trans>City</Trans>,
                                    accessor: "city",
                                    filterable: true
                                }
                            ]}
                            defaultPageSize={5}
                            showPaginationBottom={true}
                            showPageSizeOptions={false}
                            className="-striped -highlight"
                            showFilters={true}
                            style={ReactTableStyle.main}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

const CardInlineStyle = {
    card: {
        marginTop: "10px",
    },
    cardHeader: {
        backgroundColor: "rgba(0, 172, 193, 0.6)",
        margin: "0",
        paddingLeft: "10px",
    }
};
const ReactTableStyle = {
    main: {
        height: "100%",
        overflow: "hidden"
    }
};

const mapStateToProps = state => {
    return {
        beneficiaryCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].beneficiaryCtx : null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        openThirdParty: thirdPartyId => {
            dispatch(Actions.OpenThirdParty(thirdPartyId))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(thirdPartySearchCriteriaStyle)(TabBeneficiarySupplier));