import { Trans } from "@lingui/macro";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Add } from "@material-ui/icons";
import React, { useState } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import { CommonCountry, TpCoupaActivity } from "store/MasterValue/MasterValueTypes";
import { isArrayNullOrEmpty, isNull, isNullOrEmpty } from "tools";
import thirdPartySearchCriteriaStyle from "../../../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import { ApimGet } from "../../../../components/Common/ApimSender";
import GetMasterValueLabel from "../../../../components/Common/MasterValueLabel";
import Button from "../../../../components/CustomButtons/Button";
import * as Actions from "../../store/actions";

const DialogCoupaContainGroup = function({
  openDialog,
  setOpenDialog,
  onContainGroupChanged,
  selectedContainGroups,
  coupaActivities,
  countries,
  erts,
  ues,
  defaultLang
}) {
  const [coupaContainGroups, setCoupaContainGroups] = useState(null);

  function convertDataToReactTable() {
    if (isArrayNullOrEmpty(coupaContainGroups)) return [];

    return coupaContainGroups
      .filter(cg => !isNullOrEmpty(cg.codeFiliale))
      .map(prop => {
        var icon = "";
        var action = "";
        var selectedContainGroup = selectedContainGroups.find(c => c.code === prop.code);
        if (!isNull(selectedContainGroup)) {
          action = selectedContainGroup.action;
          if (action === "INSERT") {
            icon = (
              <Button aria-label="Cancel" onClick={() => onContainGroupChanged(selectedContainGroups.filter(cg => cg.code !== prop.code))}>
                <Trans>Cancel</Trans>
              </Button>
            );
          }
        } else {
          icon = (
            <IconButton aria-label="Add" onClick={() => onContainGroupChanged([...selectedContainGroups, { ...prop, action: "INSERT" }])}>
              <Add />
            </IconButton>
          );
        }

        var codePays = countries.find(country => prop.codePays === country.codeIso2);
        if (isNull(codePays)) {
          codePays = prop.codePays;
        } else {
          codePays = codePays.codeIso2 + " - " + GetMasterValueLabel(codePays, defaultLang);
        }

        var codeFiliale = prop.codeFiliale;
        codeFiliale = erts.find(ert => codeFiliale === ert.oldIdentifier);
        if (isNull(codeFiliale)) {
          return null;
        }
        codeFiliale = codeFiliale.oldIdentifier + " - " + codeFiliale.label;

        var codeRegion = prop.codeRegion;
        if (!isNullOrEmpty(codeRegion)) {
          codeRegion = ues.find(ue => codeRegion === ue.oldIdentifier);
          if (isNull(codeRegion)) {
            return null;
          }

          codeRegion = codeRegion.oldIdentifier + " - " + codeRegion.label;
        }

        var codeActivite = prop.codeActivite;
        if (!isNullOrEmpty(codeActivite)) {
          codeActivite = coupaActivities.find(act => prop.codeActivite === act.code);
          if (isNull(codeActivite)) {
            return null;
          }

          codeActivite = codeActivite.code + " - " + GetMasterValueLabel(codeActivite, defaultLang);
        }

        return {
          code: prop.code,
          codePays: codePays,
          codeFiliale: codeFiliale,
          codeRegion: codeRegion,
          codeActivite: codeActivite,
          action: action,
          icons: <div>{icon}</div>
        };
      })
      .filter(g => !isNull(g));
  }

  function colorizeRow(state, rowInfo, instance) {
    if (rowInfo) {
      if (rowInfo.original.action === "INSERT")
        return {
          style: {
            background: "#6cefff"
          }
        };
      else if (rowInfo.original.action === "EXIST")
        return {
          style: {
            background: "#00acc1",
            color: "white"
          }
        };
    }

    return {};
  }

  var filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return !isNull(row[id]) ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
  };

  let dialogContent = <></>;
  if (openDialog) {
    if (!coupaContainGroups) {
      ApimGet(
        "api/coupaContainGroup",
        c => setCoupaContainGroups(c),
        e => {
          setCoupaContainGroups([]);
          console.error(e);
        }
      );
      dialogContent = <CircularProgress />;
    } else {
      dialogContent = (
        <ReactTable
          data={convertDataToReactTable()}
          columns={[
            {
              Header: <Trans>Country</Trans>,
              accessor: "codePays",
              sortable: true,
              filterable: true
            },
            {
              Header: <Trans>Filiale</Trans>,
              accessor: "codeFiliale",
              sortable: true,
              filterable: true
            },
            {
              Header: <Trans>Region</Trans>,
              accessor: "codeRegion",
              sortable: true,
              filterable: true
            },
            {
              Header: <Trans>Activity</Trans>,
              accessor: "codeActivite",
              sortable: true,
              filterable: true
            },
            {
              Header: <Trans>Action</Trans>,
              accessor: "icons",
              filterable: false,
              sortable: false
            }
          ]}
          defaultPageSize={20}
          showPaginationBottom={true}
          showPageSizeOptions={false}
          className="-striped -highlight"
          showFilters={true}
          defaultFilterMethod={filterMethod}
          style={ReactTableStyle.main}
          getTrProps={colorizeRow}
        />
      );
    }
  }

  return (
    <Dialog fullWidth={true} maxWidth="md" open={openDialog} scroll="paper">
      <DialogTitle>
        <Trans>CoupaContainGroup_Select</Trans>
      </DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)} color="info">
          <Trans> Close </Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    coupaActivities: state.MasterValueReducer[TpCoupaActivity],
    countries: state.MasterValueReducer[CommonCountry],
    erts: state.AuthenticationReducer.user.structureTps.erts,
    ues: state.AuthenticationReducer.user.structureTps.ues
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSjValueChanged: sj => {
      dispatch(Actions.BeneficiarySjUpdate(sj));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(DialogCoupaContainGroup));
