import React from "react";
import axios from "axios";
import uuidv4 from "uuid/v4"; // Generate GUID
import * as Actions from "./types";
import { isArrayNullOrEmpty, isNullOrEmpty } from "../../../tools";
import { Trans } from "@lingui/macro";
import LocalStorageHelper from "../../../../services/common/LocalStorageHelper";

import { RootStore } from "store/RootStore";
import { ApimGet, ApimPost } from "components/Common/ApimSender";

export function SetCriterias(criterias) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_SET_CRITERIA,
      module: "WORKER",
      criterias: criterias
    });
  };
}

export function AddItem() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_GET_END,
      module: "WORKER",
      item: { isReadOnly: false }
    });
  };
}

export function SelectItem(item) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_GET_END,
      module: "WORKER",
      item: item
    });
  };
}

export function SetSearchMode(searchMode) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_SET_SEARCHMODE,
      module: "WORKER",
      searchMode: searchMode
    });
  };
}

export function ItemChanged(item) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_ITEM_CHANGED,
      module: "WORKER",
      item: item
    });
  };
}

export function Crupdate() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_UPDATE_BEGIN,
      module: "JOBEMPLOYEE"
    });

    const item = getState().AMC_Reducer.currentItem["WORKER"];

    ApimPost(
      "api/JobEmployees",
      item,
      responseJson => {
        dispatch({
          type: Actions.AMC_END_EDITING,
          module: "WORKER"
        });
        dispatch(Search());
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.AMC_ERROR,
          module: "WORKER",
          error: error.response.data
        });
      }
    );
  };
}

export function Get(login) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_GET_BEGIN,
      module: "WORKER"
    });

    ApimGet(
      "api/JobEmployees/" + login,
      responseJson => {
        dispatch({
          type: Actions.AMC_GET_END,
          module: "WORKER",
          item: responseJson
        });
      },
      error => {
        dispatch({
          type: Actions.AMC_SEARCH_ERROR,
          error: error
        });
      }
    );
  };
}

export function Search() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_SEARCH_BEGIN,
      module: "WORKER"
    });

    const criterias = getState().AMC_Reducer.criterias["WORKER"];

    const queryString = ConvertCriteriaToQueryString(criterias);
    ApimGet(
      "api/JobEmployees/Search" + queryString,
      responseJson => {
        dispatch({
          type: Actions.AMC_SEARCH_END,
          module: "WORKER",
          results: responseJson
        });
      },
      error => {
        dispatch({
          type: Actions.AMC_SEARCH_ERROR,
          error: error
        });
      }
    );
  };
}

function ConvertCriteriaToQueryString(criteria) {
  var queryString = "";
  var queryParams = [];

  if (!isNullOrEmpty(criteria.login)) {
    queryParams.push("criterias.login=" + criteria.login);
  }
  if (!isNullOrEmpty(criteria.firstName)) {
    queryParams.push("criterias.firstName=" + criteria.firstName);
  }
  if (!isNullOrEmpty(criteria.lastName)) {
    queryParams.push("criterias.lastName=" + criteria.lastName);
  }

  if (!isNullOrEmpty(criteria.orderProperty)) {
    queryParams.push("pagingAndOrder.orderProperty=" + criteria.orderProperty);
  }
  if (!isNullOrEmpty(criteria.isDescendingOrder)) {
    queryParams.push("pagingAndOrder.isDescendingOrder=" + criteria.isDescendingOrder);
  }
  if (!isNullOrEmpty(criteria.startPage)) {
    queryParams.push("pagingAndOrder.startPage=" + criteria.startPage);
  }
  if (!isNullOrEmpty(criteria.pageSize)) {
    queryParams.push("pagingAndOrder.pageSize=" + criteria.pageSize);
  }
  if (!isArrayNullOrEmpty(queryParams)) queryString = "?" + queryParams.join("&");

  return queryString;
}
