import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as Actions from 'module/admin/store/actions/Migration.Actions';
import * as Types from 'module/admin/store/actions/types';

import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import MigrationStructureSelection from './MigrationStructureSelection';
import MigrationResult from './MigrationResult';
import { CircularProgress } from '@material-ui/core';
import Button from "components/CustomButtons/Button.jsx";


import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/admin/adminStyle.jsx";

//lingui
import { Trans } from '@lingui/macro';

function BuCleaner({ admin, launchCleaning, cleanResult, classes }) {

    const [cleaningParameters, setCleaningParameters] = React.useState(null);

    var working = admin.working;
    var migrationReport = admin.result;
    var error = admin.error;

    var componentResult = "";
    if (working) {
        componentResult = <CircularProgress />;
    }
    else if (!!migrationReport && typeof (migrationReport) !== "undefined") {
        componentResult = <MigrationResult migrationReport={migrationReport} />;
    }

    var launchButton = "";
    if (!working) {
        launchButton = (
            <Button
                type="submit"
                color="info"
                onClick={() => { launchCleaning(cleaningParameters); }}>
                <Trans>Launch Cleaning</Trans>
            </Button>
        );
    }

    function changeParameters(parameters) {
        cleanResult();
        setCleaningParameters(parameters);
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <MigrationStructureSelection onChange={changeParameters} withUe={true} />
                <div className={classes.flexRight}>
                    {launchButton}
                </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                {componentResult}
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = state => {
    return {
        admin: state.Admin_Reducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        launchCleaning: migrationParameters => {
            dispatch(Actions.CleanBus(migrationParameters))
        },
        cleanResult: () => {
            dispatch({
                type: Types.ADMIN_CLEANRESULT
            });
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(amcSearchStyle)(BuCleaner));