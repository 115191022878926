import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";

import { Trans } from "@lingui/macro";
import Header from "./Header";
import TabGeneral from "./TabGeneral";
import TabRelation from "../common/TabRelation";
import { Tabs, Tab } from "@material-ui/core";
import { TabPanel } from "components/Common/Tabs";
import TabTree from "../common/TabTree";
import TabActivityLevel from "../common/TabActivityLevel";
import IconTab from "../common/IconTab";
import TabHistory from "../common/TabHistory";
import TabJournal from "../common/TabJournal";
import { isNullOrEmpty } from "tools";

const MainUp = ({ structure, setStructure, masterValues, defaultLang, errors, isEditable, isTagEditable }) => {
  const [tabIdx, setTabIdx] = useState(0);
  const up = structure.up;
  const setUp = v => setStructure({ ...structure, up: v });

  var tabStatus = buildTabs(isNullOrEmpty(structure.identifiant));
  var tabs = tabStatus.map(tab => {
    var tabButton = <span>{tab.title}</span>;
    var tabContent = "";

    switch (tab.component) {
      case "General":
        let generalErrorTypes = [
          "SJ_RAISONSOCIALE",
          "SJ_RAISONSOCIALECOMPLET",
          "SJ_LEGALID",
          "SJ_TAXCODE",
          "SJ_ACTIVITE",
          "SJ_FORM_JUR",
          "SJ_RCS",
          "SJ_ADDR_LINE1",
          "SJ_ADDR_LINE2"
        ];
        tabButton = (
          <span>
            <IconTab errors={errors} errorTypes={generalErrorTypes} /> {tab.title}
          </span>
        );
        tabContent = (
          <TabGeneral
            structure={structure}
            setStructure={setStructure}
            masterValues={masterValues}
            defaultLang={defaultLang}
            isEditable={isEditable}
            errors={errors}
          />
        );
        break;
      case "Activities":
        tabButton = (
          <span>
            <IconTab errors={errors} errorTypes={[]} /> {tab.title}
          </span>
        );
        tabContent = (
          <TabActivityLevel
            isFromJade={up.areActivityLevelsFromJade}
            activities={up.activityLevels}
            setActivities={v => setUp({ ...up, activityLevels: v })}
            masterValues={masterValues}
            defaultLang={defaultLang}
            isEditable={isEditable}
            errors={errors}
          />
        );
        break;
      case "Tree":
        tabButton = (
          <span>
            <IconTab errors={errors} errorTypes={[]} /> {tab.title}
          </span>
        );
        tabContent = (
          <TabTree structure={structure} setStructure={setStructure} masterValues={masterValues} defaultLang={defaultLang} isEditable={isEditable} />
        );
        break;
      case "Relation":
        tabButton = (
          <span>
            <IconTab errors={errors} errorTypes={["RELATION"]} /> {tab.title}
          </span>
        );
        tabContent = (
          <TabRelation structure={structure} setStructure={setStructure} defaultLang={defaultLang} isEditable={isEditable} errors={errors} />
        );
        break;
      case "Journal":
        tabButton = (
          <span>
            <IconTab errors={errors} errorTypes={["JOURNAL"]} /> {tab.title}
          </span>
        );
        tabContent = (
          <TabJournal structure={structure} setStructure={setStructure} defaultLang={defaultLang} isEditable={isEditable} errors={errors} masterValues={masterValues}/>
        );
        break;
      case "History":
        tabButton = (
          <span>
            <IconTab errors={errors} errorTypes={[]} /> {tab.title}
          </span>
        );
        tabContent = <TabHistory structure={structure} defaultLang={defaultLang} />;
        break;
      default:
        break;
    }

    return {
      tabButton: tabButton,
      tabContent: tabContent,
      tabName: tab.component
    };
  });

  return (
    <>
      <Header
        structure={structure}
        setStructure={setStructure}
        isEditable={isEditable}
        masterValues={masterValues}
        defaultLang={defaultLang}
        errors={errors}
        isTagEditable={isTagEditable}
      />
      <Tabs variant="scrollable" value={tabIdx} onChange={(e, value) => setTabIdx(value)} aria-label="Tabs supplier">
        {tabs.map((t, idx) => (
          <Tab key={"ueTab" + idx} label={t.tabButton} />
        ))}
      </Tabs>
      {tabs.map((t, idx) => (
        <TabPanel key={"ueTabPanel" + idx} value={tabIdx} index={idx} id={"ueTab" + idx}>
          {t.tabContent}
        </TabPanel>
      ))}
    </>
  );
};

function buildTabs(isCreation) {
  var tabs = [
    {
      title: <Trans>Structure_Tab_General</Trans>,
      component: "General",
      status: "neutral"
    },
    {
      title: <Trans>Structure_Tab_Activities</Trans>,
      component: "Activities",
      status: "neutral"
    },
    {
      title: <Trans>Structure_Tab_Tree</Trans>,
      component: "Tree",
      status: "neutral"
    },
    {
      title: <Trans>Structure_Tab_Relation</Trans>,
      component: "Relation",
      status: "neutral"
    },
    {
      title: <Trans>Structure_Tab_Journal</Trans>,
      component: "Journal",
      status: "neutral"
    }
  ];
  if (!isCreation) {
    tabs = [
      ...tabs,
      {
        title: <Trans>Structure_Tab_History</Trans>,
        component: "History",
        status: "neutral"
      }
    ];
  }
  return tabs;
}

export default withStyles(structureStyle)(MainUp);
