import { ApimGet, ApimGetAsync, ApimGetPromise, ApimPost } from "components/Common/ApimSender";
import { HasOneOfRights } from "services/user/UserHelper";
import { HasRight } from "services/user/UserHelper";
import * as Actions from "./types";

export function OpenPaymentCharacteristic(paymentCharacteristicId) {
  return async dispatch => {
    await ApimGetPromise("api/TpCopernic/PaymentCharacteristics/" + paymentCharacteristicId)
      .then(
        async pc =>
          await ApimGetPromise("api/TpCopernic/Suppliers/" + pc.supplierId)
            .then(s =>
              dispatch({
                type: Actions.TP_COPERNIC_PAYMENT_CHAR_OPEN,
                paymentCharacteristic: pc,
                supplier: s,
                isApplicationAdmin: HasRight("application.admin"),
                isEditable: HasRight("thirdparty_supplier.edit_iban")
              })
            )
            .catch(error => {
              console.error(error);
              dispatch({
                type: Actions.TP_COPERNIC_PAYMENT_CHAR_ERROR,
                error: error
              });
            })
      )
      .catch(error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_PAYMENT_CHAR_ERROR,
          error: error
        });
      });
  };
}

export function CheckSisPaymentCharacteristics(supplierId, paymentCharacteristicId, language) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.TP_COPERNIC_PAYMENT_CHAR_SIS_BEGIN
    });

    ApimGet(
      "api/TpCopernic/Suppliers/" + supplierId + "/PaymentCharacteristics/" + paymentCharacteristicId + "/checkSis/" + language,
      responseJson => {
        const authentifSelector = getState().AuthenticationReducer;
        dispatch({
          type: Actions.TP_COPERNIC_PAYMENT_CHAR_SIS_END,
          paymentCharacteristic: responseJson,
          paymentCharacteristicId,
          isEditable: HasOneOfRights(["thirdparty_supplier.edit", "thirdparty_customer.edit"])
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_PAYMENT_CHAR_ERROR,
          error: error
        });
      }
    );
  };
}

export function EditPaymentCharacteristic(paymentCharacteristic) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_PAYMENT_CHAR_EDITVALUE,
      paymentCharacteristic: paymentCharacteristic
    });
  };
}

export function ClosePaymentCharacteristic() {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_PAYMENT_CHAR_CLOSE
    });
  };
}

export function SavePaymentCharacteristic(payChar) {
  return dispatch => {
    dispatch({
      type: Actions.TP_COPERNIC_PAYMENT_CHAR_SAVE_BEGIN
    });

    ApimPost(
      "api/TpCopernic/Suppliers/" + payChar.supplierIdentifier + "/paymentCharacteristics",
      payChar,
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_PAYMENT_CHAR_SAVE_END,
          payChars: responseJson
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_PAYMENT_CHAR_ERROR,
          error
        });
      }
    );
  };
}
