import { Trans } from "@lingui/macro";
import { Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import { Add, Cancel, Check, Delete, Warning } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import OldStructureAutocomplete from "components/Autocomplete/OldStructureAutocomplete";
import StructureAutocomplete from "components/Autocomplete/StructureAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import FieldText from "components/Common/FieldText";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { CrupdateMigCode, GetExistingMigCode, SearchMigCode } from "module/tpCopernic/store/actions/MigCode.Actions";
import { TP_COPERNIC_MIGCODE_END_ACTION, TP_COPERNIC_MIGCODE_GET_DETAIL } from "module/tpCopernic/store/actions/types";
import React, { useState } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import { TpPaymentMethod, TpPaymentPeriod, TpThirdPartyCategory } from "store/MasterValue/MasterValueTypes";
import uuidv4 from "uuid/v4"; // Generate GUID

function MigCodeDetail({ migCodeCtx, setDetail, getExistingMigCode, endEditing, crupdate, classes }) {
  var showDetail = migCodeCtx.showDetail;
  var isPerforming = migCodeCtx.isPerforming;

  const item = migCodeCtx.item;
  const existingItem = migCodeCtx.existingItem;
  const isNew = migCodeCtx.isNew;

  const [hasBeenModified, setHasBeenModified] = useState(false);
  const [newLegalIdentifier, setNewLegalIdentifier] = useState(null);
  const [newDuns, setNewDuns] = useState(null);
  const [newThirdPartyLegalIdentifierValue, setNewThirdPartyLegalIdentifierValue] = useState(null);

  function convertDataToReactTable() {
    if (!item.migCodeLegalIdentifiers) item.migCodeLegalIdentifiers = [];
    return item.migCodeLegalIdentifiers.map(legalId => {
      return {
        ...legalId,
        actions: (
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              color="info"
              className="like"
              onClick={() => {
                deleteLink(legalId.identifier);
              }}
            >
              <Delete />
            </Button>
          </div>
        )
      };
    });
  }

  var existingMigError = "";
  var isMigCodeInError = false;
  if (!!existingItem && !!existingItem.identifier) {
    isMigCodeInError = true;
    existingMigError = (
      <>
        <GridItem xs={4} sm={4} md={4}></GridItem>
        <GridItem xs={8} sm={8} md={8}>
          <div>
            <div style={{ lineHeight: "0.5em" }}>
              <Warning style={{ verticalAlign: "middle", color: "orange" }} />
              <span style={{ verticalAlign: "middle", color: "orange" }}>
                <strong>
                  <Trans>Code Mig already exist</Trans>
                </strong>
              </span>
            </div>
          </div>
        </GridItem>
      </>
    );
  }

  function deleteLink(idLink) {
    var links = item.migCodeLegalIdentifiers;
    var indexToDelete = links
      .map(e => {
        return e.identifier;
      })
      .indexOf(idLink);
    links.splice(indexToDelete, 1);
    item.migCodeLegalIdentifiers = links;
    setDetail({
      ...item
    });
    setHasBeenModified(true);
  }

  function addNewLink() {
    var links = item.migCodeLegalIdentifiers;
    if (!links) links = [];

    links.push({
      identifier: uuidv4(),
      migCodeIdentifier: item.identifier,
      legalIdentifierValue: newLegalIdentifier,
      dunsValue: newDuns,
      thirdPartyLegalIdentifierValue: newThirdPartyLegalIdentifierValue
    });
    setDetail({
      ...item
    });
    setNewDuns(null);
    setNewLegalIdentifier(null);
    setNewThirdPartyLegalIdentifierValue(null);
    setHasBeenModified(true);
  }

  function changeValue(value, propertyField) {
    item[propertyField] = value;
    setDetail({
      ...item
    });
    setHasBeenModified(true);
  }

  var content = "";

  if (showDetail) {
    var label = (
      <span>
        <Trans>Mig code</Trans> {item.identifier}
      </span>
    );
    var identifierLabelPart = "";
    var identifierPart = "";
    if (isNew) {
      label = (
        <span>
          <Trans>New Mig code</Trans>
        </span>
      );
      identifierLabelPart = (
        <GridItem xs={4} sm={4} md={4}>
          <FormLabel error={isMigCodeInError}>
            <Trans>Identifier</Trans>
          </FormLabel>
        </GridItem>
      );
      identifierPart = (
        <GridItem xs={8} sm={8} md={8}>
          <TextField
            margin="dense"
            id="identifier"
            type="text"
            fullWidth
            value={item.identifier}
            onChange={event => {
              changeValue(event.target.value, "identifier");
              getExistingMigCode(event.target.value);
            }}
          />
        </GridItem>
      );
    }
    var validateButton = "";
    if (hasBeenModified && (!existingItem || existingItem.length == 0)) {
      validateButton = (
        <Button
          color="info"
          onClick={() => {
            setHasBeenModified(false);
            crupdate(item);
          }}
        >
          <Trans>Validate</Trans>
        </Button>
      );
    }
    var buttons = (
      <DialogActions>
        {validateButton}
        <div style={{ flex: "1 0 0" }} />
        <Button onClick={endEditing} color="info">
          <Trans> Close </Trans>
        </Button>
      </DialogActions>
    );

    if (isPerforming)
      content = (
        <div>
          <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={true}
            onClose={endEditing}
            aria-labelledby="form-dialog-title"
            classes={{ paper: classes.dialogPaper }}
            scroll="paper"
          >
            <DialogTitle id="form-dialog-title">
              <div>{label}</div>
            </DialogTitle>
            <DialogContent
              style={{
                ...DialogInlineStyleDetail.dialogContent
              }}
              dividers={true}
            >
              <center>
                <CircularProgress />
              </center>
            </DialogContent>
          </Dialog>
        </div>
      );
    else
      content = (
        <div>
          <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={true}
            onClose={endEditing}
            aria-labelledby="form-dialog-title"
            classes={{ paper: classes.dialogPaper }}
            scroll="paper"
          >
            <DialogTitle id="form-dialog-title">
              <div>{label}</div>
            </DialogTitle>
            <DialogContent
              style={{
                ...DialogInlineStyleDetail.dialogContent
              }}
              dividers={true}
            >
              <div style={{ width: "98%" }}>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <Card
                      style={{
                        ...CardInlineStyle.card
                      }}
                    >
                      <CardHeader
                        style={{
                          ...CardInlineStyle.cardHeader
                        }}
                        icon
                      >
                        <h4>
                          <Trans>General Information</Trans>
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                          {existingMigError}
                          {identifierLabelPart}
                          {identifierPart}
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Label EN</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={8} sm={8} md={8}>
                            <TextField
                              margin="dense"
                              id="labelEn"
                              type="text"
                              fullWidth
                              value={item.label_EN}
                              onChange={event => {
                                changeValue(event.target.value, "label_EN");
                              }}
                            />
                          </GridItem>
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Label FR</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={8} sm={8} md={8}>
                            <TextField
                              margin="dense"
                              id="labelFr"
                              type="text"
                              fullWidth
                              value={item.label_FR}
                              onChange={event => {
                                changeValue(event.target.value, "label_FR");
                              }}
                            />
                          </GridItem>
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Third party category</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={7} sm={7} md={7}>
                            <MasterValueAutocomplete
                              isEditable={true}
                              mvType={TpThirdPartyCategory}
                              formatText={i => `${i.code} - ${i.label}`}
                              selectedValue={item.thirdPartyCategoryCode}
                              onValueChange={code => changeValue(code, "thirdPartyCategoryCode")}
                            />
                          </GridItem>
                          <GridItem xs={1} sm={1} md={1}>
                            <Button
                              justIcon
                              round
                              simple
                              color="info"
                              className="like"
                              onClick={() => {
                                changeValue(null, "thirdPartyCategoryCode");
                              }}
                            >
                              <Cancel />
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <Card
                      style={{
                        ...CardInlineStyle.card
                      }}
                    >
                      <CardHeader
                        style={{
                          ...CardInlineStyle.cardHeader
                        }}
                        icon
                      >
                        <h4>
                          <Trans>Payment and structure information</Trans>
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Sj</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={7} sm={7} md={7}>
                            {/* <BaseSelect
                              items={availableSjs}
                              displayedProperties={{
                                keyProperty: "identifier",
                                valueProperty: "label"
                              }}
                              transformValue={item => {
                                return item.identifier + " - " + item.oldIdentifier + " - " + item.label;
                              }}
                              value={item.sjIdentifier}
                              isSearchable={true}
                              onChange={event => {
                                changeValue(event.value, "sjIdentifier");
                              }}
                            /> */}
                            <OldStructureAutocomplete
                              structureTypes={["SJ"]}
                              value={{ structureId: item.sjIdentifier, structureType: "SJ" }}
                              onChange={v => {
                                if (v) {
                                  changeValue(v, "sjIdentifier");
                                }
                              }}
                            />
                          </GridItem>
                          <GridItem xs={1} sm={1} md={1}>
                            <Button
                              justIcon
                              round
                              simple
                              color="info"
                              className="like"
                              onClick={() => {
                                changeValue(null, "sjIdentifier");
                              }}
                            >
                              <Cancel />
                            </Button>
                          </GridItem>
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Payment Period</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={7} sm={7} md={7}>
                            <MasterValueAutocomplete
                              isEditable={true}
                              mvType={TpPaymentPeriod}
                              formatText={i => `${i.code} - ${i.label}`}
                              selectedValue={item.paymentPeriodCode}
                              onValueChange={code => changeValue(code, "paymentPeriodCode")}
                            />
                          </GridItem>
                          <GridItem xs={1} sm={1} md={1}>
                            <Button
                              justIcon
                              round
                              simple
                              color="info"
                              className="like"
                              onClick={() => {
                                changeValue(null, "paymentPeriodCode");
                              }}
                            >
                              <Cancel />
                            </Button>
                          </GridItem>
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Payment Method</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={7} sm={7} md={7}>
                            <MasterValueAutocomplete
                              isEditable={true}
                              mvType={TpPaymentMethod}
                              formatText={i => `${i.code} - ${i.label}`}
                              selectedValue={item.paymentMethodCode}
                              onValueChange={code => changeValue(code, "paymentMethodCode")}
                            />
                          </GridItem>
                          <GridItem xs={1} sm={1} md={1}>
                            <Button
                              justIcon
                              round
                              simple
                              color="info"
                              className="like"
                              onClick={() => {
                                changeValue(null, "paymentMethodCode");
                              }}
                            >
                              <Cancel />
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card
                      style={{
                        ...CardInlineStyle.card
                      }}
                    >
                      <CardHeader
                        style={{
                          ...CardInlineStyle.cardHeader
                        }}
                        icon
                      >
                        <h4>
                          <Trans>Flags</Trans>
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                              checked={item.authorizeSupplierCreation}
                              disabled={false}
                              onChange={event => {
                                changeValue(event.target.checked, "authorizeSupplierCreation");
                              }}
                            />
                          }
                          classes={{
                            label: classes.label
                          }}
                          label={<Trans>Is Colas</Trans>}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                              checked={item.isColas}
                              disabled={false}
                              onChange={event => {
                                changeValue(event.target.checked, "isColas");
                              }}
                            />
                          }
                          classes={{
                            label: classes.label
                          }}
                          label={<Trans>Is Colas</Trans>}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                              checked={item.isBouygues}
                              disabled={false}
                              onChange={event => {
                                changeValue(event.target.checked, "isBouygues");
                              }}
                            />
                          }
                          classes={{
                            label: classes.label
                          }}
                          label={<Trans>Is Bouygues</Trans>}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                              checked={item.isPrincipalShareHolder}
                              disabled={false}
                              onChange={event => {
                                changeValue(event.target.checked, "isPrincipalShareHolder");
                              }}
                            />
                          }
                          classes={{
                            label: classes.label
                          }}
                          label={<Trans>Is pricipal shareholder</Trans>}
                        />
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={5} sm={5} md={5}>
                    <Card
                      style={{
                        ...CardInlineStyle.card
                      }}
                    >
                      <CardHeader
                        style={{
                          ...CardInlineStyle.cardHeader
                        }}
                        icon
                      >
                        <h4>
                          <Trans>New</Trans>&nbsp;<Trans>Legal Identifier</Trans>&nbsp;<Trans>Or</Trans>&nbsp;<Trans>Duns</Trans>
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Legal Identifier</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={8} sm={8} md={8}>
                            <FieldText
                              key="newLegalIdentifier"
                              id="newLegalIdentifier"
                              value={newLegalIdentifier}
                              isEditable={true}
                              onChange={event => setNewLegalIdentifier(event.target.value)}
                            />
                          </GridItem>
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Duns</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={8} sm={8} md={8}>
                            <FieldText
                              key="newDuns"
                              id="newDuns"
                              value={newDuns}
                              isEditable={true}
                              onChange={event => setNewDuns(event.target.value)}
                            />
                          </GridItem>
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Long Legal Identifier</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={8} sm={8} md={8}>
                            <FieldText
                              key="newThirdPartyLegalIdentifierValue"
                              id="newThirdPartyLegalIdentifierValue"
                              value={newThirdPartyLegalIdentifierValue}
                              isEditable={true}
                              onChange={event => setNewThirdPartyLegalIdentifierValue(event.target.value)}
                            />
                          </GridItem>
                          <GridItem>
                            <IconButton aria-label="Add" onClick={addNewLink}>
                              <Add />
                            </IconButton>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={7} sm={7} md={7}>
                    <Card
                      style={{
                        ...CardInlineStyle.card
                      }}
                    >
                      <CardHeader
                        style={{
                          ...CardInlineStyle.cardHeader
                        }}
                        icon
                      >
                        <h4>
                          <Trans>Existing</Trans>&nbsp;<Trans>Legal Identifiers</Trans>&nbsp;<Trans>And</Trans>&nbsp;<Trans>Duns</Trans>
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <ReactTable
                              data={convertDataToReactTable()}
                              columns={[
                                {
                                  accessor: "actions",
                                  sortable: false,
                                  filterable: false,
                                  width: 50
                                },
                                {
                                  Header: <Trans>Legal identifier</Trans>,
                                  accessor: "legalIdentifierValue",
                                  filterable: true
                                },
                                {
                                  Header: <Trans>Duns</Trans>,
                                  accessor: "dunsValue",
                                  filterable: true
                                },
                                {
                                  Header: <Trans>Long Legal Identifier</Trans>,
                                  accessor: "thirdPartyLegalIdentifierValue",
                                  filterable: true
                                },
                                {
                                  width: 5
                                }
                              ]}
                              defaultPageSize={3}
                              showPaginationBottom={true}
                              showPageSizeOptions={false}
                              className="-striped -highlight"
                              showFilters={true}
                              style={ReactTableStyle.main}
                            />
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </div>
            </DialogContent>
            {buttons}
          </Dialog>
        </div>
      );
  }

  return <div>{content}</div>;
}

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "95%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};
const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    migCodeCtx: state.TP_MigCode
    //availableSjs: state.AuthenticationReducer.user.structureTps.sjs
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setDetail: item => {
      dispatch({
        type: TP_COPERNIC_MIGCODE_GET_DETAIL,
        item: item
      });
    },
    getExistingMigCode: migCode => {
      dispatch(GetExistingMigCode(migCode));
    },
    endEditing: item => {
      dispatch({
        type: TP_COPERNIC_MIGCODE_END_ACTION,
        item: null
      });
      dispatch(SearchMigCode());
    },
    crupdate: item => {
      dispatch(CrupdateMigCode(item));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(MigCodeDetail));
