import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as Actions from 'module/amc/store/actions/Job.Actions';

// custom components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

// helpers
import LocalStorageHelper from "services/common/LocalStorageHelper";

import { Trans } from '@lingui/macro';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";

function JobSearchCriteriaSimple({ showSearchCriteriaOnSideBar, amc, setCriterias, search, classes }) {
    const dispatch = useDispatch();

    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");

    var defaultSearchMode = "Simple";
    if (!!getPref && !!getPref.Pref && !!getPref.Pref.Search) {
        defaultSearchMode = getPref.Pref.Search;
    }

    const criterias = amc.criterias['JOB'];



    var searchGridSize = 4;
    if (showSearchCriteriaOnSideBar)
        searchGridSize = 12;

    var countrySimpleSearchWidth = 3;
    var simpleSearchWidth = 9;
    var divMultiSelectStyle = { width: '100%' };
    if (showSearchCriteriaOnSideBar) {
        countrySimpleSearchWidth = 12;
        simpleSearchWidth = 12;
        divMultiSelectStyle = { width: '100%', marginTop: '10px' };
    }

    function launchSearch(e) {
        e.preventDefault();
        search();
    }

    function cleanSearchCriterias(e) {
        e.preventDefault();
        var newCriterias = { pageSite: criterias.pageSite, criteriaFullText: '', criteriaMdmId: '' };
        setCriterias(newCriterias);
    }

    return (

        <form onSubmit={launchSearch} autoComplete="off">
            <GridContainer>
                <GridItem xs={simpleSearchWidth} sm={simpleSearchWidth} md={simpleSearchWidth}>
                    <CustomInput
                        labelText={<Trans>Mdm id, label, ...</Trans>}
                        id="criteriaFullTextSearch"
                        onChange={(e) => setCriterias({ ...criterias, criteriaFullText: e.target.value })}
                        value={criterias.criteriaFullText}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                </GridItem>
            </GridContainer>
            <div className={classes.flexRight}>
                <Button
                    onClick={cleanSearchCriterias}>
                    <Trans> Clean </Trans>
                </Button>
                <Button
                    type="submit"
                    color="info"
                    onClick={launchSearch}>
                    <Trans> Search </Trans>
                </Button>
            </div>
        </form>
    );
}

const mapStateToProps = state => {
    return {
        amc: state.AMC_Reducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCriterias: criterias => {
            dispatch(Actions.SetCriterias(criterias))
        },
        setSearchMode: searchMode => {
            dispatch(Actions.SetSearchMode(searchMode))
        },
        search: () => {
            dispatch(Actions.Search())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(amcSearchStyle)(JobSearchCriteriaSimple));