import React, { useEffect, useState } from 'react';
import { Query, Builder, BasicConfig, Utils as QbUtils } from 'react-awesome-query-builder';
import 'assets/jss/mdmcolas/common/awesomeSearch.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css'; //optional, for more compact styles
import { connect, useDispatch } from 'react-redux';


function ComplexSearchCriteria({ fields, queryValue, onChange }) {
    const dispatch = useDispatch();
    const config = {
        ...BasicConfig,
        fields: fields
    };

    const [tree, setTree] = React.useState(!!queryValue && typeof(queryValue) !== "undefined" ? QbUtils.checkTree(QbUtils.loadTree(queryValue), config) : null);
    const [conf, setConf] = React.useState(config);

    function RenderBuilder(props) {
        return (
            <div className="query-builder-container" style={{ padding: '10px' }}>
                <div className="query-builder qb-lite">
                    <Builder {...props} />
                </div>
            </div>
        );
    }

    function RequestChange(immutableTree, config) {
        setConf(config);
        setTree(immutableTree);

        if (!!onChange && typeof(onChange) !== "undefined")
            onChange(immutableTree, QbUtils.sqlFormat(immutableTree, config));
    }

    return (
        <>
            <Query
                {...config}
                value={tree}
                onChange={RequestChange}
                renderBuilder={RenderBuilder}
            />
        </>
    );
}

export default ComplexSearchCriteria;