import { Trans } from "@lingui/macro";
import { Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import { Check, Error, History, DeviceHub } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import StructureAutocomplete from "components/Autocomplete/StructureAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import DateSelector from "components/Common/DateSelector";
import { isArrayNullOrEmpty, isNull, isNullOrEmpty, isValidDate, toDate, dateToUtc  } from "tools";

import { HasRight } from "services/user/UserHelper";
import { CrupdateColCode, GetColCode, GetColCodeHistory, SearchColCode } from "./store/actions/ColCode.Actions";
import { OpenStructure } from "module/structure/store/actions";
import { STRUCTURE_COLCODE_END_ACTION, STRUCTURE_COLCODE_GET_DETAIL } from "./store/actions/types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import HistoryContainer from "module/history/containers/HistoryContainer";
import CardError from "components/Card/CardError";
import CardHeader from "components/Card/CardHeader";
import DialogBox from "components/DialogBox/DialogBox";

function ColCodeDetail({ colCodeCtx, setDetail, endEditing, getColCode, crupdate, classes, defaultLang, openStructure }) {
  const canEdit = HasRight("structure.edit");

  var showDetail = colCodeCtx.showDetail;
  var isPerforming = colCodeCtx.isPerforming;

  const item = colCodeCtx.item;
  const isNew = isNull(item) || isNull(item.id) || item.id === 0;

  const [hasBeenModified, setHasBeenModified] = useState(false);
  const [errors, setErrors] = useState([]);
  var [dialogBox, setDialogBox] = useState(null);

  function changeValue(value, propertyField) {
    if (canEdit) {
      item[propertyField] = value;
      setDetail({
        ...item
      });
      setHasBeenModified(true);
    }
  }
  
  function changeDateValue(value, propertyField) {
    if (value && isValidDate(new Date(value))) {
      changeValue(value, propertyField);
    }
    else
    {
      changeValue(null, propertyField);
    }
  }

  var content = "";

  if (showDetail) {
    var hasErrors = !isArrayNullOrEmpty(errors);

    var label = (
      <span>
        <Trans>Col Code</Trans> {item.identifier}
      </span>
    );

    var identifierLabelPart = "";
    var identifierPart = "";
    if (canEdit && isNew) {
      item.id = 0;
      item.dateStart = new Date();
      item.dateEnd = new Date();

      label = (
        <span>
          <Trans>New Col Code</Trans>
        </span>
      );
      identifierLabelPart = (
        <GridItem xs={4} sm={4} md={4}>
          <FormLabel error={hasErrors}>
            <Trans>Identifier</Trans>
          </FormLabel>
        </GridItem>
      );
      identifierPart = (
        <GridItem xs={8} sm={8} md={8}>
          <TextField
            margin="dense"
            id="identifier"
            type="text"
            fullWidth
            value={item.identifier}
            onChange={event => {
              changeValue(event.target.value, "identifier");
            }}
          />
        </GridItem>
      );
    }

    var errorCard = "";
    if (hasErrors) {
      errorCard = (
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              {errors.map((t, idx) => {
                return (
                  <div>
                    <div style={{ lineHeight: "0.5em" }}>
                      <Error style={{ verticalAlign: "middle", color: "red" }} />
                      <span style={{ verticalAlign: "middle", color: "red", marginRight: "5px" }}>
                      <strong>{t.descriptionFr}</strong>
                      </span>
                    </div>
                  </div>
                );
              })}
            </CardBody>
          </Card>
        </GridItem>
      );
    }

    var viewHistory = () => {
      setDialogBox({
        type: "cancel",
        message: <PartHistory colCode={item} defaultLang={defaultLang} />,
        cancel: text => {
          setDialogBox(null);
        }
      });
    };

    var validateButton = "";
    if (canEdit && hasBeenModified) {
      validateButton = (
        <Button
          color="info"
          onClick={() => {
            setHasBeenModified(false);
            crupdate(item, () => setErrors([]), e => setErrors(e));
          }}
        >
          <Trans>Validate</Trans>
        </Button>
      );
    }

    var buttons = (
      <DialogActions>
        {validateButton}
        <div style={{ flex: "1 0 0" }} />
        <IconButton onClick={viewHistory}>
          <History color="info" />
        </IconButton>
        <Button onClick={endEditing} color="info">
          <Trans> Close </Trans>
        </Button>
      </DialogActions>
    );
    var structLink = "";
    if (!isNullOrEmpty(item.structureIdentifier)) {
      structLink = (
        <IconButton
          onClick={() => openStructure(item.structureIdentifier.slice(0, 2), item.structureIdentifier)}
          className={classes.buttonLink}
          component={Link}
        >
          <DeviceHub className={classes.headerLinksSvg + " " + classes.links} />
        </IconButton>
      );
    }
    var structManagLink = "";
    if (!isNullOrEmpty(item.managingStructureIdentifier)) {
      structManagLink = (
        <IconButton onClick={() => openStructure("SJ", item.managingStructureIdentifier)} className={classes.buttonLink} component={Link}>
          <DeviceHub className={classes.headerLinksSvg + " " + classes.links} />
        </IconButton>
      );
    }
    if (isPerforming)
      content = (
        <div>
          <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={true}
            onClose={endEditing}
            aria-labelledby="form-dialog-title"
            classes={{ paper: classes.dialogPaper }}
            scroll="paper"
          >
            <DialogTitle id="form-dialog-title">
              <div>{label}</div>
            </DialogTitle>
            <DialogContent
              style={{
                ...DialogInlineStyleDetail.dialogContent
              }}
              dividers={true}
            >
              <center>
                <CircularProgress />
              </center>
            </DialogContent>
          </Dialog>
        </div>
      );
    else
      content = (
        <div>
          <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={true}
            onClose={endEditing}
            aria-labelledby="form-dialog-title"
            classes={{ paper: classes.dialogPaper }}
            scroll="paper"
          >
            <DialogTitle id="form-dialog-title">
              <div>{label}</div>
            </DialogTitle>
            <DialogContent
              style={{
                ...DialogInlineStyleDetail.dialogContent
              }}
              dividers={true}
            >
              <div style={{ width: "98%" }}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card
                      style={{
                        ...CardInlineStyle.card
                      }}
                    >
                      <CardBody>
                        <GridContainer>
                          {identifierLabelPart}
                          {identifierPart}
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Label</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={7} sm={7} md={7}>
                            <CustomInput
                              isEditable={canEdit}
                              onChange={e => changeValue(e.target.value, "label")}
                              value={item.label}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                          </GridItem>
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Date start</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={7} sm={7} md={7}>
                            <DateSelector
                              value={item.dateStart}
                              isEditable={canEdit}
                              openDaysOnly={false}
                              onChange={e => changeDateValue(e, "dateStart")}
                              defaultLang={defaultLang}
                            />
                          </GridItem>
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Date end</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={7} sm={7} md={7}>
                          <DateSelector
                              value={item.dateEnd}
                              isEditable={canEdit}
                              openDaysOnly={false}
                              onChange={e => changeDateValue(e, "dateEnd")}
                              defaultLang={defaultLang}
                            />
                          </GridItem>
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Is Demat</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={7} sm={7} md={7}>
                            <Checkbox
                              isEditable={canEdit}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                              onChange={e => changeValue(e.currentTarget.checked, "colDemat")}
                              checked={item.colDemat}
                            />
                          </GridItem>
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Structure</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={6} sm={6} md={6}>
                            <StructureAutocomplete
                              isEditable={canEdit}
                              structureTypes={["UE", "UP"]}
                              value={item.structureId}
                              onChange={e => changeValue(e !== undefined && e !== null ? e.id : "", "structureId")}
                              size="small"
                            />
                          </GridItem>
                          <GridItem xs={1} sm={1} md={1}>
                            {structLink}
                          </GridItem>
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Managing Structure</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={6} sm={6} md={6}>
                            <StructureAutocomplete
                              isEditable={canEdit}
                              structureTypes={["SJ"]}
                              value={item.managingStructureId}
                              onChange={e => changeValue(e !== undefined && e !== null ? e.id : "", "managingStructureId")}
                              size="small"
                            />
                          </GridItem>
                          <GridItem xs={1} sm={1} md={1}>
                            {structManagLink}
                          </GridItem>
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Addressage Code</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={7} sm={7} md={7}>
                            <CustomInput
                              isEditable={canEdit}
                              onChange={e => changeValue(e.target.value, "addressageCode")}
                              value={item.addressageCode}
                              formControlProps={{
                                fullWidth: true
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                  {errorCard}
                </GridContainer>
              </div>
            </DialogContent>
            {buttons}
          </Dialog>
        </div>
      );
  }

  return (
    <div>
      {content}
      <DialogBox dialogBox={dialogBox} setDialogBox={setDialogBox} />
    </div>
  );
}

const PartHistory = ({ colCode, defaultLang }) => {
  if (isNullOrEmpty(defaultLang)) defaultLang = "FR";

  const [state, setState] = useState({ isLoading: false });

  if (!state.isLoading && !state.history) {
    setState({ isLoading: true, history: [] });

    GetColCodeHistory(colCode.id, h => setState({ isLoading: false, history: h }), e => setState({ isLoading: false, history: [], error: e }));
  }

  if (state.error) {
    return <CardError error={state.error} />;
  }

  if (state.isLoading) {
    return <CircularProgress />;
  }

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Structure_Part_History</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <HistoryContainer items={state.history} />
      </CardBody>
    </Card>
  );
};

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "95%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    colCodeCtx: state.ST_ColCode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setDetail: item => {
      dispatch({
        type: STRUCTURE_COLCODE_GET_DETAIL,
        item: item
      });
    },
    getColCode: id => {
      dispatch(GetColCode(id));
    },
    endEditing: item => {
      dispatch({
        type: STRUCTURE_COLCODE_END_ACTION,
        item: null
      });
      dispatch(SearchColCode());
    },
    crupdate: (item, callbackFn, errorFn) => {
      dispatch(CrupdateColCode(item, callbackFn, errorFn));
    },
    openStructure: (structureType, identifiant) => {
      dispatch(OpenStructure(structureType, identifiant));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(ColCodeDetail));
