import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  FormControlLabel,
  Tooltip
} from "@material-ui/core";
import { Close, Check } from "@material-ui/icons";
import { Trans } from "@lingui/macro";
import ThirdPartySelect from "../../module/tpCopernic/thirdParties/ThirdPartySelect";
import { red } from "@material-ui/core/colors";
import CountryAutocomplete from "../Autocomplete/CountryAutocomplete";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import { Alert } from "@material-ui/lab";
import { CommonLanguage, CommonCountry } from "store/MasterValue/MasterValueTypes";
import { isNullOrEmpty } from "tools";
import { RootStore } from "store/RootStore";
import { HasRight } from "services/user/UserHelper";
import FieldText from "./FieldText";

const ThirdPartySelectorNew = ({
  thirdParty,
  setThirdParty,
  errorMessage,
  error,
  isEditable,
  isLanguageEditable,
  clearable,
  initialCriterias,
  classes,
  title,
  isBeneficiary,
  showIsHandicapSector
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [errMsg, setErrMsg] = useState(errorMessage);

  var thirdPartyLabel = (
    <em>
      <Trans>NoThirdPartySelected</Trans>
    </em>
  );
  if (thirdParty) {
    var tpCountry = RootStore.getState().MasterValueReducer[CommonCountry].find(c => c.code === thirdParty.countryCode);
    thirdPartyLabel = (
      <>
        <div>
          ({thirdParty.identifier} -
          {isNullOrEmpty(thirdParty.legalIdentifier)
            ? thirdParty.thirdParty_LegalIdentifiers.length > 0
              ? thirdParty.thirdParty_LegalIdentifiers[0].value
              : ""
            : thirdParty.legalIdentifier}
          )) {thirdParty.longName}
        </div>
        <div>
          {thirdParty.address_Line1}
          <br />
          {thirdParty.address_ZipCode} {thirdParty.address_City}
        </div>
        <div>
          <FieldText
            margin="dense"
            id="customLongName"
            fullWidth
            value={thirdParty.customLongName}
            onChange={e => setThirdParty({ ...thirdParty, customLongName: e.target.value })}
            isEditable={thirdParty.identifier === 0}
          />
        </div>
        <div>
          <CountryAutocomplete isEditable={false} countryCode={thirdParty.countryCode} />
        </div>
        <div>
          <MasterValueAutocomplete
            isEditable={isLanguageEditable && (thirdParty.identifier === 0 || HasRight("thirdparty_workflow.edit"))}
            isRequired
            mvType={CommonLanguage}
            selectedValue={
              thirdParty.identifier === 0 && thirdParty.languageCode === null ? (!tpCountry ? "F" : tpCountry.languageCode) : thirdParty.languageCode
            }
            onValueChange={value => setThirdParty({ ...thirdParty, languageCode: value })}
          />
        </div>
        {(thirdParty.isHeadQuarter || (thirdParty.mig != null && (thirdParty.mig.authorizeSupplierCreation || thirdParty.mig.isColas))) && (
          <div hidden={showIsHandicapSector === undefined || !showIsHandicapSector}>
            <Tooltip
              id="tooltip-top"
              title={
                <>
                  <Trans>Handicap sector info</Trans>
                </>
              }
              placement="auto"
              classes={{ tooltip: classes.tooltip }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                    checked={thirdParty.isHandicapSector}
                    disabled={!isEditable || !HasRight("thirdparty_workflow.edit")}
                    onChange={e => setThirdParty({ ...thirdParty, isHandicapSector: e.target.checked })}
                  />
                }
                classes={{
                  label: classes.label
                }}
                label={<Trans>Handicap sector</Trans>}
              />
            </Tooltip>
          </div>
        )}
      </>
    );
  }

  var dialog = <></>;
  if (showDialog) {
    dialog = (
      <Dialog open={true} onClose={() => setShowDialog(false)} maxWidth="lg" fullWidth>
        <DialogTitle>
          Sélection du tiers
          <IconButton aria-label="close" className={classes.closeButton} onClick={() => setShowDialog(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ThirdPartySelect
            initialCriteria={initialCriterias}
            setThirdParty={tp => {
              if (isBeneficiary) {
                if (!tp.isBeneficiary) {
                  setThirdParty(null);
                  setShowDialog(false);
                  setErrMsg(<Trans>test</Trans>);
                } else {
                  setShowDialog(false);
                  setThirdParty(tp);
                  setErrMsg(null);
                }
              } else {
                setShowDialog(false);
                setThirdParty(tp);
                setErrMsg(null);
              }
            }}
            searchModes={["Extend"]}
          />
        </DialogContent>
      </Dialog>
    );
  }

  var cardHeader = thirdPartyLabel;
  if (title) {
    cardHeader = (
      <>
        {title}
        <br />
        {cardHeader}
      </>
    );
  }
  if (errMsg) {
    cardHeader = (
      <>
        <Alert severity="error">{errMsg}</Alert>
      </>
    );
  }

  return (
    <>
      <Card classes={{ root: !!error ? classes.cardError : classes.card }}>
        <CardContent>{cardHeader}</CardContent>
        <CardActions>
          {isEditable && (
            <Button onClick={() => setShowDialog(true)}>
              <Trans>Edit</Trans>
            </Button>
          )}
          {isEditable && !!clearable && thirdParty && (
            <Button style={{ color: red[500] }} onClick={() => setThirdParty(null)}>
              <Trans>Delete</Trans>
            </Button>
          )}
        </CardActions>
      </Card>
      {dialog}
    </>
  );
};

export default withStyles(thirdPartySearchCriteriaStyle)(ThirdPartySelectorNew);
