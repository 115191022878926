import { ApimDelete, ApimGet, ApimPost } from "components/Common/ApimSender";
import { isArray, isArrayNullOrEmpty } from "tools";
import * as Actions from "./types";

export function WorkflowSearch(criterias, callbackFn, errorFn) {
  ApimPost("api/Workflows/Search", criterias, c => callbackFn(c), e => errorFn(e));
}

export function GetWorkflow(workflowTypeCode, workflowId, callbackFn, errorFn) {
  return ApimGet(
    `api/Workflows/${workflowTypeCode}/${workflowId}`,
    c => {
      var cc = isArrayNullOrEmpty(c) ? {} : c;
      callbackFn(cc);
    },
    errorFn
  );
}

export function WorkflowCount(callbackFn, errorFn) {
  return ApimGet("api/Workflows/Count", callbackFn, errorFn);
}

export function AbortWorkflow(workflow, callbackFn, errorFn) {
  return ApimPost("api/Workflows/Abort", workflow, callbackFn, errorFn);
}

export function ValidateWorkflow(workflow, callbackFn, errorFn) {
  return ApimPost("api/Workflows/Validate", workflow, callbackFn, errorFn);
}

export function RollbackWorkflow(workflow, callbackFn, errorFn) {
  return ApimPost("api/Workflows/Rollback", workflow, callbackFn, errorFn);
}

export function SaveWorkflow(workflow, callbackFn, errorFn) {
  return ApimPost("api/Workflows", workflow, callbackFn, errorFn);
}

export function DeleteDraftWorkflow(workflow, callbackFn, errorFn) {
  return ApimGet("api/Workflows/" + workflow.identifier + "/removeDraft", callbackFn, errorFn);
}

export function WorkflowGetHistories(workflowId, callbackFn, errorFn) {
  return ApimGet(`api/Workflows/Histories/${workflowId}`, callbackFn, errorFn);
}

export function OpenWorkflow(workflowTypeCode, workflowId) {
  return dispatch => {
    return dispatch({
      type: Actions.WORKFLOW_OPEN,
      workflowId,
      workflowTypeCode
    });
  };
}

export function CloseWorkflow() {
  return dispatch => {
    return dispatch({
      type: Actions.WORKFLOW_CLOSE
    });
  };
}
