import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-6';
import { connect, useDispatch } from 'react-redux';
import * as Actions from 'module/amc/store/actions/Worker.Actions';
import { isArrayNullOrEmpty, isNull, isNullOrEmpty } from 'module/tools';

import { Breadcrumbs, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Card, Tabs, Tab, FormLabel } from '@material-ui/core';
import { RefreshOutlined, ErrorOutlined, CheckCircleOutlined, Warning } from '@material-ui/icons';
import { TabPanel } from 'components/Common/Tabs';

import NavPills from "components/NavPills/NavPills.jsx";
import Button from 'components/CustomButtons/Button';

// helpers
import LocalStorageHelper from "services/common/LocalStorageHelper";

// core components
import { Check, SearchOutlined, ViewDay, ViewHeadline, ViewQuilt } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";

//lingui
import { I18n } from "@lingui/react";
import { Trans } from "@lingui/macro";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import FieldText from '../../../../components/Common/FieldText';

function WorkerDetail({ amc, isReadonly, itemChanged }) {
    const dispatch = useDispatch();
    const [tabIdx, setTabIdx] = React.useState(0);

    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");



    var item = amc.currentItem['WORKER'];


    return (
        <div style={{ width: '99%' }}>
            <GridContainer>
                <GridItem xs={4}>
                    <FormLabel><Trans>Login</Trans></FormLabel>
                </GridItem>
                <GridItem xs={8}>
                    <FieldText
                        isEditable={!isReadonly}
                        id="login"
                        value={item.login}
                        onChange={
                            (e) => itemChanged({ ...item, login: e.target.value })
                        }
                    />
                </GridItem>
                <GridItem xs={4}>
                    <FormLabel><Trans>First name</Trans></FormLabel>
                </GridItem>
                <GridItem xs={8}>
                    <FieldText
                        isEditable={!isReadonly}
                        id="firstName"
                        value={item.firstName}
                        onChange={
                            (e) => itemChanged({ ...item, firstName: e.target.value })
                        }
                    />
                </GridItem>
                <GridItem xs={4}>
                    <FormLabel><Trans>Last name</Trans></FormLabel>
                </GridItem>
                <GridItem xs={8}>
                    <FieldText
                        isEditable={!isReadonly}
                        id="lastName"
                        value={item.lastName}
                        onChange={
                            (e) => itemChanged({ ...item, lastName: e.target.value })
                        }
                    />
                </GridItem>
            </GridContainer>
        </div>
    );
}



function GetSaveStatusIcon(saveStatus) {
    if (!isNull(saveStatus)) {
        switch (saveStatus) {
            case 'PENDING':
                return (<RefreshOutlined />);
            case 'ERROR':
                return (<ErrorOutlined style={{ color: 'red' }} />);
            case 'SUCCESS_RELOAD':
            case 'SUCCESS':
                return (<CheckCircleOutlined style={{ color: 'green' }} />);
        }
    }
    return '';
}

const mapStateToProps = state => {
    return {
        amc: state.AMC_Reducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCriterias: criterias => {
            dispatch(Actions.SetCriterias(criterias))
        },
        search: () => {
            dispatch(Actions.Search())
        },
        itemChanged: item => {
            dispatch(Actions.ItemChanged(item))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(amcSearchStyle)(WorkerDetail));