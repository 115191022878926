import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-6';
import { connect, useDispatch } from 'react-redux';
import * as Actions from 'module/amc/store/actions/Job.Actions';
import * as Tools from 'module/tools';

// custom components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CountryAutocomplete from 'components/Autocomplete/CountryAutocomplete';
import Select, { components } from 'react-select';
import { AntTabs, AntTab, TabPanel } from 'components/Common/Tabs';

// helpers
import LocalStorageHelper from "services/common/LocalStorageHelper";

// core components
import { Check, SearchOutlined, ViewDay, ViewHeadline, ViewQuilt } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Checkbox, FormControlLabel, Typography, FormLabel, TextField } from "@material-ui/core";

//lingui
import { Trans } from '@lingui/macro';

import { Warning, CheckCircleOutlined } from '@material-ui/icons';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import FieldText from 'components/Common/FieldText';

function JobBudgetPart({ amc, masterValues, itemChanged, isReadonly }) {
    const dispatch = useDispatch();
    const [tabIdx, setTabIdx] = React.useState(0);

    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");

    var item = !!amc.currentItem['JOB'] ? amc.currentItem['JOB'] : {};
    var warnings = amc.errors['JOB'];

    var dateInputProps = {};
    if (isReadonly)
        dateInputProps = {
            readOnly: { isReadonly },
            disabled: { isReadonly },
        };

    return (
        <Card
            style={{
                ...CardInlineStyle.card
            }}
        >
            <CardHeader
                style={{
                    ...CardInlineStyle.cardHeader
                }}
                icon>
                <h4><Trans>Budgets</Trans></h4>
            </CardHeader>
            <CardBody>
                <GridContainer>
                    <GridItem xs="6" sm="6" md="6">
                        <GridContainer>
                            <GridItem xs={7} sm={7} md={7}>
                                <FormLabel><Trans>ForecastExpenditure</Trans></FormLabel>
                            </GridItem>
                            <GridItem xs={5} sm={5} md={5}>
                                <FieldText
                                    isEditable={!isReadonly}
                                    id="forecastExpenditure"
                                    value={item.forecastExpenditure}
                                    type="number"
                                    onChange={
                                        (e) => itemChanged({ ...item, forecastExpenditure: e.target.value })
                                    }
                                />
                            </GridItem>
                            <GridItem xs={7} sm={7} md={7}>
                                <FormLabel><Trans>ForecastExpenditureUpdated</Trans></FormLabel>
                            </GridItem>
                            <GridItem xs={5} sm={5} md={5}>
                                <FieldText
                                    isEditable={!isReadonly}
                                    id="forecastExpenditureUpdated"
                                    value={item.forecastExpenditureUpdated}
                                    type="number"
                                    onChange={
                                        (e) => itemChanged({ ...item, forecastExpenditureUpdated: e.target.value })
                                    }
                                />
                            </GridItem>
                            <GridItem xs={7} sm={7} md={7}>
                                <FormLabel><Trans>FinalProjectionExpenditures</Trans></FormLabel>
                            </GridItem>
                            <GridItem xs={5} sm={5} md={5}>
                                <FieldText
                                    isEditable={!isReadonly}
                                    id="finalProjectionExpenditures"
                                    value={item.finalProjectionExpenditures}
                                    type="number"
                                    onChange={
                                        (e) => itemChanged({ ...item, finalProjectionExpenditures: e.target.value })
                                    }
                                />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs="6" sm="6" md="6">
                        <GridContainer>
                            <GridItem xs={7} sm={7} md={7}>
                                <FormLabel><Trans>ForecastTurnover</Trans></FormLabel>
                            </GridItem>
                            <GridItem xs={5} sm={5} md={5}>
                                <FieldText
                                    isEditable={!isReadonly}
                                    id="forecastTurnover"
                                    value={item.forecastTurnover}
                                    type="number"
                                    onChange={
                                        (e) => itemChanged({ ...item, forecastTurnover: e.target.value })
                                    }
                                />
                            </GridItem>
                            <GridItem xs={7} sm={7} md={7}>
                                <FormLabel><Trans>ForecastTurnoverUpdated</Trans></FormLabel>
                            </GridItem>
                            <GridItem xs={5} sm={5} md={5}>
                                <FieldText
                                    isEditable={!isReadonly}
                                    id="forecastTurnoverUpdated"
                                    value={item.forecastTurnoverUpdated}
                                    type="number"
                                    onChange={
                                        (e) => itemChanged({ ...item, forecastTurnoverUpdated: e.target.value })
                                    }
                                />
                            </GridItem>
                            <GridItem xs={7} sm={7} md={7}>
                                <FormLabel><Trans>FinalProjectionTurnover</Trans></FormLabel>
                            </GridItem>
                            <GridItem xs={5} sm={5} md={5}>
                                <FieldText
                                    isEditable={!isReadonly}
                                    id="finalProjectionTurnover"
                                    value={item.finalProjectionTurnover}
                                    type="number"
                                    onChange={
                                        (e) => itemChanged({ ...item, finalProjectionTurnover: e.target.value })
                                    }
                                />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </CardBody>
        </Card>
    );
}

const CardInlineStyle = {
    card: {
        marginTop: "10px",
    },
    cardHeader: {
        backgroundColor: "rgba(0, 172, 193, 0.6)",
        margin: "0",
        paddingLeft: "10px",
    }
};

const mapStateToProps = state => {
    return {
        amc: state.AMC_Reducer,
        masterValues: state.MasterValueReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        itemChanged: item => {
            dispatch(Actions.ItemChanged(item))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(amcSearchStyle)(JobBudgetPart));