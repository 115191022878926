import { Trans } from "@lingui/macro";
import { IconButton, Link, Tooltip } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { ArrowForward } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Warning from "components/Typography/Warning";
import Wizard from "components/Wizard/Wizard";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LocalStorageHelper from "services/common/LocalStorageHelper.jsx";
import { CommonCountry } from "store/MasterValue/MasterValueTypes";
import { isNullOrEmpty } from "tools";
import { isArrayNullOrEmpty } from "../../../tools";
import PrivateStep1 from "../components/WizardSteps/PrivateStep1.jsx";
import PrivateStep2 from "../components/WizardSteps/PrivateStep2.jsx";
import * as Actions from "../store/actions";
import { ThirdPartyAutocomplete, ThirdPartyCrupdate, ThirdPartyValidate } from "../thirdParties/actions/ThirdPartyActions";

function ThirdPartyWizardPrivate({ openThirdParty, handleClose, masterValues, defaultLang }) {
  const initialState = {
    thirdPartyCategoryCode: "PAR"
  };
  const [hasChanged, setHasChanged] = useState(false);
  const [thirdParty, setThirdParty] = useState(initialState);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (isNullOrEmpty(thirdParty.address_CountryCode)) {
      var defaultCountry = "FR";
      var localStorageHelper = new LocalStorageHelper();
      var getPref = localStorageHelper.get("Preferences");
      if (getPref !== null) {
        defaultCountry = getPref.InfoGeneral.Country;
      }
      var country = masterValues[CommonCountry].find(c => c.code === defaultCountry);
      var lang = !country ? "E" : country.languageCode;

      setThirdParty({ ...thirdParty, address_CountryCode: defaultCountry, languageCode: lang });
    }
  }, defaultLang);

  function handleSetThirdParty(tp) {
    setThirdParty(tp);
    setHasChanged(tp !== initialState);
  }

  var steps = [
    {
      title: <Trans>ThirdParty_Wizard_Individual_Step1</Trans>,
      content: () => <PrivateStep1 thirdParty={thirdParty} errors={errors} setThirdParty={handleSetThirdParty} masterValues={masterValues} />,
      validate: (callback, force) => validateStep(1, callback, force)
    },
    {
      title: <Trans>ThirdParty_Wizard_Individual_Step2</Trans>,
      content: () => <PrivateStep2 thirdParty={thirdParty} handleChange={handleSetThirdParty} />
    }
  ];

  function validateStep(stepId, callback, force) {
    ThirdPartyAutocomplete(
      thirdParty,
      updatedItem => {
        setThirdParty(updatedItem);
        ThirdPartyValidate(
          updatedItem,
          "privateStep1",
          e => {
            if (force && !e.some(e1 => e1.severity === "Error")) {
              setErrors(errors.filter(e => e.step !== stepId));
              callback(true);
              return;
            }
            setErrors([
              ...errors.filter(e => e.step !== stepId),
              ...e.map(er => {
                er.step = stepId;
                return er;
              })
            ]);
            callback(e.length === 0);
          },
          e => {
            callback(false);
            console.log(e);
          }
        );
      },
      error => {
        callback(false);
        console.log(error);
      }
    );
  }

  function handleSave() {
    ThirdPartyCrupdate(
      thirdParty,
      newItem => {
        handleClose();
        openThirdParty(newItem.identifier);
      },
      error => {
        console.log(error);
      }
    );
  }

  var cardWarning;
  if (!isArrayNullOrEmpty(errors)) {
    var errorMessage = errors.map((t, idx) => {
      if (t.code === "ExistingPrivateLastName") {
        var links = generateLinkForExistingThirdParty(t.complexLinkedObject, handleClose, openThirdParty);
        return (
          <div>
            <div style={{ lineHeight: "0.5em" }}>
              <Warning style={{ verticalAlign: "middle", color: "orange" }} />
              <span style={{ verticalAlign: "middle", color: "orange" }}>
                <strong>
                  <Trans>At least one third party with the same long name already exists</Trans>
                </strong>
              </span>{" "}
              - {t.complexLinkedObject.longName}
            </div>
            <ol>
              <Trans>You can choose one of the existing or decide to continue :</Trans>
              {links}
            </ol>
          </div>
        );
      }
    });

    if (errorMessage.length > 0) {
      cardWarning = (
        <Card>
          <CardBody>{errorMessage}</CardBody>
        </Card>
      );
    }
  }

  return (
    <Wizard
      title={<Trans>Private ThirdParty creation</Trans>}
      steps={steps}
      handleClose={handleClose}
      checkClose={() => !hasChanged}
      handleSave={handleSave}
      cardWarning={cardWarning}
    />
  );
}

function generateLinkForExistingThirdParty(thirdparties, handleClose, openThirdParty) {
  var links = thirdparties.map(item => {
    return (
      <ul>
        <Tooltip
          placement="right"
          title={
            <>
              {item.address_Line2}
              <br />
              {item.address_Line1}
              <br />
              {item.address_ZipCode}
              <br />
              {item.address_City}
              <br />
              {item.address_CountryCode}
            </>
          }
        >
          <Link
            style={{ color: "black", fontWeight: "200", cursor: "pointer" }}
            onClick={event => {
              handleClose();
              openThirdParty(item.identifier);
            }}
          >
            <IconButton
              style={{
                color: "orange"
              }}
            >
              <ArrowForward />
            </IconButton>
            <span style={{ color: "black", fontWeight: "200", cursor: "pointer" }}>
              {item.privateFirstName} - {item.privateLastName}
            </span>
          </Link>
        </Tooltip>
      </ul>
    );
  });
  return links;
}

const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openThirdParty: thirdPartyId => {
      dispatch(Actions.OpenThirdParty(thirdPartyId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(ThirdPartyWizardPrivate));
