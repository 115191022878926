import { Trans } from "@lingui/macro";
import { FormLabel, TextField } from "@material-ui/core";
import OldStructureAutocompleteWithDefaultValue from "components/Autocomplete/OldStructureAutocompleteWithDefaultValue";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect } from "react";

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

function RequesterInformation({ request, setRequest, errors, availableUes, isReadOnly, onValueChange }) {
  const error = code => errors && errors.some(e => e.code === code);

  useEffect(() => {
    if (availableUes.length === 1 && !request.applicantUe) {
      setRequest({ ...request, applicantUe: availableUes[0].identifier });
    }
  }, []);

  var selectApplicantUe = (
    <OldStructureAutocompleteWithDefaultValue
      selectedValue={request.applicantUe}
      readOnly={isReadOnly}
      structures={availableUes}
      onValueChange={e => setRequest({ ...request, applicantUe: e })}
      error={error("applicantUe")}
    />
  );

  //if (isReadOnly) {
  //    var applicantUe = availableUes.find(a => a.identifier === request.applicantUe);
  //    if (applicantUe) {
  //        selectApplicantUe = <Tooltip title={applicantUe.identifier + " - " + applicantUe.oldIdentifier + " - " + applicantUe.label}>{selectApplicantUe}</Tooltip>;
  //    }
  //}

  return (
    <>
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <Trans>COUPA user identification</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={3}>
              <FormLabel>
                <Trans>First Name</Trans>/<Trans>Last Name</Trans>
              </FormLabel>
              <TextField margin="dense" id="applicantName" type="text" disabled={isReadOnly} fullWidth value={request.applicantName} />
            </GridItem>
            <GridItem xs={3}>
              <FormLabel>
                <Trans>Login</Trans>
              </FormLabel>
              <TextField margin="dense" id="applicantLogin" type="text" disabled={isReadOnly} fullWidth value={request.applicantLogin} />
            </GridItem>
            <GridItem xs={3}>
              <FormLabel>
                <Trans>Function</Trans>
              </FormLabel>
              <TextField margin="dense" id="applicantFunction" type="text" disabled={isReadOnly} fullWidth value={request.applicantFunction} />
            </GridItem>
            <GridItem xs={3}>
              <FormLabel error={error("applicantUe")}>
                <Trans>Agency</Trans>
              </FormLabel>
              {selectApplicantUe}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </>
  );
}

export default RequesterInformation;
