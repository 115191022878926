import React, { useEffect, useState } from "react";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { SearchOutlined, ViewDay, ViewHeadline, ViewQuilt } from "@material-ui/icons";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import { Typography } from "@material-ui/core";
import SearchCriteriaSimple from "./SearchCriteriaSimple";
import SearchCriteriaExtended from "./SearchCriteriaExtended";
//import SearchCriteriasComplex from './SearchCriteriasComplex';
import { Trans } from "@lingui/macro";

function SearchCriteria({ criteria, setCriteria, search, cleanSearch, showSearchCriteriaOnSideBar, defaultLang }) {
  const [searchMode, setSearchMode] = useState("Simple");

  const blockHeaderSearchClasses = ["blockHeaderSearch"];
  if (showSearchCriteriaOnSideBar) blockHeaderSearchClasses.push("Reduce");

  const simpleSearchClasses = ["simple-search"];
  const extendedSearchClasses = ["extended-search"];
  var searchTitle = "";

  switch (searchMode) {
    case "Simple":
      simpleSearchClasses.push("show");
      if (!showSearchCriteriaOnSideBar) searchTitle = <Trans> Quick Search </Trans>;
      break;
    case "Extend":
      extendedSearchClasses.push("show");
      if (!showSearchCriteriaOnSideBar) searchTitle = <Trans> Extended Search</Trans>;
      break;
    default:
      console.warn("Unknown searchMode: " + criteria.searchMode);
      break;
  }

  const launchSearch = e => {
    e.preventDefault();
    search(criteria);
  };

  var searchComponent = "";
  switch (searchMode) {
    case "Simple":
      searchComponent = (
        <SearchCriteriaSimple
          criteria={criteria}
          setCriteria={setCriteria}
          cleanSearch={cleanSearch}
          launchSearch={launchSearch}
          simpleSearchClasses={simpleSearchClasses}
          showSearchCriteriaOnSideBar={showSearchCriteriaOnSideBar}
          defaultLang={defaultLang}
        />
      );
      break;
    case "Extend":
      searchComponent = (
        <SearchCriteriaExtended
          criteria={criteria}
          setCriteria={setCriteria}
          cleanSearch={cleanSearch}
          launchSearch={launchSearch}
          extendedSearchClasses={extendedSearchClasses}
          showSearchCriteriaOnSideBar={showSearchCriteriaOnSideBar}
          defaultLang={defaultLang}
        />
      );
      break;
    default:
      console.warn("Unknown searchMode: " + searchMode);
      break;
  }

  return (
    <Card>
      <CardHeader color="info" icon>
        <CardIcon color="info">
          <SearchOutlined />
        </CardIcon>
        <div className={blockHeaderSearchClasses.join(" ")}>
          <div className="blockHeaderSearchItem">
            <ToggleButtonGroup
              value={searchMode}
              exclusive
              onChange={(e, mode) => {
                setSearchMode(mode);
                setCriteria({});
              }}
            >
              <ToggleButton value="Simple">
                <ViewDay />
              </ToggleButton>
              <ToggleButton value="Extend">
                <ViewHeadline />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="blockHeaderSearchItem">
            <Typography ariant="body1">{searchTitle}</Typography>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className={simpleSearchClasses.join(" ")}>
          <SearchCriteriaSimple
            criteria={criteria}
            setCriteria={setCriteria}
            cleanSearch={cleanSearch}
            launchSearch={launchSearch}
            showSearchCriteriaOnSideBar={showSearchCriteriaOnSideBar}
            defaultLang={defaultLang}
          />
        </div>
        <div className={extendedSearchClasses.join(" ")}>
          <SearchCriteriaExtended
            criteria={criteria}
            setCriteria={setCriteria}
            cleanSearch={cleanSearch}
            launchSearch={launchSearch}
            showSearchCriteriaOnSideBar={showSearchCriteriaOnSideBar}
            defaultLang={defaultLang}
          />
        </div>
      </CardBody>
    </Card>
  );
}

export default withStyles(thirdPartySearchCriteriaStyle)(SearchCriteria);
