import { Trans } from "@lingui/macro";
import { Button, Checkbox, FormControlLabel, withStyles } from "@material-ui/core";
import { Assignment, Flag, LockOpen, Lock } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import React, { useState } from "react";
import ReactTable from "react-table-6";
import LocalStorageHelper from "services/common/LocalStorageHelper";

function SearchResultTable({ results, criteria, launchSearch, setThirdParty }) {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  if (!results) {
    return "";
  }

  if (!results.items) {
    results.items = [];
  }

  var pageSize = 10;
  var localStorageHelper = new LocalStorageHelper();
  var getPref = localStorageHelper.get("Preferences");
  if (getPref !== null) {
    pageSize = getPref.Pref.ResultPageSize * 1;
  }

  var items = results.items.map(prop => {
    return {
      Identifier: prop.identifier,
      Status: prop.thirdPartyStatusCode,
      LongName: prop.longName,
      LegalIdentifier: prop.legalIdentifier,
      Address_Line1: prop.address_Line1,
      Address_Line2: prop.address_Line2,
      Address_ZipCode: prop.address_ZipCode,
      Address_City: prop.address_City,
      Address_CountryCode: prop.address_CountryCode,
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to add a like kind of action */}
          <Button justIcon round simple onClick={() => setThirdParty(prop)} color="info" className="like">
            <Assignment />
          </Button>{" "}
        </div>
      )
    };
  });

  const reactTableColumns = [
    {
      sortable: false,
      filterable: false,
      Header: x => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                type="checkbox"
                className="checkbox"
                checked={selectAll}
                onChange={e => {
                  var isSelectAll = e.currentTarget.checked;
                  setSelectAll(isSelectAll);
                  setSelectedIds(isSelectAll ? results.items.filter(tp => tp.identifier !== 0).map(tp => tp.identifier) : []);
                }}
              />
            }
            label={<Trans>Rows</Trans>}
          />
        );
      },
      accessor: "ChexBox",
      width: 50,
      sortable: false,
      filterable: false,
      Cell: rowInfo => {
        var identifier = rowInfo.original.Identifier;
        if (identifier > 0) {
          return (
            <Checkbox
              type="checkbox"
              className="checkbox"
              checked={selectedIds.some(id => id === identifier)}
              onChange={() =>
                setSelectedIds(selectedIds.some(id => id === identifier) ? selectedIds.filter(id => id !== identifier) : [...selectedIds, identifier])
              }
            />
          );
        } else return <Checkbox disabled="true" />;
      }
    },
    {
      accessor: "actions",
      width: 50,
      sortable: false,
      filterable: false
    },
    {
      Header: <Trans>Status</Trans>,
      accessor: "Status",
      Cell: rowInfo => {
        var statusIcon = <LockOpen style={{ color: "green" }} />;
        if (rowInfo.original.Status !== null && rowInfo.original.Status === "Closed") statusIcon = <Lock style={{ color: "red" }} />;
        var headQuarterIcon = "";
        if (rowInfo.original.IsHeadQuarter) headQuarterIcon = <Flag style={{ color: "orange" }} />;

        return (
          <div>
            {statusIcon}
            {headQuarterIcon}
          </div>
        );
      }
    },
    {
      Header: <Trans>Id</Trans>,
      accessor: "Identifier"
    },
    {
      Header: <Trans>Long Name</Trans>,
      accessor: "LongName"
    },
    {
      Header: <Trans>Legal Identifier</Trans>,
      accessor: "LegalIdentifier"
    },
    {
      Header: <Trans>Address Line 1</Trans>,
      accessor: "Address_Line1"
    },
    {
      Header: <Trans>Address Line 2</Trans>,
      accessor: "Address_Line2"
    },
    {
      Header: <Trans>City</Trans>,
      accessor: "Address_City"
    },
    {
      Header: <Trans>ZipCode</Trans>,
      accessor: "Address_ZipCode"
    },
    {
      Header: <Trans>Country</Trans>,
      accessor: "Address_CountryCode"
    }
  ];

  if (!results.serverSide) {
    return (
      <ReactTable
        //manual
        data={items}
        //pages={items.pages}
        columns={reactTableColumns}
        defaultPageSize={pageSize}
        showPaginationBottom={true}
        className="-striped -highlight"
        style={ReactTableStyle.main}
      />
    );
  } else {
    return (
      <ReactTable
        manual
        data={items}
        page={results.pageIndex}
        pages={results.nbOfPages}
        columns={reactTableColumns}
        sorted={[{ id: criteria.orderProperty, desc: criteria.isDescendingOrder }]}
        onPageChange={(state, instance) => {
          var tempCriteria = criteria;
          tempCriteria.startPage = state;
          launchSearch({ ...criteria, startPage: state });
        }}
        onPageSizeChange={(state, instance) => {
          var tempCriteria = criteria;
          tempCriteria.pageSize = state;
          launchSearch({ ...criteria, pageSize: state });
        }}
        onSortedChange={(newSorted, column, shiftKey) => {
          var tempCriteria = criteria;
          tempCriteria.orderProperty = newSorted[0].id;
          tempCriteria.isDescendingOrder = newSorted[0].desc;
          launchSearch({ ...criteria, orderProperty: newSorted[0].id, isDescendingOrder: newSorted[0].desc });
        }}
        defaultPageSize={results.pageSize}
        showPaginationBottom={true}
        className="-striped -highlight"
        style={ReactTableStyle.main}
      />
    );
  }
}

const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(SearchResultTable);
