import { Trans } from "@lingui/macro";
import { Breadcrumbs, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link } from "@material-ui/core";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Ballot,
  BusinessCenter,
  CheckCircleOutlined,
  ErrorOutlined,
  Lock,
  LockOpen,
  RefreshOutlined,
  Store,
  Warning as WarningIcon,
  Close
} from "@material-ui/icons";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import cx from "classnames";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { HasRight } from "services/user/UserHelper";
import Button from "../../../components/CustomButtons/Button";
import NavPills from "../../../components/NavPills/NavPills.jsx";
import { isArrayNullOrEmpty, isNull, isNullOrEmpty } from "../../tools";
import { OpenCompany } from "../companies/store/actions";
import CardError from "components/Card/CardError";
import ThirdPartyAccountDatas from "../components/ThirdPartyAccountDatas";
import ThirdPartyBeneficiaryDatas from "../components/ThirdPartyBeneficiaryDatas";
import ThirdPartyCustomerDatas from "../components/ThirdPartyCustomerDatas";
import ThirdPartyLegalDatas from "../components/ThirdPartyLegalDatas";
import ThirdPartyPointOfSaleDatas from "../components/ThirdPartyPointOfSaleDatas";
import ThirdPartyPrivateDatas from "../components/ThirdPartyPrivateDatas";
import ThirdPartySupplierDatas from "../components/ThirdPartySupplierDatas";
import * as Actions from "../store/actions";
import { ApimGet} from "components/Common/ApimSender";

function ThirdPartyDetail({ mainThirdPartyCtx, refreshThirdPartyForm, refreshFromExternalRepository, classes }) {
  const dispatch = useDispatch();
  var [showCloseWithoutSave, setShowCloseWithoutSave] = useState(false);
  const [notifSent, setNotifSent] = useState(false);
  const [hasNotifError, setHasNotifError] = useState(false);
  const [errors, setErrors] = useState([]);

  function handleSnackbarClose() {
    setNotifSent(false);
    setHasNotifError(false);
  }

  if (isNull(mainThirdPartyCtx)) {
    return "";
  }

  var error = mainThirdPartyCtx.error;
  if (error) {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={true}
        onClose={() => closeThirdParty()}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogPaper }}
        scroll="paper"
      >
        <DialogContent
          style={{
            ...DialogInlineStyleDetail.dialogContent
          }}
          dividers={true}
        >
          <CardError error={error} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeThirdParty()} color="info">
            <Trans> Close </Trans>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (mainThirdPartyCtx.isLoading)
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={true}
        onClose={() => closeThirdParty()}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogPaper }}
        scroll="paper"
      >
        <DialogContent
          style={{
            ...DialogInlineStyleDetail.dialogContent
          }}
          dividers={true}
        >
          <center>
            <p>
              <Trans>Fetching data</Trans>
            </p>
            <p>
              <CircularProgress />
            </p>
          </center>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeThirdParty()} color="info">
            <Trans> Close </Trans>
          </Button>
        </DialogActions>
      </Dialog>
    );

  var thirdParty = mainThirdPartyCtx.thirdPartyCtx.thirdParty;
  var thirdPartyCtx = mainThirdPartyCtx.thirdPartyCtx;
  var thirdPartyHasChanged = false;
  var thirdPartySaveStatus = null;
  var thirdPartyHasWarnings = false;
  var thirdPartyMigCodeHasChanged = false;
  if (!isNull(thirdPartyCtx)) {
    thirdPartyHasChanged = thirdPartyCtx.hasChanged || (!isNull(thirdPartyCtx.contacts) && thirdPartyCtx.contacts.some(ctx => ctx.action !== "NONE"));
    thirdPartySaveStatus = thirdPartyCtx.saveStatus;
    thirdPartyHasWarnings = !isArrayNullOrEmpty(thirdPartyCtx.thirdParty.warnings);
    thirdPartyMigCodeHasChanged = thirdPartyCtx.hasChangedMigCode;
  }

  var supplierCtx = mainThirdPartyCtx.supplierCtx;
  var supplierHasChanged = false;
  var supplierSaveStatus = null;
  var canSendSupplierNotification = false;
 
  var supplierPayCharHasChanged =
  !isArrayNullOrEmpty(supplierCtx.paymentCharacteristics) && supplierCtx.paymentCharacteristics.some(ctx => ctx.action !== undefined );
  var supplierSjsHasChanged =
  (!isArrayNullOrEmpty(supplierCtx.sjs) && supplierCtx.sjs.some(ctx => ctx.action !== "NONE"))

  if (!isNull(supplierCtx)) {
    supplierHasChanged =
      supplierCtx.hasChanged ||
      supplierCtx.coupaHasChanged ||
      supplierPayCharHasChanged ||
      supplierSjsHasChanged;
    supplierSaveStatus = supplierCtx.saveStatus;
    canSendSupplierNotification = !!supplierCtx.supplier && !!supplierCtx.supplier.isActif;
  }

  var pointOfSaleCtx = mainThirdPartyCtx.pointOfSaleCtx;
  var pointOfSaleHasChanged = false;
  var pointOfSaleSaveStatus = null;
  if (!isNull(pointOfSaleCtx)) {
    pointOfSaleHasChanged = pointOfSaleCtx.hasChanged || pointOfSaleCtx.coupaHasChanged;
    pointOfSaleSaveStatus = pointOfSaleCtx.saveStatus;
  }

  var customerCtx = mainThirdPartyCtx.customerCtx;
  var customerHasChanged = false;
  var customerSaveStatus = null;
  var canSendCustomerNotification = false;
  if (!isNull(customerCtx)) {
    customerHasChanged = customerCtx.hasChanged || (!isNull(customerCtx.sjs) && customerCtx.sjs.some(ctx => ctx.action !== "NONE"));
    customerSaveStatus = customerCtx.saveStatus;
    canSendCustomerNotification = !!customerCtx.customer && !!customerCtx.customer.isActif;
  }

  var accountCtx = mainThirdPartyCtx.accountCtx;
  var accountHasChanged = false;
  var accountSaveStatus = null;
  if (!isNull(accountCtx)) {
    accountHasChanged = accountCtx.hasChanged;
    accountSaveStatus = accountCtx.saveStatus;
  }

  var beneficiaryCtx = mainThirdPartyCtx.beneficiaryCtx;
  var beneficiaryHasChanged = false;
  var beneficiarySaveStatus = null;
  if (!isNull(beneficiaryCtx)) {
    beneficiaryHasChanged = beneficiaryCtx.hasChanged || (!isNull(beneficiaryCtx.sjs) && beneficiaryCtx.sjs.some(ctx => ctx.action !== "NONE"));
    beneficiarySaveStatus = beneficiaryCtx.saveStatus;
  }

  var hasChanged =
    thirdPartyHasChanged || supplierHasChanged || pointOfSaleHasChanged || customerHasChanged || accountHasChanged || beneficiaryHasChanged;

  function closeThirdParty(confirmClose) {
    if (hasChanged && !confirmClose) {
      setShowCloseWithoutSave(true);
    } else {
      setShowCloseWithoutSave(false);
      dispatch(Actions.CloseThirdParty());
    }
  }

  var canSendNotification = thirdParty.identifier > 0 && HasRight("application.admin");
  function generateNotification() {
    if (!hasChanged && canSendNotification) {
      if (canSendSupplierNotification) {
        dispatch(
          Actions.GenerateNotificationSupplier(
            supplierCtx.supplier,
            r => setNotifSent(true),
            e => {
              setHasNotifError(true);
              setNotifSent(true);
            }
          )
        );
      }
  
      if (canSendCustomerNotification) {
        dispatch(
          Actions.GenerateNotificationCustomer(
            customerCtx.customer,
            r => setNotifSent(true),
            e => {
              setHasNotifError(true);
              setNotifSent(true);
            }
          )
        );
      }
    }
  }

  var tabStatus = buildTabs(thirdParty);
  var tabs = tabStatus.map((tab, index) => {
    var tabButton = <span>{tab.title}</span>;
    var tabContent = "";

    switch (tab.component) {
      case "ThirdPartyPrivateDatas":
        var star = "";
        if (thirdPartyHasChanged) star = " *";
        tabButton = (
          <span>
            {tab.title}
            {star}
          </span>
        );
        tabContent = <ThirdPartyPrivateDatas />;
        break;
      case "ThirdPartyLegalDatas":
        var star = "";
        var saveStatusIcon = GetSaveStatusIcon(thirdPartySaveStatus);
        var warningIcon = thirdPartyHasWarnings ? <WarningIcon style={{ color: "orange" }} /> : "";
        if (thirdPartyHasChanged) star = " *";
        tabButton = (
          <span>
            {saveStatusIcon}
            {warningIcon}
            {tab.title}
            {star}
          </span>
        );
        tabContent = <ThirdPartyLegalDatas errors={errors}/>;
        break;
      case "ThirdPartySupplierDatas":
        var star = "";
        var saveStatusIcon = GetSaveStatusIcon(supplierSaveStatus);
        if (supplierHasChanged) star = " *";

        tabButton = (
          <span>
            {saveStatusIcon}
            {tab.title}
            {star}
          </span>
        );
        tabContent = <ThirdPartySupplierDatas />;
        break;
      case "ThirdPartyPointOfSaleDatas":
        var star = "";
        var saveStatusIcon = GetSaveStatusIcon(pointOfSaleSaveStatus);
        if (pointOfSaleHasChanged) star = " *";

        tabButton = (
          <span>
            {saveStatusIcon}
            {tab.title}
            {star}
          </span>
        );
        tabContent = <ThirdPartyPointOfSaleDatas />;
        break;
      case "ThirdPartyCustomerDatas":
        var star = "";
        var saveStatusIcon = GetSaveStatusIcon(customerSaveStatus);
        if (customerHasChanged) star = " *";

        tabButton = (
          <span>
            {saveStatusIcon}
            {tab.title}
            {star}
          </span>
        );
        tabContent = <ThirdPartyCustomerDatas />;
        break;
      case "ThirdPartyAccountDatas":
        var star = "";
        var saveStatusIcon = GetSaveStatusIcon(accountSaveStatus);
        if (accountHasChanged) star = " *";

        tabButton = (
          <span>
            {saveStatusIcon}
            {tab.title}
            {star}
          </span>
        );
        tabContent = <ThirdPartyAccountDatas />;
        break;
      case "ThirdPartyBeneficiaryDatas":
        var star = "";
        var saveStatusIcon = GetSaveStatusIcon(beneficiarySaveStatus);
        if (beneficiaryHasChanged) star = " *";

        tabButton = (
          <span>
            {saveStatusIcon}
            {tab.title}
            {star}
          </span>
        );
        tabContent = <ThirdPartyBeneficiaryDatas />;
        break;
      default:
        break;
    }

    return {
      tabButton: tabButton,
      tabContent: tabContent,
      tabName: tab.component
    };
  });

  var activeTabIndex = !isNullOrEmpty(mainThirdPartyCtx.activeTabName) ? tabs.findIndex(t => t.tabName === mainThirdPartyCtx.activeTabName) : 0;

  var statusIcon = <LockOpen style={{ color: "green" }} />;
  if (thirdParty.thirdPartyStatusCode !== null && thirdParty.thirdPartyStatusCode === "Closed") statusIcon = <Lock style={{ color: "red" }} />;

  const detailPanel =
    classes.detailPanel +
    " " +
    cx({
      [classes.detailPanelWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1
    });

  var btnSave = "";
  if (hasChanged) {
    btnSave = (
      <Button onClick={() => {
        if(thirdPartyMigCodeHasChanged)
            validateThirdParty(thirdParty);
        else 
          SaveThirdParty(mainThirdPartyCtx, dispatch);
            }} color="info">
        <Trans> Save </Trans>
      </Button>
    );
  }
  
  const validateThirdParty = tp => {
    let err = [];
    setErrors([]);
    ApimGet(
      "api/TpCopernic/MigCodes/" + tp.migCode,
      item => {
        if (item === undefined || isArrayNullOrEmpty(item)) {
          err.push({ code: "MIG" });
          setErrors(err);
        }
        else
          SaveThirdParty(mainThirdPartyCtx, dispatch)
      },
      error => setErrors(error)
    );
  }
  var btnReload = "";
  if (thirdParty.identifier > 0) {
    btnReload = (
      <Button onClick={() => refreshThirdPartyForm(mainThirdPartyCtx.thirdPartyCtx.thirdParty.identifier)}>
        <Trans> Reload </Trans>
      </Button>
    );
  }

  var btnRefresh = "";
  if (thirdParty.identifier > 0 && (thirdParty.isFromInfolegal || thirdParty.isFromAltares)) {
    btnRefresh = (
      <Button onClick={() => refreshFromExternalRepository(mainThirdPartyCtx.thirdPartyCtx.thirdParty.identifier)}>
        <Trans> Refresh from External Repository </Trans>
      </Button>
    );
  }

  var btnNotif = "";
  if (!hasChanged && canSendNotification && (canSendSupplierNotification || canSendCustomerNotification)) {
    btnNotif = (
      <Button onClick={generateNotification}>
        <Trans>Send Notification</Trans>
      </Button>
    );
  }

  var thirdPartyClosed = <></>;
  var thirdParty = thirdPartyCtx.thirdParty;
  if (thirdParty.thirdPartyStatusCode === "Closed") {
    thirdPartyClosed = <Lock style={{ color: "red" }} />;
  }

  var companyLink = "";
  var lastLink = "";

  if (thirdParty.isPointOfSale && !isNull(mainThirdPartyCtx.pointOfSaleCtx) && !isNull(mainThirdPartyCtx.pointOfSaleCtx.pointOfSale)) {
    var pointOfSale = mainThirdPartyCtx.pointOfSaleCtx.pointOfSale;
    lastLink = (
      <span>
        {thirdPartyClosed}
        <Store />
        {pointOfSale.label}({pointOfSale.thirdPartyIdentifier})
      </span>
    );

    var parentSupplier = pointOfSale.supplier;
    if (parentSupplier !== null) {
      companyLink = (
        <Link onClick={() => dispatch(OpenCompany(parentSupplier.thirdParty.companyIdentifier))}>
          <Ballot /> {parentSupplier.thirdParty.customCompanyLongName} ({parentSupplier.thirdParty.companyIdentifier})
        </Link>
      );
    }
  } else {
    lastLink = (
      <span>
        {thirdPartyClosed}
        <BusinessCenter />
        {isNullOrEmpty(thirdParty.customLongName) ? thirdParty.longName : thirdParty.customLongName} ({thirdParty.identifier})
      </span>
    );
    if (thirdParty.companyIdentifier > 0)
      companyLink = (
        <Link onClick={() => dispatch(OpenCompany(thirdParty.companyIdentifier))}>
          <Ballot /> {thirdParty.customCompanyLongName} ({thirdParty.companyIdentifier})
        </Link>
      );
  }

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={true}
        onClose={() => closeThirdParty()}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogPaper }}
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          <Breadcrumbs aria-label="Breadcrumb">
            {/*{statusIcon}
                        <Typography color="textPrimary"><Trans>Third Party Detail</Trans>&nbsp;({thirdParty.identifier} - {thirdParty.customLongName})</Typography>*/}
            {companyLink} &gt; {lastLink}
          </Breadcrumbs>
        </DialogTitle>
        <DialogContent
          style={{
            ...DialogInlineStyleDetail.dialogContent
          }}
          dividers={true}
        >
          <div className={detailPanel}>
            <NavPills
              color={thirdParty.thirdPartyStatusCode === "Active" || HasRight("application.edit") ? "info" : "gray"}
              tabs={tabs}
              active={activeTabIndex}
            />
          </div>
        </DialogContent>
        <DialogActions>
          {btnSave}
          {btnNotif}
          {btnReload}
          {btnRefresh}
          <Button onClick={() => closeThirdParty()} color={hasChanged ? "" : "info"}>
            <Trans> Close </Trans>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showCloseWithoutSave}>
        <DialogContent>
          <DialogContentText>
            <Trans> ConfirmCloseWithoutSave </Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeThirdParty(true)} color="info">
            <Trans> Yes </Trans>
          </Button>
          <Button onClick={() => setShowCloseWithoutSave(false)}>
            <Trans> No </Trans>
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        open={notifSent}
        onClose={handleSnackbarClose}
        autoHideDuration={4000}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={
          hasNotifError ?
            <span id="message-id">
              <Trans>Notification error</Trans>
            </span> :
            <span id="message-id">
              <Trans>Notification sent</Trans>
            </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={handleSnackbarClose}>
            <Close />
          </IconButton>
        ]}
      />
    </div>
  );
}

function SaveThirdParty(mainThirdPartyCtx, dispatch) {
  // THIRD PARTY
  var thirdPartyCtx = mainThirdPartyCtx.thirdPartyCtx;
  var thirdParty = thirdPartyCtx.thirdParty;
  
  if (thirdPartyCtx.hasChanged) {
    dispatch(Actions.ThirdPartySave(thirdParty));
  }
  var thirdPartyContactHasChanged = !isArrayNullOrEmpty(thirdPartyCtx.contacts) && thirdPartyCtx.contacts.some(ctx => ctx.action !== "NONE");
  if (thirdPartyContactHasChanged) {
    dispatch(Actions.ThirdPartyContactSave(thirdParty.identifier, thirdPartyCtx.contacts));
  }

  // SUPPLIER
  var supplierCtx = mainThirdPartyCtx.supplierCtx;
  if (supplierCtx.hasChanged) {
    var supplier = supplierCtx.supplier;
    dispatch(Actions.SaveSupplier(supplier));
  }

  var supplierSjHasChanged = !isArrayNullOrEmpty(supplierCtx.sjs) && supplierCtx.sjs.some(ctx => ctx.action !== "NONE");
  if (supplierSjHasChanged) {
    dispatch(Actions.SupplierSjSave(thirdParty.identifier, supplierCtx.sjs));
  }

  var supplierPayCharHasChanged =
    !isArrayNullOrEmpty(supplierCtx.paymentCharacteristics) && supplierCtx.paymentCharacteristics.some(ctx => ctx.action !== undefined);
  if (supplierPayCharHasChanged) {
    dispatch(Actions.SupplierPayCharSave(thirdParty.identifier, supplierCtx.paymentCharacteristics));
  }

  var supplierCoupaHasChanged = !isNull(supplierCtx.coupa) && supplierCtx.coupaHasChanged;
  if (supplierCoupaHasChanged) {
    dispatch(Actions.SupplierCoupaSave(thirdParty.identifier, supplierCtx.coupa));
  }

  // POINT OF SALE
  var pointOfSaleCtx = mainThirdPartyCtx.pointOfSaleCtx;
  if (pointOfSaleCtx.hasChanged) {
    var pointOfSale = pointOfSaleCtx.pointOfSale;
    dispatch(Actions.SavePointOfSale(pointOfSale));
  }

  var pointOfSaleCoupaHasChanged = !isNull(pointOfSaleCtx.coupa) && pointOfSaleCtx.coupaHasChanged;
  if (pointOfSaleCoupaHasChanged) {
    dispatch(Actions.PointOfSaleCoupaSave(thirdParty.identifier, pointOfSaleCtx.coupa));
  }

  // CUSTOMER
  var customerCtx = mainThirdPartyCtx.customerCtx;
  if (customerCtx.hasChanged) {
    var customer = customerCtx.customer;
    dispatch(Actions.SaveCustomer(customer));
  }

  var customerSjHasChanged = !isArrayNullOrEmpty(customerCtx.sjs) && customerCtx.sjs.some(ctx => ctx.action !== "NONE");
  if (customerSjHasChanged) {
    dispatch(Actions.CustomerSjSave(thirdParty.identifier, customerCtx.sjs));
  }

  // BENEFICIARY
  var beneficiaryCtx = mainThirdPartyCtx.beneficiaryCtx;
  if (beneficiaryCtx.hasChanged) {
    var beneficiary = beneficiaryCtx.beneficiary;
    dispatch(Actions.SaveBeneficiary(beneficiary));
  }

  var beneficiarySjHasChanged = !isArrayNullOrEmpty(beneficiaryCtx.sjs) && beneficiaryCtx.sjs.some(ctx => ctx.action !== "NONE");
  if (beneficiarySjHasChanged) {
    dispatch(Actions.BeneficiarySjSave(thirdParty.identifier, beneficiaryCtx.sjs));
  }

}

///------------------------------
/// Définit les tabs à afficher
///------------------------------
function buildTabs(thirdParty) {
  var tabStatus = [];

  if (thirdParty.thirdPartyCategoryCode === "PAR")
    tabStatus.push({
      index: tabStatus.length,
      title: <Trans>Legal Datas</Trans>,
      component: "ThirdPartyPrivateDatas",
      status: "neutral",
      ref: React.createRef()
    });
  else
    tabStatus.push({
      index: tabStatus.length,
      title: <Trans>Legal Datas</Trans>,
      component: "ThirdPartyLegalDatas",
      status: "neutral",
      ref: React.createRef()
    });

  if (thirdParty.isSupplierTabVisible) {
    tabStatus.push({
      index: tabStatus.length,
      title: <Trans>Supplier</Trans>,
      component: "ThirdPartySupplierDatas",
      ref: React.createRef()
    });
  }

  if (thirdParty.isPointOfSsaleTabVisible) {
    tabStatus.push({
      index: tabStatus.length,
      title: <Trans>Points of Sale</Trans>,
      component: "ThirdPartyPointOfSaleDatas",
      ref: React.createRef()
    });
  }

  if (!isNull(thirdParty.identifier)) {
    tabStatus.push({
      index: tabStatus.length,
      title: <Trans>Customer</Trans>,
      component: "ThirdPartyCustomerDatas",
      ref: React.createRef()
    });

    tabStatus.push({
      index: tabStatus.length,
      title: <Trans>Account</Trans>,
      component: "ThirdPartyAccountDatas",
      ref: React.createRef()
    });

    tabStatus.push({
      index: tabStatus.length,
      title: <Trans>Beneficiary</Trans>,
      component: "ThirdPartyBeneficiaryDatas",
      ref: React.createRef()
    });
  }

  return tabStatus;
}

function GetSaveStatusIcon(saveStatus) {
  if (!isNull(saveStatus)) {
    switch (saveStatus) {
      case "PENDING":
        return <RefreshOutlined />;
      case "ERROR":
        return <ErrorOutlined style={{ color: "red" }} />;
      case "SUCCESS_RELOAD":
      case "SUCCESS":
        return <CheckCircleOutlined style={{ color: "green" }} />;
    }
  }
  return "";
}

const styles = {
  formControl: {
    marginLeft: "10px"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  },
  dialogPaperCustomCreation: {
    minHeight: "90vh",
    maxHeight: "90vh",
    width: "900px"
  },
  dialogPaperCustomCreationUnknown: {
    minHeight: "90vh",
    maxHeight: "90vh",
    width: "900px"
  }
};

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "90%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};

const mapStateToProps = state => {
  return {
    mainThirdPartyCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1] : null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refreshThirdPartyForm: id => {
      dispatch(Actions.RefreshThirdPartyForm(id));
    },
    refreshFromExternalRepository: id => {
      dispatch(Actions.RefreshFromExternalRepository(id));
    },
    getExistingMigCode: migCode => {
      dispatch(Actions.GetExistingMigCode(migCode))
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(ThirdPartyDetail));
