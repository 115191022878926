import { Trans } from "@lingui/macro";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import { isNullOrUndefined } from "@microsoft/applicationinsights-core-js";
import CityZipAutocomplete from "components/Autocomplete/CityZipAutocomplete";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GpsAutocomplete from "components/Autocomplete/GpsAutocomplete";
import React from "react";
import { SearchCityInseeCodes } from "services/masterdata/GeographicalMasterValuesHelper";
import { isNullOrEmpty } from "../../../tools";

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

class UnknownStep1 extends React.Component {
  constructor(props) {
    super(props);

    var selectedZipCode = {};
    var autcompleteZipCodeData = [];
    if (!isNullOrEmpty(this.props.thirdParty) && !isNullOrEmpty(this.props.thirdParty.address_ZipCode)) {
      selectedZipCode = { label: this.props.thirdParty.address_ZipCode, value: this.props.thirdParty.address_ZipCode };
      autcompleteZipCodeData.push({ label: this.props.thirdParty.address_ZipCode, value: this.props.thirdParty.address_ZipCode });
    }

    var selectedCommune = {};
    var autocompleteCommuneData = [];
    if (!isNullOrEmpty(this.props.thirdParty) && !isNullOrEmpty(this.props.thirdParty.address_City)) {
      selectedCommune = { label: this.props.thirdParty.address_City, value: this.props.thirdParty.address_City };
      autocompleteCommuneData.push({ label: this.props.thirdParty.address_City, value: this.props.thirdParty.address_City });
    }

    this.state = {
      autocompleteZipCodeData: autcompleteZipCodeData,
      autocompleteCommuneData: autocompleteCommuneData,
      selectedZipCode: selectedZipCode,
      selectedCommune: selectedCommune
    };

    this.onChangeZipCode = this.onChangeZipCode.bind(this);
    this.onSearchZipCode = this.onSearchZipCode.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onSearchCity = this.onSearchCity.bind(this);
  }

  onChangeZipCode(val) {
    this.setState({ selectedZipCode: val });
    this.props.handleChange({ ...this.props.thirdParty, address_ZipCode: val.value });
    if (val.value.length > 2) {
      SearchCityInseeCodes({ zipCode: val.value }, items => {
        this.setState({
          autocompleteCommuneData: items.items.map(item => {
            return { label: item.commune, value: item.commune };
          })
        });
      });
    }
  }

  onSearchZipCode(val) {
    if (val.length > 2) {
      SearchCityInseeCodes({ zipCode: val }, items => {
        var zipCodeList = items.items.map(item => {
          return item.zipCode;
        });
        var arraytTemp = Array.from(new Set(zipCodeList));
        this.setState({
          autocompleteZipCodeData: arraytTemp.map(item => {
            return { label: item, value: item };
          }),
          autocompleteCommuneData: items.items.map(item => {
            return { label: item.commune, value: item.commune };
          })
        });
      });
    }
  }

  onChangeCity(val) {
    this.setState({ selectedCommune: val });
    this.props.handleChange({ ...this.props.thirdParty, address_City: val.value });
  }

  onSearchCity(val) {
    if (isNullOrEmpty(this.props.thirdParty.address_ZipCode)) {
      if (val.length > 2) {
        SearchCityInseeCodes({ name: val }, items => {
          var zipCodeList = items.items.map(item => {
            return item.zipCode;
          });
          var arraytTemp = Array.from(new Set(zipCodeList));
          this.setState({
            autocompleteZipCodeData: arraytTemp.map(item => {
              return { label: item, value: item };
            }),
            autocompleteCommuneData: items.items.map(item => {
              return { label: item.commune, value: item.commune };
            })
          });
        });
      }
    }
  }

  renderErrors() {
    var errorTabs = [];
    for (var i = 0; i < this.props.errors.length; i++) {
      errorTabs[this.props.errors[i].code] = this.props.defaultLang == "FR" ? this.props.errors[i].descriptionFr : this.props.errors[i].descriptionEn;
    }
    return errorTabs;
  }

  render() {
    var item = this.props.thirdParty;
    var errorTabs = this.renderErrors();
    if (isNullOrUndefined(item.gpsCoordinates)) {
      item.gpsCoordinates = { latitude: null, longitude: null };
    }
    
    return (
      <div style={{ width: "100%" }}>
        <Card
          style={{
            ...CardInlineStyle.card
          }}
        >
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <h4>
              <Trans>General informations</Trans>
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel error={errorTabs["longName"]}>
                  <Trans>Long name</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <TextField
                  margin="dense"
                  id="longName"
                  type="text"
                  fullWidth
                  error={errorTabs["longName"]}
                  value={item.longName}
                  onChange={e => {
                    var value = e.target.value;
                    if (value.length > 40) value = value.substring(0, 40);
                    this.props.handleChange({ ...this.props.thirdParty, longName: value });
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel error={errorTabs["customLongName"]}>
                  <Trans>Colas name</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <TextField
                  margin="dense"
                  id="longNameColas"
                  type="text"
                  error={errorTabs["customLongName"]}
                  fullWidth
                  value={item.customLongName}
                  onChange={e => {
                    var value = e.target.value;
                    // if (value.length > 40) value = value.substring(0, 40);
                    this.props.handleChange({ ...this.props.thirdParty, customLongName: value });
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Card
          style={{
            ...CardInlineStyle.card
          }}
        >
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <h4>
              <Trans>Address</Trans>
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel error={errorTabs["AddressLine1"]}>
                  <Trans>Line 1</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <TextField
                  margin="dense"
                  id="line1"
                  type="text"
                  error={errorTabs["AddressLine1"]}
                  fullWidth
                  value={item.address_Line1}
                  onChange={e => {
                    var value = e.target.value;
                    if (value.length > 40) value = value.substring(0, 40);
                    this.props.handleChange({ ...this.props.thirdParty, address_Line1: value });
                  }}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel error={errorTabs["AddressLine2"]}>
                  <Trans>Line 2</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <TextField
                  margin="dense"
                  id="line2"
                  error={errorTabs["AddressLine2"]}
                  type="text"
                  fullWidth
                  value={item.address_Line2}
                  onChange={e => {
                    var value = e.target.value;
                    if (value.length > 40) value = value.substring(0, 40);
                    this.props.handleChange({ ...this.props.thirdParty, address_Line2: value });
                  }}
                />
              </GridItem>
              {/*<GridItem xs={4} sm={4} md={4}>*/}
              {/*    <FormLabel*/}
              {/*        error={errorTabs['AddressZipCodeMissing']}><Trans>Zip Code</Trans></FormLabel>*/}
              {/*</GridItem>*/}
              {/*<GridItem xs={8} sm={8} md={8}>*/}
              {/*    {zipCodeComponent}*/}
              {/*</GridItem>*/}
              {/*<GridItem xs={4} sm={4} md={4}>*/}
              {/*    <FormLabel*/}
              {/*        error={errorTabs['AddressCityMissing']}><Trans>City</Trans></FormLabel>*/}
              {/*</GridItem>*/}
              {/*<GridItem xs={8} sm={8} md={8}>*/}
              {/*    {cityComponent}*/}
              {/*</GridItem>*/}
              {/*{county}*/}
              {/*{state}*/}
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel error={errorTabs["AddressZipCode"] || errorTabs["AddressCity"]} required>
                  <Trans>City</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <CityZipAutocomplete
                  countryCode={item.address_CountryCode}
                  zipCode={item.address_ZipCode}
                  city={item.address_City}
                  countyCode={item.address_CountyCode}
                  stateCode={item.address_StateCode}
                  zipCodeError={errorTabs["AddressZipCode"]}
                  cityError={errorTabs["AddressCity"]}
                  onZipCityChange={(zipCode, city) => {
                    this.props.handleChange({ ...this.props.thirdParty, address_ZipCode: zipCode, address_City: city });
                  }}
                  onCountyStateChange={countyState => {
                    this.props.handleChange({
                      ...this.props.thirdParty,
                      address_StateCode: countyState.stateCode,
                      address_CountyCode: countyState.countyCode
                    });
                  }}
                  isEditable={true}
                  defaultLang="FR"
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel error={errorTabs["AddressCountryMissing"]}>
                  <Trans>Country</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <CountryAutocomplete
                  countryCode={item.address_CountryCode}
                  onChange={codeIso2 => {
                    if (item.address_CountryCode !== codeIso2 && (item.address_CountryCode === "FR" || codeIso2 === "FR"))
                      this.props.handleChange({ ...this.props.thirdParty, address_ZipCode: null, address_City: null, address_CountryCode: codeIso2 });
                    else this.props.handleChange({ ...this.props.thirdParty, address_CountryCode: codeIso2 });
                  }}
                  defaultLang={this.props.defaultLang}
                  isEditable
                  error={errorTabs["AddressCountryMissing"]}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>LatitudeLongitude</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <GpsAutocomplete
                  //latitudeError={checkError("LatitudeWrongFormat")}
                  //longitudeError={checkError("LongitudeWrongFormat")}
                  isEditable={true}
                  defaultLang={item.defaultLang}
                  source={item}
                  setSource={c => {
                    this.props.handleChange(c);
                  }}
                  error={errorTabs["GpsCoordinates"]}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default UnknownStep1;
