import React from "react";
import Selector from "components/Common/Selector";
import en from "locale/en/messages";
import fr from "locale/fr/messages";
import { isNull } from "tools";

//lingui
import { setupI18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import { Add } from "@material-ui/icons";
import Button from "components/CustomButtons/Button";
import CardContact from "./CardContact";

function PaymentCharacteristicContact({ payChar, setPayChar, knownContacts, masterValues, isEditable, currentCountry, defaultLang, errors }) {
  const i18n = setupI18n({ catalogs: { fr: fr, en: en }, language: defaultLang });

  var supplierCountryPhoneCode = currentCountry && !isNull(currentCountry.defaultPhoneCode) ? "+" + currentCountry.defaultPhoneCode : "";
  const initialContact = {
    id: 0,
    thirdPartyIdentifier: payChar.supplierIdentifier,
    contactTypeCode: "COMPTA",
    lastName: "",
    firstName: "",
    details: [
      {
        contactDetailTypeCode: "MAIL",
        title: "Principal",
        phoneCode: null,
        value: ""
      },
      {
        contactDetailTypeCode: "PHONE",
        title: "Principal",
        phoneCode: supplierCountryPhoneCode,
        value: null
      }
    ]
  };

  function changeContact(contactId) {
    let pc = payChar;
    if (contactId === 0) {
      pc.thirdPartyContactId = 0;
      pc.thirdPartyContact = initialContact;
    } else {
      pc.thirdPartyContactId = contactId;
      pc.thirdPartyContact = knownContacts.find(c => c.id === contactId);
    }
    setPayChar(pc);
  }

  var select = "";
  if (isEditable) {
    if (knownContacts.length > 0) {
      select = (
        <div>
          <Selector
            items={[
              { id: 0, name: i18n._(t`ThirdParty_NewContact`) },
              ...knownContacts.map(c => {
                return { id: c.id, name: c.lastName + " " + c.firstName };
              })
            ]}
            displayedProperties={{
              keyProperty: "id",
              valueProperty: "name"
            }}
            value={payChar.thirdPartyContact ? payChar.thirdPartyContact.id : 0}
            isEditable={isEditable}
            onChange={e => changeContact(e.target.value)}
          />
        </div>
      );
    }
  }

  if (!payChar.thirdPartyContact) {
    if (isEditable) {
      return (
        <>
          <Button
            onClick={() => {
              let pc = payChar;
              pc.thirdPartyContact = initialContact;
              setPayChar(pc);
            }}
          >
            <Add /> <Trans>PayChar_Contact_New</Trans>
          </Button>
        </>
      );
    }

    return <Trans>PayChar_Contact_NoData</Trans>;
  }

  return (
    <>
      {select}
      <CardContact
        id="paymentCharacteristicContact"
        contact={payChar.thirdPartyContact}
        setContact={(c, hasError) => {
          let pc = payChar;
          pc.thirdPartyContact = c;
          setPayChar(pc, hasError);
        }}
        isEditable={isEditable || payChar.thirdPartyContactId === 0}
        isDeletable={false}
        //customHeader={(<span><Trans>PaymentCharacteristic_Contact</Trans></span>)}
        lockContactDetails={true}
        countryPhoneCode={supplierCountryPhoneCode}
        errors={errors}
        masterValues={masterValues}
        defaultLang={defaultLang}
      />
    </>
  );
}

export default PaymentCharacteristicContact;
