import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import ThirdPartySelectorNew from "components/Common/ThirdPartySelectorNew";
import { ThirdPartyGet } from "../../../tpCopernic/thirdParties/actions/ThirdPartyActions";
import CardError from "components/Card/CardError";
import { isNullOrEmpty } from "tools";

const RequestSupplierThirdParty = ({ request, setRequest, isReadOnly, error }) => {
  const [state, setState] = useState({ isLoading: false, error: null });
  const { i18n } = useLingui();

  useEffect(() => {
    if (!request.linkedThirdParty && !isNullOrEmpty(request.thirdPartySource)) {
      if (request.thirdPartySource.toUpperCase() === "ALTARES" && !isNullOrEmpty(request.thirdPartyDuns)) {
        setState({ isLoading: true, error: null });
        ThirdPartyGet(
          "DUNS:" + request.thirdPartyDuns,
          tp => {
            setState({ isLoading: false });
            var thirdParty = tp.thirdParty;
            if (request.thirdPartyLanguageCode) thirdParty.languageCode = request.thirdPartyLanguageCode;
            if (request.thirdPartyCustomLongName) thirdParty.customLongName = request.thirdPartyCustomLongName;
            if (request.thirdPartyIsHandicapSector) thirdParty.isHandicapSector = request.thirdPartyIsHandicapSector;
            setThirdParty(thirdParty);
          },
          e => setState({ isLoading: false, error: e })
        );
      } else if (
        request.thirdPartySource.toUpperCase() === "INFOLEGALE" &&
        !isNullOrEmpty(request.thirdPartyLegalIdentifier) &&
        request.thirdPartyLegalIdentifier.length === 14
      ) {
        setState({ isLoading: true, error: null });
        ThirdPartyGet(
          "SIRET:" + request.thirdPartyLegalIdentifier,
          tp => {
            setState({ isLoading: false });
            var thirdParty = tp.thirdParty;
            if (request.thirdPartyLanguageCode) thirdParty.languageCode = request.thirdPartyLanguageCode;
            if (request.thirdPartyCustomLongName) thirdParty.customLongName = request.thirdPartyCustomLongName;
            if (request.thirdPartyIsHandicapSector) thirdParty.isHandicapSector = request.thirdPartyIsHandicapSector;
            setThirdParty(thirdParty);
          },
          e => setState({ isLoading: false, error: e })
        );
      } else if (request.thirdPartySource.toUpperCase() === "MANUAL" && request.thirdPartyIdentifier > 0) {
        setState({ isLoading: true, error: null });
        ThirdPartyGet(
          request.thirdPartyIdentifier,
          tp => {
            setState({ isLoading: false });
            var thirdParty = tp.thirdParty;
            if (request.thirdPartyCustomLongName) thirdParty.customLongName = request.thirdPartyCustomLongName;
            setThirdParty(thirdParty);
          },
          e => setState({ isLoading: false, error: e })
        );
      } else {
        setState({ isLoading: false, error: "ThirdParty is not recognized" });
      }
    }
  }, [
    request.linkedThirdParty,
    request.thirdPartyIdentifier,
    request.thirdPartyDuns,
    request.thirdPartyLegalIdentifier,
    request.thirdPartyLanguageCode,
    request.thirdPartyCustomLongName,
    request.thirdPartyIsHandicapSector
  ]);

  const setThirdParty = tp => {
    setRequest({
      ...request,
      thirdPartyIdentifier: tp.identifier,
      thirdPartyStatus: tp.thirdPartyStatusCode,
      thirdPartyLegalIdentifier: tp.legalIdentifier,
      thirdPartyDuns: tp.duns,
      thirdPartyLongName: tp.longName,
      thirdPartyIsPointOfSale: tp.isPointOfSale,
      thirdPartyIsSupplier: tp.isSupplier,
      thirdPartySource: tp.isFromAltares ? "ALTARES" : tp.isFromInfolegal ? "INFOLEGALE" : "MANUAL",
      thirdPartyLanguageCode: tp.languageCode,
      thirdPartyCustomLongName: tp.customLongName,
      thirdPartyIsHandicapSector: tp.isHandicapSector,
      linkedThirdParty: tp,
      coupaSupplier:
        request.contextCode === "advancePurchase" ? null : parseInt(request.coupaSupplier?.idMdm) === tp.identifier ? request.coupaSupplier : null
    });
  };

  const ConvertThirdPartyStatus = tp => {
    if (!tp) return "";
    if (!!tp?.isSupplier) return i18n._(t`Workflow_Supplier_KnownAsSupplier`);
    if (!!tp?.isPointOfSale) return i18n._(t`Workflow_Supplier_KnownAsPointOfSale`);
    return i18n._(t`Workflow_Supplier_NotReferenced`);
  };

  if (state.error) return <CardError error={state.error} />;

  if (state.isLoading) return <CircularProgress />;

  var errorThirdParty;
  if (error("legalIdentifier_invalid")) {
    errorThirdParty = <Trans>LegalIdentifier_Invalid</Trans>;
  } else if (error("legalIdentifier_closed")) {
    errorThirdParty = <Trans>ThirdParty_Status_Closed</Trans>;
  } else if (error("SupplierAlreadyAssociated")) {
    errorThirdParty = <Trans>SupplierAlreadyAssociated</Trans>;
  }

  return (
    <ThirdPartySelectorNew
      isEditable={!isReadOnly}
      isLanguageEditable={!isReadOnly}
      thirdParty={request.linkedThirdParty}
      setThirdParty={tp => {
        setThirdParty(tp);
      }}
      errorMessage={errorThirdParty}
      error={error("legalIdentifier")}
      title={ConvertThirdPartyStatus(request.linkedThirdParty)}
      showIsHandicapSector={true}
    />
  );
};

export default RequestSupplierThirdParty;
