import React, { useEffect, useState } from "react";
import ReactTable from "react-table-6";
import { connect, useDispatch } from "react-redux";
import * as Actions from "../store/actions";

import { Checkbox, Chip, CircularProgress, FormControl, FormControlLabel, MenuItem, Select, Tooltip, Typography } from "@material-ui/core";
import { Assignment, Description, ErrorOutline, PermContactCalendar } from "@material-ui/icons";

// helpers
import LocalStorageHelper from "services/common/LocalStorageHelper";

import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import PaymentCharacteristicDetail from "./PaymentCharacteristicDetail";
import PaymentCharacteristicSearch from "../components/PaymentCharacteristicSearch";

//lingui
import { Trans } from "@lingui/macro";
import { isNull, isArrayNullOrEmpty, isNullOrEmpty } from "../../tools";
import { formatDate, toDate } from "../../../tools";

import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";
import CardError from "components/Card/CardError";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import { TpPaymentCharacteristicStatus } from "store/MasterValue/MasterValueTypes";

function PaymentCharacteristicList({ searchCtx, masterValues, openPaymentCharacteristic, classes, defaultLang }) {
  const dispatch = useDispatch();
  var [isFirstTime, setIsFirstTime] = useState(true);
  var [selectAll, setSelectAll] = useState(false);
  var [selectedIds, setSelectedIds] = useState([]);

  var paymentCharacteristics = [],
    searchError,
    isSearching = false,
    criterias,
    openDialogDetail;
  if (searchCtx.searchScope === "paymentCharacteristics") {
    paymentCharacteristics = searchCtx.results;
    searchError = searchCtx.error;
    isSearching = searchCtx.isSearching;
    criterias = searchCtx.criterias;
    openDialogDetail = searchCtx.openDialogDetail;
  }

  const reactTableColumns = [
    {
      sortable: false,
      filterable: false,
      Header: x => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                type="checkbox"
                className="checkbox"
                checked={selectAll}
                onChange={e => {
                  var isSelectAll = e.currentTarget.checked;
                  setSelectAll(isSelectAll);
                  setSelectedIds(isSelectAll ? paymentCharacteristics.items.map(pc => pc.identifier) : []);
                }}
              />
            }
            label={<Trans>Rows</Trans>}
          />
        );
      },
      accessor: "ChexBox",
      width: 50,
      sortable: false,
      filterable: false,
      Cell: rowInfo => {
        var identifier = rowInfo.original.Identifier;
        if (identifier > 0) {
          return (
            <Checkbox
              type="checkbox"
              className="checkbox"
              checked={selectedIds.some(id => id === identifier)}
              onChange={() =>
                setSelectedIds(selectedIds.some(id => id === identifier) ? selectedIds.filter(id => id !== identifier) : [...selectedIds, identifier])
              }
            />
          );
        } else return <FormControlLabel disabled control={<Checkbox value="" />} />;
      }
    },
    {
      accessor: "actions",
      width: 50,
      sortable: false,
      filterable: false
    },
    {
      Header: <Trans>Id</Trans>,
      accessor: "identifier"
    },
    {
      Header: <Trans>Iban</Trans>,
      accessor: "iban"
    },
    {
      Header: <Trans>SIS Score</Trans>,
      accessor: "sisScore",
      Cell: row => (
        <span>
          {renderPaymentCharacteristicsChip(
            row,
            masterValues[TpPaymentCharacteristicStatus].map(i => {
              return { code: i.code, label: GetMasterValueLabel(i, defaultLang) };
            })
          )}
        </span>
      )
    },
    {
      Header: <Trans>SIS Reason</Trans>,
      accessor: "sisComputedReasons",
      Cell: row => <span>{renderPaymentCharacteristicsReason(row)}</span>
    },
    {
      Header: <Trans>StartDate</Trans>,
      accessor: "startOfValidityDate",
      sortMethod: (a, b) => {
        if (a === b) {
          return 0;
        }
        let dateA = toDate(a, defaultLang);
        let dateB = toDate(a, defaultLang);

        return dateA > dateB ? 1 : -1;
      }
    },
    {
      Header: <Trans>EndDate</Trans>,
      accessor: "endOfValidityDate"
    },
    {
      Header: <Trans>Contact</Trans>,
      accessor: "isContactFound",
      Cell: row =>
        row.value && row.value.lastName ? (
          <Tooltip title={`${row.value.lastName} ${row.value.firstName}`}>
            <PermContactCalendar />
          </Tooltip>
        ) : (
          <></>
        ),
      sortMethod: (a, b) => {
        if (!a || !a.lastName) {
          return -1;
        }
        if (!b || !b.lastName) {
          return -1;
        }
        return a.lastName > b.lastName ? 1 : -1;
      }
    }
  ];

  useEffect(() => {
    if (isFirstTime) {
      if (!isNull(paymentCharacteristics) && !isArrayNullOrEmpty(paymentCharacteristics.items)) dispatch(Actions.CleanSearchPaymentCharacteristics());
      setIsFirstTime(false);
    }
  });

  var searchResult = "";
  if (searchError) {
    searchResult = <CardError error={searchError} />;
  } else if (isSearching) {
    searchResult = (
      <center>
        <CircularProgress />
      </center>
    );
  } else if (!isNullOrEmpty(paymentCharacteristics)) {
    var items = ConvertDatas(paymentCharacteristics, openPaymentCharacteristic, defaultLang);

    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");
    var pageSize = 10;
    if (getPref !== null) {
      pageSize = getPref.Pref.ResultPageSize * 1;
    }

    var reactTable = (
      <ReactTable
        //manual
        data={items.tabItems}
        //pages={items.pages}
        columns={reactTableColumns}
        defaultPageSize={pageSize}
        showPaginationBottom={true}
        className="-striped -highlight"
        style={ReactTableStyle.main}
      />
    );

    searchResult = (
      <Card>
        <CardHeader icon>
          <div className="blockHeaderResult">
            <div className="blockHeaderResultItem">
              <Typography ariant="display1">
                <Trans>Table result</Trans>
              </Typography>
            </div>
            <div className="blockHeaderResultItem"></div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="table-result show">{reactTable}</div>
        </CardBody>
      </Card>
    );
  }

  var dialogDetail = "";
  if (!isNullOrEmpty(openDialogDetail)) {
    // show last ID
    switch (openDialogDetail.type) {
      case "paymentCharacteristic":
        dialogDetail = <PaymentCharacteristicDetail />;
        break;
      default:
        throw "Unknown type: " + openDialogDetail.type;
    }
  }

  var layoutSearchResult;
  if (!isNullOrEmpty(searchResult)) {
    layoutSearchResult = (
      <GridContainer>
        <GridItem xs={8} sm={8} md={8}>
          {searchResult}
        </GridItem>
        <GridItem xs={4} sm={4} md={4}>
          <PaymentCharacteristicSearch criterias={criterias} showSearchCriteriaOnSideBar={true} defaultLang={defaultLang} />
        </GridItem>
      </GridContainer>
    );
  } else {
    layoutSearchResult = <PaymentCharacteristicSearch criterias={criterias} showSearchCriteriaOnSideBar={false} defaultLang={defaultLang} />;
  }

  return (
    <div>
      {layoutSearchResult}
      {dialogDetail}
    </div>
  );
}

function renderPaymentCharacteristicsChip(row, paymentCharacteristicStatusCodes) {
  if (!isNullOrEmpty(row.value)) {
    var splitted = row.value.split("¤");
    var sisScore = splitted[0];
    var statusCode = splitted[1];
    var label = paymentCharacteristicStatusCodes.find(i => i.code === statusCode).label;
    if (isNaN(sisScore)) return <Chip style={{ borderStyle: "solid", borderWidth: "2px", borderColor: "#ffbf00" }} label={label} />;
    if (sisScore < 30)
      return (
        <Chip
          style={{ backgroundColor: "#ff2e00" }}
          label={
            <span>
              {sisScore} - {label}
            </span>
          }
        />
      );
    if (sisScore >= 30 && sisScore <= 70)
      return (
        <Chip
          style={{ backgroundColor: "#ffbf00" }}
          label={
            <span>
              {sisScore} - {label}
            </span>
          }
        />
      );
    return (
      <Chip
        style={{ backgroundColor: "#57d500" }}
        label={
          <span>
            {sisScore} - {label}
          </span>
        }
      />
    );
  }
  return <Trans>SIS NotComputed</Trans>;
}

function renderPaymentCharacteristicsReason(row) {
  if (!isNull(row.value) && row.value.length > 0) {
    return (
      <Tooltip
        title={
          <>
            {row.value.map((v, key) => (
              <div key={key}>
                <strong>{v.key}</strong> - {v.value}
              </div>
            ))}
          </>
        }
        placement="top"
      >
        <ErrorOutline />
      </Tooltip>
    );
  }
  return <span></span>;
}

///-----------------------------------------------------------
/// Convertit le résultat d'une recherche en item pour react-table
///-----------------------------------------------------------
function ConvertDatas(paymentCharacteristics, openPaymentCharacteristic, defaultLang) {
  if (paymentCharacteristics.items == null || paymentCharacteristics.items.length === 0) return [];

  var result = {
    pages: paymentCharacteristics.nbOfPages,
    datas: paymentCharacteristics,
    serverSide: paymentCharacteristics.serverSide,
    tabItems: []
  };
  paymentCharacteristics.items.map(prop => {
    result.tabItems.push({
      identifier: prop.identifier,
      iban: prop.iban,
      sisScore: prop.sisScore + "¤" + prop.paymentCharacteristicStatusCode,
      sisComputedReasons: prop.sisComputedReasons,
      startOfValidityDate: formatDate(prop.startOfValidityDate, defaultLang),
      endOfValidityDate: prop.endOfValidityDate ? formatDate(prop.endOfValidityDate, defaultLang) : "",
      isContactFound: prop.thirdPartyContact,
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to add a like kind of action */}
          <Button
            justIcon
            round
            simple
            onClick={() => {
              let item = paymentCharacteristics.items.find(o => o.identifier === prop.identifier);
              openPaymentCharacteristic(item.identifier);
            }}
            color="info"
            className="like"
          >
            <Assignment />
          </Button>{" "}
        </div>
      )
    });
  });

  return result;
}
const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    searchCtx: state.TP_Search,
    masterValues: state.MasterValueReducer,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    searchThirdParties: criterias => {
      dispatch(Actions.SearchPaymentCharacteristics(criterias));
    },
    openPaymentCharacteristic: id => {
      dispatch(Actions.OpenPaymentCharacteristic(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(PaymentCharacteristicList));
